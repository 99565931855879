.input-validation {
    .input-validation-input-container .input-validation-message {
        display: none;
    }

    &:not(.valid) {
        .input-validation-input-container {
            position: relative;
            z-index: 21;

            .input-validation-message {
                background-color: white;
                border-radius: 4px;
                box-shadow: 0px 0px 16px 0px $bright-sand;
                display: block;
                padding: 12px;
                position: absolute;
                right: 50%;
                text-align: center;
                top: calc(100% + 16px);
                transform: translate(50%, 0px);
                width: 160px;
            }
        }

        .input-validation-background {
            background-color: rgba($light-sand, 0.7);
            bottom: 0;
            left: 0;
            position: fixed;
            right: 0;
            top: 0;
            z-index: 20;
        }
    }

    &.dark:not(.valid) {
        .input-validation-input-container .input-validation-message {
            background-color: $deep-blue-80;
            box-shadow: 0px 0px 16px 0px $deep-blue;
        }

        .input-validation-background {
            background-color: rgba($deep-blue, 0.7);
        }
    }
}
