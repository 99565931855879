﻿.navigation-button {
    background-color: $light-sand;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    gap: 16px;
    height: 64px;
    justify-content: space-between;
    outline: none;
    overflow: hidden;
    transition: background-color 0.2s ease-in-out;

    .button-content {
        align-items: center;
        display: flex;
        font-size: 16px;
        gap: 8px;
        line-height: 24px;
        user-select: none;

        .button-selection {
            align-self: stretch;
            flex-shrink: 0;
            transition: background-color 0.2s ease-in-out;
            width: 8px;

            &.selected {
                background-color: $interactive-cyan;
            }
        }

        svg {
            width: 32px;
        }
    }

    .button-tail {
        display: flex;
        gap: 10px;

        .button-dot {
            align-items: center;
            display: flex;
        }

        .button-arrow {
            align-items: center;
            border-left: 1px white solid;
            display: flex;

            svg {
                fill: $interactive-blue;
                transition: fill 0.2s ease-in-out;
                width: 24px;
            }
        }
    }

    &:hover {
        background-color: $bold-green-18;

        .button-tail .button-arrow svg {
            fill: $teal;
        }
    }

    &:focus-visible {
        outline: 1px $focus-blue solid;
        outline-offset: 1px;
    }

    &.dark {
        background-color: $deep-blue-90;

        .button-content {
            .button-selection.selected {
                background-color: $bold-blue;
            }

            svg {
                fill: white;
            }
        }

        .button-tail .button-arrow {
            border-color: $deep-blue;

            svg {
                fill: $interactive-coral;
            }
        }

        &:hover {
            background-color: $bold-green-12-db;

            .button-tail .button-arrow svg {
                fill: $bold-green;
            }
        }
    }
}
