.menu-divider-item {
    padding: 10px 0;

    &::before {
        border-bottom: 1px solid $light-grey;
        border-radius: 1px;
        content: "";
        display: block;
        width: 100%;
    }

    &.dark::before {
        border-color: $deep-blue-50;
    }
}
