﻿.tile-with-bar {
    background-color: $light-sand;
    border-radius: 4px;
    grid-column: span 2;

    .tile-content {
        padding: 16px 16px 0;

        .tile-lable {
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 6px;
        }

        .tile-value {
            font-size: 40px;
            font-weight: 900;
            line-height: 56px;
            margin-left: -2px;

            &.color-blue {
                color: $feedback-blue-light;
            }

            &.color-green {
                color: $feedback-green-light;
            }

            &.color-red {
                color: $feedback-red-light;
            }

            &.color-yellow {
                color: $feedback-yellow-light;
            }
        }

        .tile-bar {
            border-radius: 5px;
            display: flex;
            overflow: hidden;

            .tile-bar-segment {
                height: 10px;

                &:not(:last-child) {
                    border-right: 1px solid $light-grey;
                }

                &.color-0 {
                    background-color: $feedback-red-light;
                }

                &.color-1 {
                    background-color: $feedback-yellow-light;
                }

                &.color-2 {
                    background-color: $feedback-blue-light;
                }

                &.color-3 {
                    background-color: $feedback-green-light;
                }
            }
        }

        .tile-bar-labels {
            display: flex;
            gap: 16px;
            margin-bottom: 20px;
            margin-top: 12px;

            .tile-bar-label {
                align-items: baseline;
                display: flex;

                .tile-bar-label-dot {
                    border-radius: 4px;
                    height: 8px;
                    margin-right: 4px;
                    width: 8px;
                }

                .tile-bar-label-text {
                    font-size: 12px;
                    line-height: 16px;
                }

                &:nth-child(1) .tile-bar-label-dot {
                    background-color: $feedback-red-light;
                }

                &:nth-child(2) .tile-bar-label-dot {
                    background-color: $feedback-yellow-light;
                }

                &:nth-child(3) .tile-bar-label-dot {
                    background-color: $feedback-blue-light;
                }

                &:nth-child(4) .tile-bar-label-dot {
                    background-color: $feedback-green-light;
                }
            }
        }
    }

    .tile-action {
        border-top: 1px solid white;
        cursor: pointer;
        outline: none;
        padding: 8px 16px;
        transition: background-color 0.2s ease-in-out;

        .tile-action-text {
            align-items: center;
            color: $interactive-blue;
            display: flex;
            font-size: 16px;
            line-height: 24px;
            user-select: none;
            transition: color 0.2s ease-in-out;

            svg {
                fill: $interactive-blue;
                margin-left: 1px;
                width: 16px;
                transition: fill 0.2s ease-in-out;
            }
        }

        &:hover {
            background-color: $light-green-40;

            .tile-action-text {
                color: $teal;

                svg {
                    fill: $teal;
                }
            }
        }

        &:focus-visible {
            outline: 1px solid $focus-blue;
            outline-offset: 1px;
        }
    }

    &.dark {
        background-color: $deep-blue-90;

        .tile-content {
            .tile-value {
                &.color-blue {
                    color: $feedback-blue-dark;
                }

                &.color-green {
                    color: $feedback-green-dark;
                }

                &.color-red {
                    color: $feedback-red-dark;
                }

                &.color-yellow {
                    color: $feedback-yellow-dark;
                }
            }

            .tile-bar .tile-bar-segment {
                border-color: $deep-blue;

                &.color-0 {
                    background-color: $feedback-red-dark;
                }

                &.color-1 {
                    background-color: $feedback-yellow-dark;
                }

                &.color-2 {
                    background-color: $feedback-blue-dark;
                }

                &.color-3 {
                    background-color: $feedback-green-dark;
                }
            }

            .tile-bar-labels .tile-bar-label {
                &:nth-child(1) .tile-bar-label-dot {
                    background-color: $feedback-red-dark;
                }

                &:nth-child(2) .tile-bar-label-dot {
                    background-color: $feedback-yellow-dark;
                }

                &:nth-child(3) .tile-bar-label-dot {
                    background-color: $feedback-blue-dark;
                }

                &:nth-child(4) .tile-bar-label-dot {
                    background-color: $feedback-green-dark;
                }
            }
        }

        .tile-action {
            border-color: $deep-blue;

            .tile-action-text {
                color: $interactive-coral;

                svg {
                    fill: $interactive-coral;
                }
            }

            &:hover {
                background-color: $bold-green-12-db;

                .tile-action-text {
                    color: $bold-green;

                    svg {
                        fill: $bold-green;
                    }
                }
            }
        }
    }
}
