.table {
    background-color: inherit;
    position: relative;

    &::before, &::after {
        bottom: 0;
        content: "";
        pointer-events: none;
        position: absolute;
        top: 0;
        width: 10px;
    }

    &::before {
        left: -11px;
    }

    &::after {
        right: -10px;
    }

    .table-container {
        display: grid;
        height: 100%;
        margin: 0 -10px;
        overflow-x: auto;
        overflow-y: hidden;
        padding: 0 10px;

        &::-webkit-scrollbar {
            height: 24px;
        }

        &::-webkit-scrollbar-track {
            background-clip: content-box;
            background-color: $light-grey;
            border: 11px transparent solid;
        }

        &::-webkit-scrollbar-thumb {
            background-clip: content-box;
            background-color: $interactive-blue;
            border: 10px transparent solid;
        }

        &::-webkit-scrollbar-thumb:hover {
            background-color: $teal-90;
        }

        .table-grid {
            column-gap: 20px;
            display: grid;
            height: 100%;

            .table-row {
                display: contents;

                .table-cell {
                    border: 1px solid;
                    border-left: none;
                    border-right: none;
                    color: $deep-blue;
                    font-weight: 400;
                    outline: none;
                    padding: 16px;
                    transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
                    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

                    &:focus-visible {
                        outline: 1px solid $focus-blue;
                        outline-offset: -1px;
                    }

                    &.table-header-cell {
                        align-items: center;
                        border-color: $deep-blue;
                        border-top: none;
                        display: grid;
                        gap: 5px;
                        grid-template: minmax(20px, auto) / 1fr 20px;
                        user-select: none;

                        svg {
                            fill: $deep-blue;
                            transition: fill 0.2s ease-in-out;
                            width: 20px;
                        }

                        &.clickable {
                            cursor: pointer;

                            &:hover {
                                background-color: $bold-green-18;
                                color: $teal-90;

                                svg {
                                    fill: $teal-90;
                                }
                            }
                        }

                        &.bold {
                            font-weight: 700;
                        }

                        &.highlighted {
                            border-width: 3px;
                            font-weight: 700;
                        }

                        &.uninteractive {
                            border-color: transparent;
                            display: block;
                            padding: 0;
                        }

                        &.selected {
                            border-color: $teal;
                        }
                    }

                    &.table-data-cell {
                        &:not(.uninteractive) {
                            border-color: $deep-blue-30;
                        }

                        &.single-line {
                            white-space: nowrap;
                        }

                        &.sized {
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }

                        &.input-style {
                            padding: 5px;
                            overflow: visible;

                            & > * {
                                min-width: auto;
                            }
                        }

                        &.highlighted {
                            font-weight: 700;
                        }

                        &.uninteractive {
                            border-color: transparent;
                            padding: 0;
                        }

                        &.svg-coloring svg {
                            fill: $deep-blue;
                            transition: fill 0.2s ease-in-out;
                            width: 20px;
                        }

                        .divider-content {
                            height: 110px;
                        }
                    }
                }

                &.hoverable:hover, &.clickable:hover {
                    .table-data-cell:not(.uninteractive) {
                        background-color: $bold-green-18;
                        border-color: $teal-90;
                        color: $teal-90;

                        &.svg-coloring svg {
                            fill: $teal-90;
                        }
                    }

                    & + .table-row .table-data-cell:not(.uninteractive) {
                        border-top-color: $teal-90;
                    }

                    &:last-child .table-data-cell.highlighted {
                        border-bottom-color: $deep-blue;
                    }
                }

                &.selected {
                    .table-data-cell:not(.uninteractive) {
                        border-color: $teal !important;
                        color: $teal-90;

                        &.svg-coloring svg {
                            fill: $teal-90;
                        }
                    }

                    & + .table-row .table-data-cell:not(.uninteractive) {
                        border-top-color: $teal !important;
                    }
                }

                &.clickable .table-data-cell:not(.not-clickable) {
                    cursor: pointer;
                }

                &:not(.no-header):nth-child(2) .table-data-cell {
                    border-top: none;
                }

                &:not(:last-child) .table-data-cell {
                    border-bottom: none;
                }

                &:last-child.no-border-last .table-data-cell {
                    border-bottom: none;
                }

                &:last-child .table-data-cell.highlighted {
                    border-bottom-color: $deep-blue;
                    border-bottom-width: 3px;
                }
            }
        }

        &.no-overscroll {
            overscroll-behavior-x: none;
        }

        &.no-scrollbar {
            scrollbar-width: none;

            &::-webkit-scrollbar {
                display: none;
            }
        }
    }

    &.table-layer-1 {
        &::before {
            background: linear-gradient(to right, white, rgba(white, 0.0));
        }

        &::after {
            background: linear-gradient(to left, white, rgba(white, 0.0));
        }
    }

    &.table-layer-2 {
        &::before {
            background: linear-gradient(to right, $light-sand, rgba($light-sand, 0.0));
        }

        &::after {
            background: linear-gradient(to left, $light-sand, rgba($light-sand, 0.0));
        }
    }

    &.table-layer-3 {
        &::before {
            background: linear-gradient(to right, $bright-sand, rgba($bright-sand, 0.0));
        }

        &::after {
            background: linear-gradient(to left, $bright-sand, rgba($bright-sand, 0.0));
        }
    }

    &.dark {
        .table-container {
            &::-webkit-scrollbar-track {
                background-color: white;
            }

            &::-webkit-scrollbar-thumb {
                background-color: $interactive-coral;
            }

            &::-webkit-scrollbar-thumb:hover {
                background-color: $bold-green;
            }

            .table-grid .table-row {
                .table-cell {
                    color: white;

                    &.table-header-cell:not(.uninteractive) {
                        border-color: white;

                        svg {
                            fill: white;
                        }

                        &.clickable:hover {
                            background-color: $bold-green-12-db;
                            color: $bold-green;

                            svg {
                                fill: $bold-green;
                            }
                        }

                        &.selected {
                            border-color: $bold-green;
                        }
                    }


                    &.table-data-cell {
                        &:not(.uninteractive) {
                            border-color: $deep-blue-70;
                        }

                        &.svg-coloring svg {
                            fill: white;
                        }
                    }
                }

                &.hoverable:hover, &.clickable:hover {
                    .table-data-cell:not(.uninteractive) {
                        background-color: $bold-green-12-db;
                        border-color: $teal-90;
                        color: $bold-green;

                        &.svg-coloring svg {
                            fill: $bold-green;
                        }
                    }

                    & + .table-row .table-data-cell:not(.uninteractive) {
                        border-top-color: $teal-90;
                    }

                    &:last-child .table-data-cell.highlighted {
                        border-bottom-color: white;
                    }
                }

                &.selected {
                    .table-data-cell:not(.uninteractive) {
                        border-color: $bold-green !important;
                        color: $bold-green;

                        &.svg-coloring svg {
                            fill: $bold-green;
                        }
                    }

                    & + .table-row .table-data-cell:not(.uninteractive) {
                        border-top-color: $bold-green !important;
                    }
                }

                &:last-child .table-data-cell.highlighted {
                    border-bottom-color: white;
                }
            }
        }

        &.table-layer-1 {
            &::before {
                background: linear-gradient(to right, $deep-blue, rgba($deep-blue, 0.0));
            }

            &::after {
                background: linear-gradient(to left, $deep-blue, rgba($deep-blue, 0.0));
            }
        }

        &.table-layer-2 {
            &::before {
                background: linear-gradient(to right, $deep-blue-90, rgba($deep-blue-90, 0.0));
            }

            &::after {
                background: linear-gradient(to left, $deep-blue-90, rgba($deep-blue-90, 0.0));
            }
        }

        &.table-layer-3 {
            &::before {
                background: linear-gradient(to right, $deep-blue-80, rgba($deep-blue-80, 0.0));
            }

            &::after {
                background: linear-gradient(to left, $deep-blue-80, rgba($deep-blue-80, 0.0));
            }
        }
    }
}
