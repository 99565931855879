﻿.system-element {
    display: grid;
    grid-column-gap: 24px;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr auto;

    .system-element-image-dropdown {
        grid-column-start: span 1;
    }

    .system-element-info {
        display: flex;
        flex-direction: column;
        grid-column: span 2;
        position: relative;

        .system-element-title-container {
            align-items: center;
            display: flex;
            gap: 8px;
            justify-content: space-between;

            .system-element-title {
                font-size: 16px;
                font-weight: 900;
                line-height: 24px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }

        .system-element-description {
            font-size: 16px;
            line-height: 24px;
            overflow: hidden;
            padding-top: 4px;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    .system-element-line {
        border-color: $deep-blue;
        border-left: solid 2px;
        grid-row: 2;
        grid-column-start: span 1;
        margin-bottom: -16px;
        margin-left: 41px;
    }

    .system-element-double-line {
        position: relative;
        border-color: $deep-blue;
        border-left: solid 2px;
        grid-row: 2;
        grid-column-start: span 1;
        margin-bottom: -16px;
        margin-left: 36px;
    }

    .system-element-double-line .right {
        position: absolute;
        top: 0;
        left: 8px;
        border-left: solid 2px $deep-blue;
        height: 100%;
    }

    .system-element-inputs {
        display: flex;
        flex-direction: column;
        grid-row: 2;
        grid-column-start: span 2;
        padding-bottom: 56px;
        row-gap: 16px;

        .select {
            min-width: 120px;
        }
    }

    &:last-child .system-element-inputs {
        padding-bottom: 24px;
    }

    &.dark {
        .system-element-line {
            border-color: white;
        }

        .system-element-double-line .right {
            border-color: white;
        }
    }
}
