﻿.tab-content-container {
    display: grid;
    grid-template: auto / 100%;
    margin: 0 -20px;
    padding: 0 20px;

    .tab-content {
        grid-column: 1 / span 1;
        grid-row: 1 / span 1;

        &.open-left {
            animation: open-left 0.5s ease-in-out forwards;

            @keyframes open-left {
                0% {
                    opacity: 0;
                    transform: translateX(-20px);
                    visibility: hidden;
                }

                50% {
                    opacity: 0;
                    transform: translateX(-20px);
                    visibility: visible;
                }

                100% {
                    opacity: 1;
                    transform: translateX(0);
                    visibility: visible;
                }
            }
        }

        &.open-right {
            animation: open-right 0.5s ease-in-out forwards;

            @keyframes open-right {
                0% {
                    opacity: 0;
                    transform: translateX(20px);
                    visibility: hidden;
                }

                50% {
                    opacity: 0;
                    transform: translateX(20px);
                    visibility: visible;
                }

                100% {
                    opacity: 1;
                    transform: translateX(0);
                    visibility: visible;
                }
            }
        }

        &.close-left {
            animation: close-left 0.5s ease-in-out forwards;

            @keyframes close-left {
                0% {
                    opacity: 1;
                    transform: translateX(0);
                    visibility: visible;
                }

                50% {
                    opacity: 0;
                    transform: translateX(20px);
                    visibility: visible;
                }

                100% {
                    opacity: 0;
                    transform: translateX(20px);
                    visibility: hidden;
                }
            }
        }

        &.close-right {
            animation: close-right 0.5s ease-in-out forwards;

            @keyframes close-right {
                0% {
                    opacity: 1;
                    transform: translateX(0);
                    visibility: visible;
                }

                50% {
                    opacity: 0;
                    transform: translateX(-20px);
                    visibility: visible;
                }

                100% {
                    opacity: 0;
                    transform: translateX(-20px);
                    visibility: hidden;
                }
            }
        }
    }
}
