.tab-switcher-container {
    min-width: 0;
    outline: none;
    position: relative;

    &::before, &::after {
        bottom: 0;
        content: "";
        pointer-events: none;
        position: absolute;
        top: 0;
        width: 10px;
        z-index: 1;
    }

    &::before {
        left: -11px;
    }

    &::after {
        right: -10px;
    }

    &:focus-visible .tab-switcher .tab.active {
        background-color: $light-green-40;
    }

    .tab-switcher {
        display: flex;
        margin: 0 -10px;
        overflow-x: auto;
        padding: 0 10px;
        position: relative;

        &::-webkit-scrollbar {
            height: 24px;
        }

        &::-webkit-scrollbar-track {
            background-clip: content-box;
            background-color: $light-grey;
            border: 11px transparent solid;
        }

        &::-webkit-scrollbar-thumb {
            background-clip: content-box;
            background-color: $interactive-blue;
            border: 10px transparent solid;
        }

        &::-webkit-scrollbar-thumb:hover {
            background-color: $teal-90;
        }

        .tab {
            align-items: center;
            border-bottom: 2px solid $deep-blue-20;
            color: $deep-blue;
            cursor: pointer;
            display: flex;
            font-size: 16px;
            font-weight: 900;
            justify-content: center;
            padding: 10px 32px;
            user-select: none;
            white-space: nowrap;
            -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

            &:hover, &:active {
                background-color: $light-green-40;
            }

            &.required::before {
                content: "*";
                margin-right: 2px;
            }

            &.with-children {
                .icon-button {
                    &:not(.disabled):hover, &:not(.disabled):active, &.open {
                        &.icon-button-16 svg, &:hover {
                            transform: scale(110%);
                            transition: transform .2s ease-in-out;
                        }

                        &.icon-button-layer-1::before {
                            background: initial;
                        }

                        &.icon-button-layer-2::before {
                            background: initial;
                        }

                        &.icon-button-layer-3::before {
                            background: initial;
                        }
                    }
                }
            }
        }

        .tab-switcher-underline {
            border-bottom: 2px solid $interactive-cyan;
            bottom: 0;
            left: 0;
            position: absolute;
            transition: left 0.5s ease-in-out, width 0.5s ease-in-out;
            width: 0;
        }
    }

    &.tab-switcher-layer-1 {
        &::before {
            background: linear-gradient(to right, white, rgba(white, 0.0));
        }

        &::after {
            background: linear-gradient(to left, white, rgba(white, 0.0));
        }
    }

    &.tab-switcher-layer-2 {
        &::before {
            background: linear-gradient(to right, $light-sand, rgba($light-sand, 0.0));
        }

        &::after {
            background: linear-gradient(to left, $light-sand, rgba($light-sand, 0.0));
        }
    }

    &.tab-switcher-layer-3 {
        &::before {
            background: linear-gradient(to right, $bright-sand, rgba($bright-sand, 0.0));
        }

        &::after {
            background: linear-gradient(to left, $bright-sand, rgba($bright-sand, 0.0));
        }
    }

    &.dark {
        &::before {
            background: linear-gradient(to right, $deep-blue, rgba($deep-blue, 0.0));
        }

        &::after {
            background: linear-gradient(to left, $deep-blue, rgba($deep-blue, 0.0));
        }

        &:focus-visible .tab-switcher .tab.active {
            background-color: $bold-green-12-db;
        }

        .tab-switcher {
            &::-webkit-scrollbar-track {
                background-color: white;
            }

            &::-webkit-scrollbar-thumb {
                background-color: $interactive-coral;
            }

            &::-webkit-scrollbar-thumb:hover {
                background-color: $bold-green;
            }

            .tab {
                border-color: $deep-blue-60;
                color: white;

                &:hover, &:active {
                    background-color: $bold-green-12-db;
                }
            }

            .tab-switcher-underline {
                border-color: $bold-green;
            }
        }

        &.tab-switcher-layer-1 {
            &::before {
                background: linear-gradient(to right, $deep-blue, rgba($deep-blue, 0.0));
            }

            &::after {
                background: linear-gradient(to left, $deep-blue, rgba($deep-blue, 0.0));
            }
        }

        &.tab-switcher-layer-2 {
            &::before {
                background: linear-gradient(to right, $deep-blue-90, rgba($deep-blue-90, 0.0));
            }

            &::after {
                background: linear-gradient(to left, $deep-blue-90, rgba($deep-blue-90, 0.0));
            }
        }

        &.tab-switcher-layer-3 {
            &::before {
                background: linear-gradient(to right, $deep-blue-80, rgba($deep-blue-80, 0.0));
            }

            &::after {
                background: linear-gradient(to left, $deep-blue-80, rgba($deep-blue-80, 0.0));
            }
        }
    }
}
