.comparison-card-tab {
  margin: 0 -10px 0 -10px;
}
.comparison-card-tab .comparison-card-tab-block {
  padding-top: 24px;
}
.comparison-card-tab .comparison-card-tab-block .system-content {
  width: 100%;
}
.comparison-card-tab .checkboxes {
  display: flex;
  flex-direction: row;
  justify-items: left;
  gap: 6px;
  padding: 32px 0 16px 0;
}
@media (max-width: 699px) {
  .comparison-card-tab .checkboxes {
    flex-direction: column;
  }
}
.comparison-card-tab .system-content-item-list {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}
.comparison-card-tab .comparison-card-tab-item {
  display: grid;
  grid-template-columns: 3fr 2fr;
}
.comparison-card-tab .comparison-card-tab-item :first-child {
  justify-self: left;
  text-align: left;
}
.comparison-card-tab .comparison-card-tab-item :last-child {
  text-align: right;
}

.settings {
  min-height: 860px;
}
.settings .select, .settings .text-input {
  min-width: 150px;
  width: 100%;
}

.system-circuit {
  position: relative;
  display: grid;
  grid-template-columns: 51px 11px 11px auto;
  grid-template-rows: 8px 7px 7px 30px;
  grid-template-areas: "top-1 top-2 top-2 top-3" "line-1-1 line-1-2 line-1-2 line-1-3" "line-2-1 line-2-2 line-2-2 line-2-3" "bottom-1 bottom-2 bottom-3 bottom-4";
}
.system-circuit.system-circuit-offset-8 {
  grid-template-columns: 59px 11px 11px auto;
}
.system-circuit.system-circuit-offset-8 .system-circuit-vertical-line {
  width: 69px;
}
.system-circuit.system-circuit-offset-16 {
  grid-template-columns: 67px 11px 11px auto;
}
.system-circuit.system-circuit-offset-16 .system-circuit-vertical-line {
  width: 77px;
}
.system-circuit .system-circuit-top-2, .system-circuit .system-circuit-line-1-2, .system-circuit .system-circuit-line-2-2 {
  border-bottom: 2px solid #000028;
  transform: skewY(-40deg);
  transform-origin: right bottom;
}
.system-circuit .system-circuit-bottom-1, .system-circuit .system-circuit-bottom-2, .system-circuit .system-circuit-bottom-3, .system-circuit .system-circuit-bottom-4 {
  border-bottom: 2px solid #000028;
  box-sizing: border-box;
}
.system-circuit .system-circuit-top-1 {
  grid-area: top-1;
}
.system-circuit .system-circuit-top-2 {
  grid-area: top-2;
  margin-top: -1px;
}
.system-circuit .system-circuit-top-3 {
  grid-area: top-3;
}
.system-circuit .system-circuit-line-1-1 {
  grid-area: line-1-1;
}
.system-circuit .system-circuit-line-1-2 {
  grid-area: line-1-2;
}
.system-circuit .system-circuit-line-1-3 {
  grid-area: line-1-3;
}
.system-circuit .system-circuit-line-2-1 {
  grid-area: line-2-1;
}
.system-circuit .system-circuit-line-2-2 {
  grid-area: line-2-2;
}
.system-circuit .system-circuit-line-2-3 {
  grid-area: line-2-3;
}
.system-circuit .system-circuit-bottom-1 {
  grid-area: bottom-1;
}
.system-circuit .system-circuit-bottom-2 {
  grid-area: bottom-2;
  position: relative;
}
.system-circuit .system-circuit-bottom-2 .dot {
  height: 6px;
  width: 6px;
  background-color: #000028;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  right: -3px;
  bottom: -4px;
}
.system-circuit .system-circuit-bottom-3 {
  grid-area: bottom-3;
}
.system-circuit .system-circuit-bottom-4 {
  grid-area: bottom-4;
}
.system-circuit .system-circuit-vertical-line {
  position: absolute;
  border-right: 2px solid #000028;
  left: 0;
  width: 61px;
  height: 100%;
}
.system-circuit .system-circuit-information {
  padding: 16px;
  position: absolute;
  right: 0;
  top: 0;
}
.system-circuit.dark .system-circuit-top-2 {
  border-color: white;
}
.system-circuit.dark .system-circuit-line-1-2 {
  border-color: white;
}
.system-circuit.dark .system-circuit-line-2-2 {
  border-color: white;
}
.system-circuit.dark .system-circuit-bottom-1 {
  border-color: white;
}
.system-circuit.dark .system-circuit-bottom-2 {
  border-color: white;
}
.system-circuit.dark .system-circuit-bottom-2 .dot {
  background-color: white;
}
.system-circuit.dark .system-circuit-bottom-3 {
  border-color: white;
}
.system-circuit.dark .system-circuit-bottom-4 {
  border-color: white;
}
.system-circuit.dark .system-circuit-vertical-line {
  border-color: white;
}

.system-element {
  display: grid;
  grid-column-gap: 24px;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr auto;
}
.system-element .system-element-image-dropdown {
  grid-column-start: span 1;
}
.system-element .system-element-info {
  display: flex;
  flex-direction: column;
  grid-column: span 2;
  position: relative;
}
.system-element .system-element-info .system-element-title-container {
  align-items: center;
  display: flex;
  gap: 8px;
  justify-content: space-between;
}
.system-element .system-element-info .system-element-title-container .system-element-title {
  font-size: 16px;
  font-weight: 900;
  line-height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.system-element .system-element-info .system-element-description {
  font-size: 16px;
  line-height: 24px;
  overflow: hidden;
  padding-top: 4px;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.system-element .system-element-line {
  border-color: #000028;
  border-left: solid 2px;
  grid-row: 2;
  grid-column-start: span 1;
  margin-bottom: -16px;
  margin-left: 41px;
}
.system-element .system-element-double-line {
  position: relative;
  border-color: #000028;
  border-left: solid 2px;
  grid-row: 2;
  grid-column-start: span 1;
  margin-bottom: -16px;
  margin-left: 36px;
}
.system-element .system-element-double-line .right {
  position: absolute;
  top: 0;
  left: 8px;
  border-left: solid 2px #000028;
  height: 100%;
}
.system-element .system-element-inputs {
  display: flex;
  flex-direction: column;
  grid-row: 2;
  grid-column-start: span 2;
  padding-bottom: 56px;
  row-gap: 16px;
}
.system-element .system-element-inputs .select {
  min-width: 120px;
}
.system-element:last-child .system-element-inputs {
  padding-bottom: 24px;
}
.system-element.dark .system-element-line {
  border-color: white;
}
.system-element.dark .system-element-double-line .right {
  border-color: white;
}

.systems-view {
  display: grid;
  column-gap: 40px;
  row-gap: 24px;
  margin-top: 32px;
  grid-template-columns: 1fr;
  margin-bottom: 32px;
  align-items: start;
}
.systems-view .systems-comparison-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 16px;
  column-gap: 16px;
}
@media (max-width: 699px) {
  .systems-view .systems-comparison-grid {
    grid-template-columns: 1fr;
    column-gap: initial;
  }
}
.systems-view .systems-comparison-grid .default-box {
  grid-column: span 2;
}
@media (max-width: 699px) {
  .systems-view .systems-comparison-grid .default-box {
    grid-column: span 1;
  }
}
.systems-view .systems-comparison-grid .bottom-box {
  grid-column: span 2;
}
.systems-view .systems-comparison-grid .bottom-box .info-button-mobile {
  display: none;
}
@media (max-width: 699px) {
  .systems-view .systems-comparison-grid .bottom-box {
    grid-column: span 1;
  }
  .systems-view .systems-comparison-grid .bottom-box .system-circuit:first-child, .systems-view .systems-comparison-grid .bottom-box .bottom-box-select {
    grid-column: span 2;
  }
  .systems-view .systems-comparison-grid .bottom-box .system-circuit:nth-child(2), .systems-view .systems-comparison-grid .bottom-box .info-button-desktop {
    display: none;
  }
  .systems-view .systems-comparison-grid .bottom-box .info-button-mobile {
    display: inline-flex;
  }
}
.systems-view .systems-comparison-grid.commercial-view {
  align-items: stretch;
  grid-auto-flow: column;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, auto);
}
@media (max-width: 699px) {
  .systems-view .systems-comparison-grid.commercial-view {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(6, auto);
  }
}
.systems-view .savings-box {
  min-width: 500px;
}
@media (max-width: 699px) {
  .systems-view .savings-box {
    min-width: 200px;
  }
}
.systems-view.fullscreen .systems-comparison-grid {
  display: none;
}
@media (min-width: 1250px) {
  .systems-view:not(.fullscreen) {
    grid-template-columns: 2fr 1fr;
  }
}
.systems-view .select {
  min-width: 125px;
}

.accordion {
  border: 1px solid #4C4C68;
  border-right: none;
  border-left: none;
  outline: none;
  transition: background 0.3s ease-in-out, border 0.3s ease-in-out;
}
.accordion:focus-visible {
  outline: 1px solid #36ABFF;
  outline-offset: 3px;
}
.accordion .header {
  display: grid;
  grid-template-rows: 30px;
  grid-template-columns: 1fr auto;
  align-items: center;
  padding: 25px;
  cursor: pointer;
  user-select: none;
}
.accordion .header .header-icon {
  display: flex;
  justify-self: center;
}
.accordion .header .header-icon svg {
  width: 20px;
  fill: #000028;
}
.accordion .header .header-label-container {
  position: relative;
  color: #000028;
}
.accordion .header .header-label-container .header-label {
  position: relative;
  font-size: 16px;
  font-weight: 900;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transform: translateY(0);
  transition: font-size 0.5s ease-in-out, transform 0.5s ease-in-out;
}
.accordion .header .header-label-container .header-closed-label {
  position: absolute;
  width: calc(100% - 10px);
  margin-left: 10px;
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transform: translateY(18px);
  opacity: 0;
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}
.accordion .header .header-close-icon {
  height: 20px;
  width: 20px;
  display: grid;
  justify-self: center;
}
.accordion .header .header-close-icon::before, .accordion .header .header-close-icon::after {
  content: "";
  display: block;
  height: 2px;
  width: 16px;
  grid-row: 1/span 1;
  grid-column: 1/span 1;
  align-self: center;
  justify-self: center;
  background-color: #000028;
  transition: transform 0.4s ease-in-out 0.1s;
}
.accordion .header .header-close-icon::before {
  transform: rotate(180deg);
}
.accordion .header .header-close-icon::after {
  transform: rotate(180deg);
}
.accordion .header.with-icon {
  grid-template-columns: auto 1fr auto;
}
.accordion .header.with-icon .header-label-container {
  padding-left: 25px;
}
.accordion .header-content-container .header-content {
  padding: 0 20px 30px;
}
.accordion.closing .header .header-label-container .header-label:not(:first-child), .accordion.closed .header .header-label-container .header-label:not(:first-child) {
  transform: translateY(-12px);
}
.accordion.closing .header .header-label-container .header-closed-label, .accordion.closed .header .header-label-container .header-closed-label {
  transform: translateY(8px);
  opacity: 1;
}
.accordion.closing .header .header-close-icon::before, .accordion.closed .header .header-close-icon::before {
  transform: rotate(0deg);
}
.accordion.closing .header .header-close-icon::after, .accordion.closed .header .header-close-icon::after {
  transform: rotate(90deg);
}
.accordion.opening .header-content-container {
  animation: accordion-open 0.5s ease-in-out forwards;
}
@keyframes accordion-open {
  0% {
    height: 0;
  }
}
.accordion.closing .header-content-container {
  animation: accordion-close 0.5s ease-in-out forwards;
}
@keyframes accordion-close {
  100% {
    height: 0;
  }
}
.accordion:not(.open) {
  overflow: hidden;
}
.accordion.closed .header-content-container {
  height: 0;
}
.accordion:not(:last-child) {
  border-bottom: none;
}
.accordion:hover {
  background-color: #FAFDFD;
  border-color: #00646E;
}
.accordion:hover:not(:last-child) ~ .accordion {
  border-top-color: #00646E;
}
.accordion.dark {
  border-color: #CCCCD4;
}
.accordion.dark .header .header-icon svg {
  fill: white;
}
.accordion.dark .header .header-label-container {
  color: white;
}
.accordion.dark .header .header-close-icon::before, .accordion.dark .header .header-close-icon::after {
  background-color: white;
}
.accordion.dark .header-content-container {
  color: white;
}
.accordion.dark:hover {
  background-color: #001F39;
  border-color: #00FFB9;
}
.accordion.dark:hover:not(:last-child) ~ .accordion {
  border-top-color: #00FFB9;
}

.box-tab-switcher {
  border-radius: 4px 4px 0px 0px;
  background-color: #E5E5E9;
  display: flex;
  flex-direction: column;
  position: relative;
  font-size: 20px;
  font-weight: 900;
  line-height: 26px;
  margin: -24px -24px 0px -24px;
}
.box-tab-switcher .box-tab-switcher-selected-item {
  align-self: stretch;
  border-radius: 4px 4px 0px 0px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  gap: 6px;
  overflow: hidden;
  padding: 12px;
  position: relative;
  text-overflow: ellipsis;
  user-select: none;
  white-space: nowrap;
}
.box-tab-switcher .box-tab-switcher-selected-item .box-tab-switcher-selected-item-content {
  display: flex;
  flex-direction: row;
  gap: 6px;
  align-items: center;
  min-width: 0;
}
.box-tab-switcher .box-tab-switcher-selected-item .box-tab-switcher-selected-item-content .box-tab-switcher-selected-item-label {
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.box-tab-switcher .box-tab-switcher-selected-item .box-tab-switcher-selected-item-content .box-tab-switcher-selected-item-icon {
  flex-shrink: 0;
  width: 32px;
  height: 32px;
}
.box-tab-switcher .box-tab-switcher-selected-item .box-tab-switcher-selected-item-arrow {
  align-items: center;
  display: flex;
  flex-shrink: 0;
}
.box-tab-switcher .box-tab-switcher-selected-item .box-tab-switcher-selected-item-arrow svg {
  fill: #23233C;
  transition: fill 0.3s ease-in-out, transform 0.3s ease-in-out;
  width: 24px;
  height: 24px;
}
.box-tab-switcher .box-tab-switcher-dropdown-container {
  left: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  z-index: 2;
}
.box-tab-switcher .box-tab-switcher-dropdown-container .box-tab-switcher-dropdown {
  background-color: #EBEBEE;
  border-radius: 0 0 4px 4px;
  padding: 10px 8px 12px 8px;
  pointer-events: auto;
}
.box-tab-switcher .box-tab-switcher-dropdown-container .box-tab-switcher-dropdown:first-child {
  border-top: 2px solid #D9D9D6;
  border-radius: 1px;
}
.box-tab-switcher.closing .box-tab-switcher-dropdown-container .box-tab-switcher-dropdown {
  animation: box-tab-switcher-dropdown-close 0.3s cubic-bezier(0.75, -0.02, 0.2, 0.97) forwards;
}
@keyframes box-tab-switcher-dropdown-close {
  0% {
    opacity: 1;
    transform: translate(0, 56px);
  }
  100% {
    opacity: 0;
    transform: translate(0, 40px);
  }
}
.box-tab-switcher.closed .box-tab-switcher-dropdown-container .box-tab-switcher-dropdown {
  opacity: 0;
  transform: translate(0, 56px);
  visibility: hidden;
}
.box-tab-switcher.open, .box-tab-switcher.opening {
  padding-bottom: 0;
  background-color: #EBEBEE;
}
.box-tab-switcher.open .box-tab-switcher-selected-item, .box-tab-switcher.opening .box-tab-switcher-selected-item {
  padding-bottom: 12px;
}
.box-tab-switcher.open .box-tab-switcher-dropdown-container .box-tab-switcher-dropdown, .box-tab-switcher.opening .box-tab-switcher-dropdown-container .box-tab-switcher-dropdown {
  animation: box-tab-switcher-dropdown-open 0.3s cubic-bezier(0.75, -0.02, 0.2, 0.97) forwards;
}
@keyframes box-tab-switcher-dropdown-open {
  0% {
    opacity: 0;
    transform: translate(0, 40px);
  }
  100% {
    opacity: 1;
    transform: translate(0, 56px);
  }
}
.box-tab-switcher.open .box-tab-switcher-selected-item-arrow svg, .box-tab-switcher.opening .box-tab-switcher-selected-item-arrow svg {
  transform: rotate(-180deg);
}
.box-tab-switcher.dark {
  background-color: #37374D;
}
.box-tab-switcher.dark.open, .box-tab-switcher.dark.opening {
  background-color: #66667E;
}
.box-tab-switcher.dark.open .box-tab-switcher-selected-item, .box-tab-switcher.dark.opening .box-tab-switcher-selected-item {
  background-color: #66667E;
}
.box-tab-switcher.dark .box-tab-switcher-selected-item {
  background: #37374D;
}
.box-tab-switcher.dark .box-tab-switcher-selected-item .box-tab-switcher-selected-item-content .box-tab-switcher-selected-item-icon svg, .box-tab-switcher.dark .box-tab-switcher-selected-item .box-tab-switcher-selected-item-arrow svg {
  fill: white;
}
.box-tab-switcher.dark .box-tab-switcher-dropdown-container .box-tab-switcher-dropdown {
  background-color: #66667E;
}
.box-tab-switcher.dark .box-tab-switcher-dropdown-container .box-tab-switcher-dropdown:first-child {
  border-color: #7D8099;
}

.box-tab-switcher-dropdown-item {
  color: #000028;
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-items: start;
  font-size: 20px;
  gap: 6px;
  font-weight: 900;
  line-height: 34px;
  outline: none;
  padding: 0 4px;
  position: relative;
  user-select: none;
  z-index: 1;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.box-tab-switcher-dropdown-item .box-tab-switcher-dropdown-item-icon {
  flex-shrink: 0;
  width: 32px;
  height: 32px;
}
.box-tab-switcher-dropdown-item .box-tab-switcher-dropdown-item-label {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.box-tab-switcher-dropdown-item::before {
  bottom: -1px;
  border-radius: 8px;
  content: "";
  left: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: -1px;
  z-index: -1;
}
.box-tab-switcher-dropdown-item:hover {
  color: white;
}
.box-tab-switcher-dropdown-item:hover svg {
  fill: white;
}
.box-tab-switcher-dropdown-item:hover::before {
  background-color: #00646E;
}
.box-tab-switcher-dropdown-item:focus-visible::before {
  outline: 1px solid #1491EB;
  outline-offset: -1px;
}
.box-tab-switcher-dropdown-item.dark {
  color: white;
}
.box-tab-switcher-dropdown-item.dark svg {
  fill: white;
}
.box-tab-switcher-dropdown-item.dark::before {
  border-color: #7D8099;
}
.box-tab-switcher-dropdown-item.dark:hover::before {
  background-color: #002949;
}

.breadcrumb {
  border-radius: 4px;
  color: #007993;
  cursor: pointer;
  outline: none;
  padding: 8px;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  user-select: none;
}
.breadcrumb.disabled {
  color: #7D8099;
  cursor: default;
}
.breadcrumb:not(.disabled):hover {
  background-color: #C2FFEE;
  color: #005159;
}
.breadcrumb:focus-visible {
  outline: 1px #1491EB solid;
  outline-offset: 1px;
}
.breadcrumb.dark {
  color: #00CCCC;
}
.breadcrumb.dark.disabled {
  color: #B3B3BE;
}
.breadcrumb.dark:not(.disabled):hover {
  background-color: #001F39;
  color: #00FFB9;
}

.banner {
  display: grid;
}
.banner .banner-container {
  background-color: white;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 40, 0.25);
  color: #000028;
  display: grid;
  gap: 8px;
  grid-row: 1/span 2;
  grid-template-columns: 4px 1fr;
  overflow: hidden;
}
.banner .banner-container .banner-highlight {
  align-self: stretch;
}
.banner .banner-container .banner-content {
  align-items: start;
  display: grid;
  gap: 8px;
  grid-template-columns: auto 1fr;
  padding: 8px 8px 8px 0;
}
.banner .banner-container .banner-content .status-icon svg {
  width: 24px;
}
.banner .banner-container .banner-content .banner-text-container {
  display: flex;
  flex-direction: column;
}
.banner .banner-container .banner-content .banner-text-container .banner-title {
  font-size: 16px;
  font-weight: 900;
  line-height: 24px;
}
.banner .banner-container .banner-content .banner-text-container .banner-description {
  font-size: 16px;
  line-height: 24px;
}
.banner .banner-container .banner-content .banner-action-container {
  align-items: center;
  align-self: stretch;
  display: flex;
}
.banner .banner-container .banner-content.with-actions {
  grid-template-columns: auto 1fr auto;
}
.banner .banner-container.round {
  border-radius: 4px;
}
.banner.closed {
  grid-template-rows: 0fr;
  opacity: 0;
  transition: grid-template-rows 0.3s ease-out 0.3s, opacity 0.3s ease-in;
}
.banner.open {
  grid-template-rows: 1fr;
  opacity: 1;
  transition: grid-template-rows 0.3s ease-out, opacity 0.3s ease-in 0.3s;
}
.banner.error .banner-container .banner-highlight {
  background-color: #D72339;
}
.banner.info .banner-container .banner-highlight {
  background-color: #007EB1;
}
.banner.success .banner-container .banner-highlight {
  background-color: #01893A;
}
.banner.warning .banner-container .banner-highlight {
  background-color: #E96401;
}
.banner.dark .banner-container {
  background-color: #23233C;
  color: white;
}
.banner.dark.error .banner-container .banner-highlight {
  background-color: #FF2640;
}
.banner.dark.info .banner-container .banner-highlight {
  background-color: #00BEDC;
}
.banner.dark.success .banner-container .banner-highlight {
  background-color: #01D65A;
}
.banner.dark.warning .banner-container .banner-highlight {
  background-color: #FF9000;
}

.button {
  position: relative;
  z-index: 1;
  display: inline-flex;
  align-items: center;
  gap: 5px;
  padding: 11px 25px;
  font-weight: 700;
  border: none;
  outline: none;
  user-select: none;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  transition: color 0.3s ease-in-out, background 0.3s ease-in-out, border 0.3s ease-in-out;
}
.button svg, .button path {
  width: 20px;
  transition: fill 0.3s ease-in-out;
}
.button.rounded-border {
  border-radius: 56px !important;
}
.button.big-size {
  font-size: 18px;
  font-weight: 900;
  padding: 17px 45px;
}
.button.call-to-action {
  color: #000028;
  background: linear-gradient(to right, #00FFB9, #00E6DC);
}
.button.call-to-action svg {
  fill: #000028;
}
.button.call-to-action::before {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  background: #62EEC7;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}
.button.primary {
  color: white;
  background: #007993;
}
.button.primary svg {
  fill: white;
}
.button.secondary {
  padding: 10px 24px;
  color: #007993;
  background-color: transparent;
  border: 1px solid #007993;
  border-radius: 2px;
}
.button.secondary svg, .button.secondary path {
  fill: #007993;
}
.button.tertiary {
  color: #007993;
  background-color: transparent;
}
.button.tertiary svg {
  fill: #007993;
}
.button.disabled {
  cursor: not-allowed;
}
.button.disabled.call-to-action {
  color: #EBEBEE;
  background: #B3B3BE;
}
.button.disabled.call-to-action svg {
  fill: #EBEBEE;
}
.button.disabled.primary {
  color: #EBEBEE;
  background: #B3B3BE;
}
.button.disabled.primary svg, .button.disabled.primary path {
  fill: #EBEBEE;
}
.button.disabled.secondary {
  color: #B3B3BE;
  border-color: #B3B3BE;
}
.button.disabled.secondary svg, .button.disabled.secondary path {
  fill: #B3B3BE;
}
.button.disabled.tertiary {
  color: #B3B3BE;
}
.button.disabled.tertiary svg {
  fill: #B3B3BE;
}
.button:not(.disabled):hover.call-to-action::before, .button:not(.disabled):active.call-to-action::before {
  opacity: 1;
}
.button:not(.disabled):hover.primary, .button:not(.disabled):active.primary {
  background: #00646E;
}
.button:not(.disabled):hover.secondary, .button:not(.disabled):active.secondary {
  color: #005159;
  background-color: #C2FFEE;
  border: 1px solid #005159;
  border-radius: 2px;
}
.button:not(.disabled):hover.secondary svg, .button:not(.disabled):hover.secondary path, .button:not(.disabled):active.secondary svg, .button:not(.disabled):active.secondary path {
  fill: #005159;
}
.button:not(.disabled):hover.tertiary, .button:not(.disabled):active.tertiary {
  color: #005159;
  background-color: #C2FFEE;
}
.button:not(.disabled):hover.tertiary svg, .button:not(.disabled):active.tertiary svg {
  fill: #005159;
}
.button:not(.disabled):focus-visible::after {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  outline: 1px solid #1491EB;
  outline-offset: 1px;
}
.button:not(.disabled):focus-visible.secondary::after {
  outline-offset: 2px;
}
.button.dark.call-to-action::before {
  background: #C5FFEF;
}
.button.dark.primary {
  color: #000028;
  background: #00CCCC;
}
.button.dark.primary svg {
  fill: #000028;
}
.button.dark.secondary {
  color: #00CCCC;
  background-color: transparent;
  border: 1px solid #00CCCC;
}
.button.dark.secondary svg, .button.dark.secondary path {
  fill: #00CCCC;
}
.button.dark.tertiary {
  color: #00CCCC;
  background-color: transparent;
}
.button.dark.tertiary svg {
  fill: #00CCCC;
}
.button.dark.disabled.call-to-action {
  color: #B3B3BE;
  background: #737389;
}
.button.dark.disabled.call-to-action svg {
  fill: #B3B3BE;
}
.button.dark.disabled.primary {
  color: #B3B3BE;
  background: #737389;
}
.button.dark.disabled.primary svg {
  fill: #B3B3BE;
}
.button.dark.disabled.secondary {
  color: #737389;
  border-color: #737389;
}
.button.dark.disabled.secondary svg, .button.dark.disabled.secondary path {
  fill: #737389;
}
.button.dark.disabled.tertiary {
  color: #737389;
}
.button.dark.disabled.tertiary svg {
  fill: #737389;
}
.button.dark:not(.disabled):hover.primary, .button.dark:not(.disabled):active.primary {
  background: #00FFB9;
}
.button.dark:not(.disabled):hover.secondary, .button.dark:not(.disabled):active.secondary {
  color: #00FFB9;
  background-color: #001F39;
  border: 1px solid #00FFB9;
}
.button.dark:not(.disabled):hover.secondary svg, .button.dark:not(.disabled):hover.secondary path, .button.dark:not(.disabled):active.secondary svg, .button.dark:not(.disabled):active.secondary path {
  fill: #00FFB9;
}
.button.dark:not(.disabled):hover.tertiary, .button.dark:not(.disabled):active.tertiary {
  color: #00FFB9;
  background-color: #001F39;
}
.button.dark:not(.disabled):hover.tertiary svg, .button.dark:not(.disabled):active.tertiary svg {
  fill: #00FFB9;
}

.button-group {
  display: flex;
}
.button-group .button.secondary:not(:last-child), .button-group .button.secondary:not(:last-child):not(.disabled):hover {
  border-right: none;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.button-group .button.secondary:not(:last-child):not(.disabled):hover + .button {
  border-left-color: #005159;
}
.button-group .button.secondary:not(:last-child):not(.disabled):hover + .button.dark {
  border-left-color: #00FFB9;
}
.button-group .button.secondary:not(:first-child) {
  border-left-color: #007993;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
.button-group .button.secondary:not(:first-child).dark {
  border-left-color: #00CCCC;
}
.button-group .button.secondary:not(:first-child):not(.disabled):hover {
  border-left-color: #005159;
}
.button-group .button.secondary:not(:first-child):not(.disabled):hover.dark {
  border-left-color: #00FFB9;
}

.box {
  border-radius: 4px;
  min-width: 0;
  padding: 24px 24px 32px;
}
.box:not(.colored-background).box-layer-1 {
  background-color: #F3F3F0;
}
.box:not(.colored-background).box-layer-2 {
  background-color: #DFDFD9;
}
.box.background-color-green {
  background-color: rgba(1, 214, 90, 0.2509803922);
}
.box.background-color-yellow {
  background-color: rgba(250, 228, 64, 0.7019607843);
}
.box.box-small-padding {
  padding: 16px 16px 32px;
}
.box.colored-edge {
  border-left: 4px solid;
}
.box.colored-edge.edge-color-reference {
  border-color: #553BA3;
}
.box.colored-edge.edge-color-alternative {
  border-color: #EC6602;
}
.box.dark:not(.colored-background).box-layer-1 {
  background-color: #23233C;
}
.box.dark:not(.colored-background).box-layer-2 {
  background-color: #37374D;
}
.box.dark.colored-edge.edge-color-reference {
  border-color: #805CFF;
}
.box.dark.colored-edge.edge-color-alternative {
  border-color: #FF9000;
}

.card .card-image-container {
  display: flex;
  justify-content: center;
}
.card .card-description {
  font-size: 18px;
  font-weight: 700;
  padding-top: 24px;
}
.card.interactive .card-title {
  font-size: 48px;
  font-weight: 900;
  line-height: 60px;
  padding-top: 55px;
}
.card.interactive .card-description {
  line-height: 28px;
  margin-bottom: 0;
  padding-top: 18px;
}
.card.interactive .card-button-container {
  padding-top: 32px;
}

.carousel .slick-slider {
  margin-bottom: 35px;
}
.carousel .slick-slider .slick-arrow {
  height: 50px;
  width: 50px;
  z-index: 1;
  background-color: #007993;
  border-radius: 50%;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.06), 0 6px 8px 0 rgba(0, 0, 0, 0.08);
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;
}
.carousel .slick-slider .slick-arrow::before {
  font-size: 30px;
  font-family: SiemensIcons;
  color: white;
  opacity: 1;
}
.carousel .slick-slider .slick-arrow:focus-visible {
  outline: 1px solid #1491EB;
  outline-offset: 2px;
}
.carousel .slick-slider .slick-arrow.slick-disabled {
  background: #B3B3BE;
  cursor: not-allowed;
}
.carousel .slick-slider .slick-arrow.slick-disabled::before {
  color: #EBEBEE;
}
.carousel .slick-slider .slick-arrow:not(.slick-disabled):hover {
  background: #00646E;
}
.carousel .slick-slider .slick-arrow.slick-prev {
  left: 10px;
}
.carousel .slick-slider .slick-arrow.slick-prev::before {
  content: "\e902";
}
.carousel .slick-slider .slick-arrow.slick-next {
  right: 10px;
}
.carousel .slick-slider .slick-arrow.slick-next::before {
  content: "\e903";
}
.carousel .slick-slider .slick-dots {
  bottom: -30px;
}
.carousel .slick-slider .slick-dots li {
  margin: 0;
}
.carousel .slick-slider .slick-dots li button::before {
  font-size: 12px;
  color: #000028;
}
.carousel .slick-slider .slick-dots li button:hover::before {
  color: #00646E;
}
.carousel .slick-slider .slick-dots li button:focus-visible {
  outline: 1px solid #1491EB;
}
.carousel .slick-slider .slick-list .slick-track .slick-slide > div > div {
  outline: none;
}
.carousel.dark .slick-slider .slick-arrow {
  background-color: #00CCCC;
}
.carousel.dark .slick-slider .slick-arrow::before {
  color: #000028;
}
.carousel.dark .slick-slider .slick-arrow.slick-disabled {
  background: #737389;
}
.carousel.dark .slick-slider .slick-arrow.slick-disabled::before {
  color: #B3B3BE;
}
.carousel.dark .slick-slider .slick-arrow:not(.slick-disabled):hover {
  background: #00FFB9;
}

.chart-wrapper {
  display: flex;
  flex-direction: column;
}
.chart-wrapper .intersection-point {
  fill: white;
  stroke: #000028;
}
.chart-wrapper .recharts-active-dot circle {
  fill: #000028;
  stroke: #000028;
}
.chart-wrapper .recharts-bar {
  stroke-width: 1px;
  stroke: #F3F3F0;
}
.chart-wrapper .recharts-bar .recharts-label-list {
  stroke: black;
  stroke-width: 0.1;
}
.chart-wrapper .recharts-bar.iesReference .recharts-bar-rectangle {
  fill: grey;
}
.chart-wrapper .recharts-bar.reference .recharts-bar-rectangle {
  fill: #553BA3;
}
.chart-wrapper .recharts-bar.alternative .recharts-bar-rectangle {
  fill: #EC6602;
}
.chart-wrapper .recharts-label-list {
  fill: #000028;
  font-size: 12px;
}
.chart-wrapper .recharts-line .recharts-curve.recharts-line-curve {
  stroke: #000028;
  stroke-width: 2px;
}
.chart-wrapper .recharts-line.reference-line .recharts-curve.recharts-line-curve {
  stroke: #553BA3;
}
.chart-wrapper .recharts-line.reference-line .recharts-line-dots svg {
  stroke: #553BA3;
  fill: #553BA3;
}
.chart-wrapper .recharts-line.alternative-line .recharts-curve.recharts-line-curve {
  stroke: #EC6602;
}
.chart-wrapper .recharts-line.alternative-line .recharts-line-dots svg {
  stroke: #EC6602;
  fill: #EC6602;
}
.chart-wrapper .recharts-line .recharts-line-dots svg.intersection-point {
  stroke-width: 1px;
  stroke: #000028;
  fill: white;
}
.chart-wrapper .recharts-scatter .recharts-scatter-symbol {
  fill: #B3B3BE;
}
.chart-wrapper .recharts-reference-dot .recharts-dot.recharts-reference-dot-dot {
  fill: #000028;
  stroke: none;
}
.chart-wrapper .recharts-reference-dot.dot-yellow .recharts-dot.recharts-reference-dot-dot {
  fill: #E9C32A;
}
.chart-wrapper .recharts-cartesian-axis .recharts-cartesian-axis-line {
  stroke: #000028;
}
.chart-wrapper .recharts-cartesian-axis .recharts-cartesian-axis-ticks .recharts-cartesian-axis-tick .recharts-cartesian-axis-tick-line {
  stroke: #000028;
}
.chart-wrapper .recharts-cartesian-axis .recharts-cartesian-axis-ticks .recharts-cartesian-axis-tick .recharts-text.recharts-cartesian-axis-tick-value {
  fill: #000028;
  font-size: 12px;
}
.chart-wrapper .recharts-cartesian-axis .recharts-text.recharts-label {
  fill: #000028;
  font-size: 12px;
}
.chart-wrapper .recharts-curve.recharts-tooltip-cursor {
  stroke: #B3B3BE;
}
.chart-wrapper .recharts-tooltip-wrapper .recharts-default-tooltip, .chart-wrapper .recharts-tooltip-wrapper .custom-tooltip {
  backdrop-filter: blur(3px);
  background-color: rgba(255, 255, 255, 0.85) !important;
  border: 1px solid #D9D9D6;
  border-radius: 4px;
  padding: 10px;
}
.chart-wrapper .recharts-tooltip-wrapper .recharts-default-tooltip .recharts-tooltip-label, .chart-wrapper .recharts-tooltip-wrapper .custom-tooltip .recharts-tooltip-label {
  color: #000028 !important;
  font-weight: 700;
}
.chart-wrapper .recharts-tooltip-wrapper .recharts-default-tooltip .recharts-tooltip-item-list .recharts-tooltip-item, .chart-wrapper .recharts-tooltip-wrapper .custom-tooltip .recharts-tooltip-item-list .recharts-tooltip-item {
  color: #000028 !important;
}
.chart-wrapper .recharts-tooltip-wrapper .custom-tooltip .label {
  color: #000028 !important;
  font-weight: 600;
  padding-bottom: 10px;
}
.chart-wrapper .recharts-tooltip-wrapper .custom-tooltip.intersection-point .value-list-item-name {
  color: #000028;
}
.chart-wrapper .recharts-tooltip-wrapper .custom-tooltip.intersection-point .value-list-item-value {
  color: #000028;
}
.chart-wrapper.dark .intersection-point {
  fill: #000028;
  stroke: white;
}
.chart-wrapper.dark .recharts-active-dot circle {
  fill: white;
  stroke: white;
}
.chart-wrapper.dark .recharts-bar {
  stroke-width: 1px;
  stroke: #000028;
}
.chart-wrapper.dark .recharts-bar.reference .recharts-bar-rectangle {
  fill: #805CFF;
}
.chart-wrapper.dark .recharts-bar.alternative .recharts-bar-rectangle {
  fill: #FF9000;
}
.chart-wrapper.dark .recharts-line .recharts-curve.recharts-line-curve {
  stroke: white;
}
.chart-wrapper.dark .recharts-line.reference-line .recharts-curve.recharts-line-curve {
  stroke: #805CFF;
}
.chart-wrapper.dark .recharts-line.reference-line .recharts-line-dots svg {
  stroke: #805CFF;
  fill: #805CFF;
}
.chart-wrapper.dark .recharts-line.alternative-line .recharts-curve.recharts-line-curve {
  stroke: #FF9000;
}
.chart-wrapper.dark .recharts-line.alternative-line .recharts-line-dots svg {
  stroke: #FF9000;
  fill: #FF9000;
}
.chart-wrapper.dark .recharts-line .recharts-line-dots svg.intersection-point {
  stroke-width: 1px;
  stroke: white;
  fill: #000028;
}
.chart-wrapper.dark .recharts-reference-dot .recharts-dot.recharts-reference-dot-dot {
  fill: white;
}
.chart-wrapper.dark .recharts-reference-dot.dot-yellow .recharts-dot.recharts-reference-dot-dot {
  fill: #FFD732;
}
.chart-wrapper.dark .recharts-cartesian-axis .recharts-cartesian-axis-line {
  stroke: white;
}
.chart-wrapper.dark .recharts-cartesian-axis .recharts-cartesian-axis-ticks .recharts-cartesian-axis-tick .recharts-cartesian-axis-tick-line {
  stroke: white;
}
.chart-wrapper.dark .recharts-cartesian-axis .recharts-cartesian-axis-ticks .recharts-cartesian-axis-tick .recharts-text.recharts-cartesian-axis-tick-value {
  fill: white;
}
.chart-wrapper.dark .recharts-cartesian-axis .recharts-text.recharts-label {
  fill: white;
}
.chart-wrapper.dark .recharts-curve.recharts-tooltip-cursor {
  stroke: #7D8099;
}
.chart-wrapper.dark .recharts-tooltip-wrapper .recharts-default-tooltip, .chart-wrapper.dark .recharts-tooltip-wrapper .custom-tooltip {
  background-color: rgba(55, 55, 77, 0.85) !important;
  border-color: #737389 !important;
}
.chart-wrapper.dark .recharts-tooltip-wrapper .recharts-default-tooltip .recharts-tooltip-label, .chart-wrapper.dark .recharts-tooltip-wrapper .custom-tooltip .recharts-tooltip-label {
  color: white !important;
}
.chart-wrapper.dark .recharts-tooltip-wrapper .recharts-default-tooltip .recharts-tooltip-item-list .recharts-tooltip-item, .chart-wrapper.dark .recharts-tooltip-wrapper .custom-tooltip .recharts-tooltip-item-list .recharts-tooltip-item {
  color: white !important;
}
.chart-wrapper.dark .recharts-tooltip-wrapper .custom-tooltip .label {
  color: white !important;
}
.chart-wrapper.dark .recharts-tooltip-wrapper .custom-tooltip.intersection-point .value-list-item-name {
  color: white;
}
.chart-wrapper.dark .recharts-tooltip-wrapper .custom-tooltip.intersection-point .value-list-item-value {
  color: white;
}

.checkbox-container {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  gap: 8px;
  outline: none;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.checkbox-container .checkbox {
  position: relative;
  height: 16px;
  min-width: 16px;
  border: 2px solid #4C4C68;
  background-color: #E0F1F4;
  outline: none;
  transition: background 0.3s ease-in-out, border 0.3s ease-in-out;
}
.checkbox-container .checkbox .checkmark {
  position: absolute;
  top: 0px;
  left: 4px;
  height: 12px;
  width: 8px;
  transform: rotate(40deg);
}
.checkbox-container .checkbox .checkmark::before, .checkbox-container .checkbox .checkmark::after {
  position: absolute;
  content: "";
  background-color: #4C4C68;
}
.checkbox-container .checkbox .checkmark::before {
  width: 0;
  height: 2px;
  top: calc(100% - 2px);
  left: 0;
  transition: width 0.15s ease-in-out 0.15s, background 0.3s ease-in-out 0s;
}
.checkbox-container .checkbox .checkmark::after {
  width: 2px;
  height: 0;
  bottom: 0;
  left: calc(100% - 2px);
  transition: height 0.15s ease-in-out 0s, background 0.3s ease-in-out 0s;
}
.checkbox-container .checkbox.checked .checkmark::before {
  width: 100%;
  transition: width 0.15s ease-in-out 0s, background 0.3s ease-in-out 0s;
}
.checkbox-container .checkbox.checked .checkmark::after {
  height: 100%;
  transition: height 0.15s ease-in-out 0.15s, background 0.3s ease-in-out 0s;
}
.checkbox-container .checkbox-label {
  font-size: 16px;
  color: #4C4C68;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: none;
  transition: color 0.3s ease-in-out;
}
.checkbox-container:not(.disabled):hover .checkbox, .checkbox-container:not(.disabled):active .checkbox {
  background-color: #C2FFEE;
  border-color: #005159;
}
.checkbox-container:not(.disabled):hover .checkbox .checkmark::before, .checkbox-container:not(.disabled):hover .checkbox .checkmark::after, .checkbox-container:not(.disabled):active .checkbox .checkmark::before, .checkbox-container:not(.disabled):active .checkbox .checkmark::after {
  background-color: #005159;
}
.checkbox-container:not(.disabled):hover .checkbox-label, .checkbox-container:not(.disabled):active .checkbox-label {
  color: #000028;
}
.checkbox-container:not(:disabled):focus-visible .checkbox {
  outline: 1px solid #1491EB;
  outline-offset: 1px;
}
.checkbox-container:not(.disabled) .checkbox.checked + .checkbox-label {
  color: #000028;
}
.checkbox-container:not(.disabled) .checkbox.select-highlight.checked {
  border-color: #005159;
}
.checkbox-container:not(.disabled) .checkbox.select-highlight.checked .checkmark::before, .checkbox-container:not(.disabled) .checkbox.select-highlight.checked .checkmark::after {
  background-color: #005159;
}
.checkbox-container.disabled {
  cursor: not-allowed;
}
.checkbox-container.disabled .checkbox {
  background-color: transparent;
  border-color: #7D8099;
}
.checkbox-container.disabled .checkbox .checkmark::before, .checkbox-container.disabled .checkbox .checkmark::after {
  background-color: #7D8099;
}
.checkbox-container.disabled .checkbox-label {
  color: #7D8099;
}
.checkbox-container.dark .checkbox {
  background-color: #262648;
  border-color: #B3B3BE;
}
.checkbox-container.dark .checkbox .checkmark::before {
  background-color: #B3B3BE;
}
.checkbox-container.dark .checkbox .checkmark::after {
  background-color: #B3B3BE;
}
.checkbox-container.dark .checkbox-label {
  color: #B3B3BE;
}
.checkbox-container.dark:not(.disabled):hover .checkbox, .checkbox-container.dark:not(.disabled):active .checkbox {
  background-color: #001F39;
  border-color: #00FFB9;
}
.checkbox-container.dark:not(.disabled):hover .checkbox .checkmark::before, .checkbox-container.dark:not(.disabled):hover .checkbox .checkmark::after, .checkbox-container.dark:not(.disabled):active .checkbox .checkmark::before, .checkbox-container.dark:not(.disabled):active .checkbox .checkmark::after {
  background-color: #00FFB9;
}
.checkbox-container.dark:not(.disabled):hover .checkbox-label, .checkbox-container.dark:not(.disabled):active .checkbox-label {
  color: #EBEBEE;
}
.checkbox-container.dark:not(.disabled) .checkbox.checked + .checkbox-label {
  color: #EBEBEE;
}
.checkbox-container.dark:not(.disabled) .checkbox.select-highlight.checked {
  border-color: #00FFB9;
}
.checkbox-container.dark:not(.disabled) .checkbox.select-highlight.checked .checkmark::before, .checkbox-container.dark:not(.disabled) .checkbox.select-highlight.checked .checkmark::after {
  background-color: #00FFB9;
}
.checkbox-container.dark.disabled .checkbox-label {
  color: #66667E;
}
.checkbox-container.dark.disabled .checkbox {
  background-color: transparent;
  border-color: #66667E;
}
.checkbox-container.dark.disabled .checkbox .checkmark::before, .checkbox-container.dark.disabled .checkbox .checkmark::after {
  background-color: #66667E;
}

.colorful-bar {
  border-radius: 2px;
  border: 2px solid;
}
.colorful-bar.colorful-bar-alternative {
  border-color: #EC6602;
}
.colorful-bar.colorful-bar-reference {
  border-color: #553BA3;
}
.colorful-bar.dark.colorful-bar-alternative {
  border-color: #FF9000;
}
.colorful-bar.dark.colorful-bar-reference {
  border-color: #805CFF;
}

.comment {
  display: grid;
  gap: 16px;
  grid-template-columns: 24px auto;
}
.comment .comment-line-container {
  display: grid;
  grid-template-rows: 24px auto;
}
.comment .comment-line-container .comment-monogram {
  align-items: center;
  background-color: #7D8099;
  border-radius: 50%;
  color: white;
  display: flex;
  font-size: 14px;
  justify-content: center;
  line-height: 20px;
  user-select: none;
}
.comment .comment-line-container .comment-vertical-line {
  background-color: #B3B3BE;
  justify-self: center;
  width: 2px;
}
.comment .comment-main-container .comment-content-container {
  align-items: start;
  display: flex;
  gap: 16px;
}
.comment .comment-main-container .comment-content-container .comment-sender {
  font-size: 16px;
  font-weight: 900;
  line-height: 24px;
}
.comment .comment-main-container .comment-content-container .comment-info, .comment .comment-main-container .comment-content-container .comment-message {
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 16px;
}
.comment .comment-main-container .comment-content-container .comment-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}
.comment .comment-main-container .comment-content-container .comment-tags .comment-tag {
  border: 1px solid #007993;
  border-radius: 18px;
  color: #007993;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.2px;
  padding: 11px 24px;
  white-space: nowrap;
}
.comment .comment-main-container .comment-horizontal-line {
  background-color: #B3B3BE;
  height: 1px;
  margin-bottom: 16px;
  margin-top: 24px;
  width: 100%;
}
.comment:last-child .comment-main-container .comment-horizontal-line {
  display: none;
}
.comment.dark .comment-line-container .comment-monogram {
  background-color: white;
  color: #000028;
}
.comment.dark .comment-line-container .comment-vertical-line {
  background-color: #4C4C68;
}
.comment.dark .comment-main-container .comment-content-container .comment-tags .comment-tag {
  border-color: #00CCCC;
  color: #00CCCC;
}
.comment.dark .comment-main-container .comment-horizontal-line {
  background-color: #4C4C68;
}

.comment-overlay {
  display: flex;
}
.comment-overlay .comment-overlay-dropdown-container {
  margin: -41px 0 0 -8px;
  pointer-events: none;
  position: absolute;
}
.comment-overlay .comment-overlay-dropdown-container .comment-overlay-dropdown {
  background-color: #F3F3F0;
  border-radius: 4px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 40, 0.25);
  margin-bottom: 8px;
  padding: 8px;
  pointer-events: auto;
  position: relative;
  width: 264px;
  white-space: normal;
  z-index: 2;
}
.comment-overlay .comment-overlay-dropdown-container .comment-overlay-dropdown .comment-overlay-dropdown-header {
  border-bottom: 1px #D9D9D6 solid;
  display: flex;
  gap: 8px;
  justify-content: end;
  padding-bottom: 8px;
}
.comment-overlay .comment-overlay-dropdown-container .comment-overlay-dropdown .comment-overlay-dropdown-content {
  display: grid;
  gap: 8px;
  grid-template-columns: 24px 1fr;
  padding-top: 8px;
}
.comment-overlay .comment-overlay-dropdown-container .comment-overlay-dropdown .comment-overlay-dropdown-content svg {
  fill: #000028;
  width: 24px;
}
.comment-overlay .comment-overlay-dropdown-container .comment-overlay-dropdown .comment-overlay-dropdown-content .comment-overlay-dropdown-message-container .comment-overlay-dropdown-messages {
  display: flex;
  flex-direction: column-reverse;
  gap: 16px;
  margin-bottom: 8px;
  max-height: 200px;
  overflow-y: auto;
}
.comment-overlay .comment-overlay-dropdown-container .comment-overlay-dropdown .comment-overlay-dropdown-content .comment-overlay-dropdown-message-container .comment-overlay-dropdown-messages::-webkit-scrollbar {
  width: 20px;
}
.comment-overlay .comment-overlay-dropdown-container .comment-overlay-dropdown .comment-overlay-dropdown-content .comment-overlay-dropdown-message-container .comment-overlay-dropdown-messages::-webkit-scrollbar-track {
  background-clip: content-box;
  background-color: #D9D9D6;
  border: 9px transparent solid;
}
.comment-overlay .comment-overlay-dropdown-container .comment-overlay-dropdown .comment-overlay-dropdown-content .comment-overlay-dropdown-message-container .comment-overlay-dropdown-messages::-webkit-scrollbar-thumb {
  background-clip: content-box;
  background-color: #007993;
  border: 8px transparent solid;
}
.comment-overlay .comment-overlay-dropdown-container .comment-overlay-dropdown .comment-overlay-dropdown-content .comment-overlay-dropdown-message-container .comment-overlay-dropdown-messages::-webkit-scrollbar-thumb:hover {
  background-color: #00646E;
}
.comment-overlay .comment-overlay-dropdown-container .comment-overlay-dropdown .comment-overlay-dropdown-content .comment-overlay-dropdown-message-container .comment-overlay-dropdown-messages .comment-overlay-dropdown-message .comment-overlay-dropdown-message-header {
  display: flex;
  justify-content: space-between;
  padding-bottom: 8px;
}
.comment-overlay .comment-overlay-dropdown-container .comment-overlay-dropdown .comment-overlay-dropdown-content .comment-overlay-dropdown-message-container .comment-overlay-dropdown-messages .comment-overlay-dropdown-message .comment-overlay-dropdown-message-header .comment-overlay-dropdown-message-sender {
  line-height: 20px;
}
.comment-overlay .comment-overlay-dropdown-container .comment-overlay-dropdown .comment-overlay-dropdown-content .comment-overlay-dropdown-message-container .comment-overlay-dropdown-messages .comment-overlay-dropdown-message .comment-overlay-dropdown-message-header .comment-overlay-dropdown-message-elapsed-time {
  color: #7D8099;
  line-height: 20px;
}
.comment-overlay .comment-overlay-dropdown-container .comment-overlay-dropdown.opening {
  animation: comment-overlay-dropdown-open 0.4s cubic-bezier(0.75, -0.02, 0.2, 0.97) forwards;
}
@keyframes comment-overlay-dropdown-open {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.comment-overlay .comment-overlay-dropdown-container .comment-overlay-dropdown.closing {
  animation: comment-overlay-dropdown-close 0.4s cubic-bezier(0.75, -0.02, 0.2, 0.97) forwards;
}
@keyframes comment-overlay-dropdown-close {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.comment-overlay .comment-overlay-dropdown-container .comment-overlay-dropdown.closed {
  visibility: hidden;
}
.comment-overlay.dark .comment-overlay-dropdown-container .comment-overlay-dropdown {
  background-color: #66667E;
}
.comment-overlay.dark .comment-overlay-dropdown-container .comment-overlay-dropdown .comment-overlay-dropdown-header {
  border-color: #B3B3BE;
}
.comment-overlay.dark .comment-overlay-dropdown-container .comment-overlay-dropdown .comment-overlay-dropdown-content svg {
  fill: white;
}
.comment-overlay.dark .comment-overlay-dropdown-container .comment-overlay-dropdown .comment-overlay-dropdown-content .comment-overlay-dropdown-message-container {
  color: white;
}
.comment-overlay.dark .comment-overlay-dropdown-container .comment-overlay-dropdown .comment-overlay-dropdown-content .comment-overlay-dropdown-message-container .comment-overlay-dropdown-messages::-webkit-scrollbar-track {
  background-color: white;
}
.comment-overlay.dark .comment-overlay-dropdown-container .comment-overlay-dropdown .comment-overlay-dropdown-content .comment-overlay-dropdown-message-container .comment-overlay-dropdown-messages::-webkit-scrollbar-thumb {
  background-color: #00CCCC;
}
.comment-overlay.dark .comment-overlay-dropdown-container .comment-overlay-dropdown .comment-overlay-dropdown-content .comment-overlay-dropdown-message-container .comment-overlay-dropdown-messages::-webkit-scrollbar-thumb:hover {
  background-color: #00FFB9;
}
.comment-overlay.dark .comment-overlay-dropdown-container .comment-overlay-dropdown .comment-overlay-dropdown-content .comment-overlay-dropdown-message-container .comment-overlay-dropdown-messages .comment-overlay-dropdown-message {
  color: white;
}
.comment-overlay.dark .comment-overlay-dropdown-container .comment-overlay-dropdown .comment-overlay-dropdown-content .comment-overlay-dropdown-message-container .comment-overlay-dropdown-messages .comment-overlay-dropdown-message .comment-overlay-dropdown-message-header .comment-overlay-dropdown-message-elapsed-time {
  color: #CCCCD4;
}

.content-container {
  width: 1350px;
  margin: 0 auto;
  background-color: inherit;
}
@media (max-width: 1450px) {
  .content-container {
    width: calc(100% - 2 * (17px + 2.1276595745%));
    padding: 0 calc(17px + 2.1276595745%);
  }
}

.data-table {
  background-color: inherit;
  position: relative;
}
.data-table::before, .data-table::after {
  bottom: 0;
  content: "";
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 10px;
}
.data-table::before {
  left: -11px;
}
.data-table::after {
  right: -10px;
}
.data-table .data-table-container {
  display: grid;
  height: 100%;
  margin: 0 -10px;
  overflow-x: auto;
  overflow-y: hidden;
  padding: 0 10px;
}
.data-table .data-table-container::-webkit-scrollbar {
  height: 24px;
}
.data-table .data-table-container::-webkit-scrollbar-track {
  background-clip: content-box;
  background-color: #D9D9D6;
  border: 11px transparent solid;
}
.data-table .data-table-container::-webkit-scrollbar-thumb {
  background-clip: content-box;
  background-color: #007993;
  border: 10px transparent solid;
}
.data-table .data-table-container::-webkit-scrollbar-thumb:hover {
  background-color: #00646E;
}
.data-table .data-table-container .data-table-grid {
  display: grid;
  height: 100%;
}
.data-table .data-table-container .data-table-grid .data-table-row {
  display: contents;
}
.data-table .data-table-container .data-table-grid .data-table-row .data-table-cell {
  background-clip: padding-box;
  color: #000028;
  font-size: 16px;
  line-height: 20px;
  padding: 10px 24px 9px;
}
.data-table .data-table-container .data-table-grid .data-table-row .data-table-cell.data-table-header-cell {
  background-color: #C5C5B8;
  border: 1px transparent solid;
  border-top: none;
  font-weight: 900;
}
.data-table .data-table-container .data-table-grid .data-table-row .data-table-cell.data-table-header-cell.data-table-normal-cell {
  border-left: none;
}
.data-table .data-table-container .data-table-grid .data-table-row .data-table-cell.data-table-header-cell.data-table-normal-cell.before-divider, .data-table .data-table-container .data-table-grid .data-table-row .data-table-cell.data-table-header-cell.data-table-normal-cell:last-child {
  border-right: 1px #C5C5B8 solid;
}
.data-table .data-table-container .data-table-grid .data-table-row .data-table-cell.data-table-data-cell.data-table-initial-cell {
  background-color: #DFDFD9;
  border: 1px transparent solid;
  font-weight: 900;
}
.data-table .data-table-container .data-table-grid .data-table-row .data-table-cell.data-table-data-cell.data-table-normal-cell {
  border: 1px #DFDFD9 solid;
  border-left: none;
}
.data-table .data-table-container .data-table-grid .data-table-row .data-table-cell.data-table-data-cell.data-table-normal-cell.data-table-highlighted-cell {
  background-color: #D72339;
  color: white;
}
.data-table .data-table-container .data-table-grid .data-table-row:not(:last-child) .data-table-cell.data-table-header-cell, .data-table .data-table-container .data-table-grid .data-table-row:not(:last-child) .data-table-cell.data-table-data-cell {
  border-bottom: none;
}
.data-table .data-table-container.no-overscroll {
  overscroll-behavior-x: none;
}
.data-table .data-table-container.no-scrollbar {
  scrollbar-width: none;
}
.data-table .data-table-container.no-scrollbar::-webkit-scrollbar {
  display: none;
}
.data-table.data-table-layer-1::before {
  background: linear-gradient(to right, white, rgba(255, 255, 255, 0));
}
.data-table.data-table-layer-1::after {
  background: linear-gradient(to left, white, rgba(255, 255, 255, 0));
}
.data-table.data-table-layer-2::before {
  background: linear-gradient(to right, #F3F3F0, rgba(243, 243, 240, 0));
}
.data-table.data-table-layer-2::after {
  background: linear-gradient(to left, #F3F3F0, rgba(243, 243, 240, 0));
}
.data-table.data-table-layer-3::before {
  background: linear-gradient(to right, #DFDFD9, rgba(223, 223, 217, 0));
}
.data-table.data-table-layer-3::after {
  background: linear-gradient(to left, #DFDFD9, rgba(223, 223, 217, 0));
}
.data-table.data-table-layer-3 .data-table-container .data-table-grid .data-table-row .data-table-cell.data-table-header-cell {
  background-color: #AAAA96;
}
.data-table.data-table-layer-3 .data-table-container .data-table-grid .data-table-row .data-table-cell.data-table-header-cell.data-table-normal-cell.before-divider, .data-table.data-table-layer-3 .data-table-container .data-table-grid .data-table-row .data-table-cell.data-table-header-cell.data-table-normal-cell:last-child {
  border-color: #AAAA96;
}
.data-table.data-table-layer-3 .data-table-container .data-table-grid .data-table-row .data-table-cell.data-table-data-cell.data-table-initial-cell {
  background-color: #C5C5B8;
}
.data-table.data-table-layer-3 .data-table-container .data-table-grid .data-table-row .data-table-cell.data-table-data-cell.data-table-normal-cell {
  border-color: #C5C5B8;
}
.data-table.dark .data-table-container::-webkit-scrollbar-track {
  background-color: white;
}
.data-table.dark .data-table-container::-webkit-scrollbar-thumb {
  background-color: #00CCCC;
}
.data-table.dark .data-table-container::-webkit-scrollbar-thumb:hover {
  background-color: #00FFB9;
}
.data-table.dark .data-table-container .data-table-grid .data-table-row .data-table-cell {
  color: white;
}
.data-table.dark .data-table-container .data-table-grid .data-table-row .data-table-cell.data-table-header-cell {
  background-color: #737389;
}
.data-table.dark .data-table-container .data-table-grid .data-table-row .data-table-cell.data-table-header-cell.data-table-normal-cell.before-divider, .data-table.dark .data-table-container .data-table-grid .data-table-row .data-table-cell.data-table-header-cell.data-table-normal-cell:last-child {
  border-right: 1px #737389 solid;
}
.data-table.dark .data-table-container .data-table-grid .data-table-row .data-table-cell.data-table-data-cell.data-table-initial-cell {
  background-color: #40415C;
}
.data-table.dark .data-table-container .data-table-grid .data-table-row .data-table-cell.data-table-data-cell.data-table-normal-cell {
  border-color: #40415C;
}
.data-table.dark .data-table-container .data-table-grid .data-table-row .data-table-cell.data-table-data-cell.data-table-normal-cell.data-table-highlighted-cell {
  background-color: #FF2640;
}
.data-table.dark.data-table-layer-1::before {
  background: linear-gradient(to right, #000028, rgba(0, 0, 40, 0));
}
.data-table.dark.data-table-layer-1::after {
  background: linear-gradient(to left, #000028, rgba(0, 0, 40, 0));
}
.data-table.dark.data-table-layer-2::before {
  background: linear-gradient(to right, #23233C, rgba(35, 35, 60, 0));
}
.data-table.dark.data-table-layer-2::after {
  background: linear-gradient(to left, #23233C, rgba(35, 35, 60, 0));
}
.data-table.dark.data-table-layer-3::before {
  background: linear-gradient(to right, #37374D, rgba(55, 55, 77, 0));
}
.data-table.dark.data-table-layer-3::after {
  background: linear-gradient(to left, #37374D, rgba(55, 55, 77, 0));
}
.data-table.dark.data-table-layer-3 .data-table-container .data-table-grid .data-table-row .data-table-cell.data-table-header-cell {
  background-color: #7D8099;
}
.data-table.dark.data-table-layer-3 .data-table-container .data-table-grid .data-table-row .data-table-cell.data-table-header-cell.data-table-normal-cell.before-divider, .data-table.dark.data-table-layer-3 .data-table-container .data-table-grid .data-table-row .data-table-cell.data-table-header-cell.data-table-normal-cell:last-child {
  border-color: #7D8099;
}
.data-table.dark.data-table-layer-3 .data-table-container .data-table-grid .data-table-row .data-table-cell.data-table-data-cell.data-table-initial-cell {
  background-color: #66667E;
}
.data-table.dark.data-table-layer-3 .data-table-container .data-table-grid .data-table-row .data-table-cell.data-table-data-cell.data-table-normal-cell {
  border-color: #66667E;
}

.date-input .date-input-field {
  position: relative;
  min-width: 205px;
  box-sizing: border-box;
  padding: 16px 14px 6px;
  color: #4C4C68;
  background-color: #EBF7F8;
  border-bottom: 1px solid #4C4C68;
  outline: none;
  user-select: none;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  transition: color 0.3s ease-in-out, background 0.3s ease-in-out, border 0.3s ease-in-out;
}
.date-input .date-input-field .date-input-label {
  position: absolute;
  top: 2px;
  bottom: 2px;
  display: flex;
  align-items: center;
  font-size: 16px;
  user-select: none;
  pointer-events: none;
  transition: color 0.3s ease-in-out, font-size 0.15s ease-in-out, bottom 0.15s ease-in-out;
}
.date-input .date-input-field .date-input-label.filled {
  bottom: 24px;
  font-size: 10px;
}
.date-input .date-input-field .date-input-label.required::before {
  position: absolute;
  content: "*";
  right: calc(100% + 2px);
}
.date-input .date-input-field .date-input-value-container .date-input-value {
  display: inline-block;
  border-radius: 1px;
  outline: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.date-input .date-input-field:focus-within .date-input-value-container .date-input-value.active {
  color: white;
  background-color: #00D7A0;
}
.date-input .date-input-field:hover, .date-input .date-input-field:focus-within {
  color: #000028;
  background-color: #D1FFF2;
  border-color: #00D7A0;
}
.date-input .date-input-field:hover .date-input-label, .date-input .date-input-field:focus-within .date-input-label {
  color: #000028;
}
.date-input .date-input-message {
  margin: 3px 14px 0;
  font-size: 10px;
  color: #000028;
  user-select: none;
}
.date-input.error:not(.disabled) .date-input-field:not(:focus-within) {
  color: #000028;
  background-color: #FCCCD7;
  border-color: #FF2640;
}
.date-input.error:not(.disabled) .date-input-field:not(:focus-within) .date-input-label {
  color: #FF2640 !important;
}
.date-input.error:not(.disabled) .date-input-field:not(:focus-within) .date-input-value-container .date-input-value:not(.active):hover {
  color: white;
  background-color: #FF2640;
}
.date-input.error:not(.disabled) .date-input-message {
  color: #FF2640;
}
.date-input:not(.disabled) .date-input-field .date-input-value-container .date-input-value:not(.active):hover {
  background-color: #62EEC7;
}
.date-input.disabled .date-input-field {
  color: #7D8099;
  background-color: #EBF7F8;
  border-color: transparent;
  cursor: not-allowed;
}
.date-input.disabled .date-input-field .date-input-label {
  color: #7D8099;
}
.date-input.dark .date-input-field {
  color: #EBEBEE;
  background-color: #00183B;
  border-color: #7D8099;
}
.date-input.dark .date-input-field .date-input-label {
  color: #7D8099;
}
.date-input.dark .date-input-field:focus-within .date-input-value-container .date-input-value.active {
  color: #000028;
  background-color: #00FFB9;
}
.date-input.dark .date-input-field:hover, .date-input.dark .date-input-field:focus-within {
  color: #EBEBEE;
  background-color: #001F39;
  border-color: #00FFB9;
}
.date-input.dark .date-input-field:hover .date-input-label, .date-input.dark .date-input-field:focus-within .date-input-label {
  color: #00FFB9;
}
.date-input.dark .date-input-message {
  color: #EBEBEE;
}
.date-input.dark.error:not(.disabled) .date-input-field:not(:focus-within) {
  color: #EBEBEE;
  background-color: #331131;
  border-color: #FF5454;
}
.date-input.dark.error:not(.disabled) .date-input-field:not(:focus-within) .date-input-label {
  color: #FF5454 !important;
}
.date-input.dark.error:not(.disabled) .date-input-field:not(:focus-within) .date-input-value-container .date-input-value:not(.active):hover {
  background-color: #FF5454;
}
.date-input.dark.error:not(.disabled) .date-input-message {
  color: #7D8099;
}
.date-input.dark:not(.disabled) .date-input-field .date-input-value-container .date-input-value:not(.active):hover {
  color: white;
  background-color: #005159;
}
.date-input.dark.disabled .date-input-field, .date-input.dark.disabled .date-input-field:hover {
  color: #66667E;
  background-color: #00183B;
  border-color: transparent;
}
.date-input.dark.disabled .date-input-field .date-input-label, .date-input.dark.disabled .date-input-field:hover .date-input-label {
  color: #66667E;
}

.dot {
  border-radius: 4px;
  height: 8px;
  width: 8px;
}
.dot.dot-big {
  border-radius: 8px;
  height: 16px;
  width: 16px;
}
.dot.dot-blue {
  background-color: #007EB1;
}
.dot.dot-green {
  background-color: #01893A;
}
.dot.dot-red {
  background-color: #D72339;
}
.dot.dot-yellow {
  background-color: #E9C32A;
}
.dot.dark.dot-blue {
  background-color: #00BEDC;
}
.dot.dark.dot-green {
  background-color: #01D65A;
}
.dot.dark.dot-red {
  background-color: #FF2640;
}
.dot.dark.dot-yellow {
  background-color: #FFD732;
}

.file-upload {
  align-items: center;
  background-color: #EBF7F8;
  border-bottom: 1px solid #4C4C68;
  display: flex;
  flex-direction: column;
  gap: 24px;
  min-width: 205px;
  padding: 67px 14px 41px;
  transition: border 0.3s ease-in-out;
}
.file-upload .file-upload-label {
  align-items: center;
  color: #000028;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-weight: 700;
  gap: 8px;
  line-height: 24px;
}
.file-upload .file-upload-label svg {
  fill: #000028;
  width: 40px;
}
.file-upload input[type=file] {
  display: none;
}
.file-upload.highlighted:not(:focus-within):not(:hover) {
  border-color: #007993;
}
.file-upload.dark {
  background-color: #00183B;
  border-color: #7D8099;
  color: #EBEBEE;
}
.file-upload.dark .file-upload-label {
  color: white;
}
.file-upload.dark .file-upload-label svg {
  fill: white;
}
.file-upload.dark.highlighted:not(:focus-within):not(:hover) {
  border-color: #00CCCC;
}

.flex {
  display: flex;
}
.flex.alignment-baseline {
  align-items: baseline;
}
.flex.alignment-center {
  align-items: center;
}
.flex.alignment-end {
  align-items: end;
}
.flex.alignment-start {
  align-items: start;
}
.flex.alignment-stretch {
  align-items: stretch;
}
.flex.direction-column {
  height: 100%;
  flex-direction: column;
}
.flex.direction-column-reverse {
  height: 100%;
  flex-direction: column-reverse;
}
.flex.direction-row {
  flex-direction: row;
}
.flex.direction-row-reverse {
  flex-direction: row-reverse;
}
.flex.justification-center {
  justify-content: center;
}
.flex.justification-flex-end {
  justify-content: flex-end;
}
.flex.justification-flex-start {
  justify-content: flex-start;
}
.flex.justification-space-around {
  justify-content: space-around;
}
.flex.justification-space-evenly {
  justify-content: space-evenly;
}
.flex.justification-space-between {
  justify-content: space-between;
}
.flex.wrap-no-wrap {
  flex-wrap: nowrap;
}
.flex.wrap-wrap {
  flex-wrap: wrap;
}
.flex.wrap-wrap-reverse {
  flex-wrap: wrap-reverse;
}

.grid-container {
  display: grid;
  background-color: inherit;
}
.grid-container.grid-columns-1 {
  grid-template: 1fr/repeat(1, 1fr);
}
.grid-container.grid-columns-2 {
  grid-template: 1fr/repeat(2, 1fr);
}
.grid-container.grid-columns-3 {
  grid-template: 1fr/repeat(3, 1fr);
}
.grid-container.grid-columns-4 {
  grid-template: 1fr/repeat(4, 1fr);
}
.grid-container.grid-columns-5 {
  grid-template: 1fr/repeat(5, 1fr);
}
.grid-container.grid-columns-6 {
  grid-template: 1fr/repeat(6, 1fr);
}
.grid-container.grid-columns-7 {
  grid-template: 1fr/repeat(7, 1fr);
}
.grid-container.grid-columns-8 {
  grid-template: 1fr/repeat(8, 1fr);
}
.grid-container.grid-columns-9 {
  grid-template: 1fr/repeat(9, 1fr);
}
.grid-container.grid-columns-10 {
  grid-template: 1fr/repeat(10, 1fr);
}
@media (max-width: 1500px) {
  .grid-container.grid-columns-1500-1 {
    grid-template: 1fr/repeat(1, 1fr);
  }
  .grid-container.grid-columns-1500-2 {
    grid-template: 1fr/repeat(2, 1fr);
  }
  .grid-container.grid-columns-1500-3 {
    grid-template: 1fr/repeat(3, 1fr);
  }
  .grid-container.grid-columns-1500-4 {
    grid-template: 1fr/repeat(4, 1fr);
  }
  .grid-container.grid-columns-1500-5 {
    grid-template: 1fr/repeat(5, 1fr);
  }
  .grid-container.grid-columns-1500-6 {
    grid-template: 1fr/repeat(6, 1fr);
  }
  .grid-container.grid-columns-1500-7 {
    grid-template: 1fr/repeat(7, 1fr);
  }
  .grid-container.grid-columns-1500-8 {
    grid-template: 1fr/repeat(8, 1fr);
  }
  .grid-container.grid-columns-1500-9 {
    grid-template: 1fr/repeat(9, 1fr);
  }
  .grid-container.grid-columns-1500-10 {
    grid-template: 1fr/repeat(10, 1fr);
  }
}
@media (max-width: 1200px) {
  .grid-container.grid-columns-1200-1 {
    grid-template: 1fr/repeat(1, 1fr);
  }
  .grid-container.grid-columns-1200-2 {
    grid-template: 1fr/repeat(2, 1fr);
  }
  .grid-container.grid-columns-1200-3 {
    grid-template: 1fr/repeat(3, 1fr);
  }
  .grid-container.grid-columns-1200-4 {
    grid-template: 1fr/repeat(4, 1fr);
  }
  .grid-container.grid-columns-1200-5 {
    grid-template: 1fr/repeat(5, 1fr);
  }
  .grid-container.grid-columns-1200-6 {
    grid-template: 1fr/repeat(6, 1fr);
  }
  .grid-container.grid-columns-1200-7 {
    grid-template: 1fr/repeat(7, 1fr);
  }
  .grid-container.grid-columns-1200-8 {
    grid-template: 1fr/repeat(8, 1fr);
  }
  .grid-container.grid-columns-1200-9 {
    grid-template: 1fr/repeat(9, 1fr);
  }
  .grid-container.grid-columns-1200-10 {
    grid-template: 1fr/repeat(10, 1fr);
  }
}
@media (max-width: 992px) {
  .grid-container.grid-columns-992-1 {
    grid-template: 1fr/repeat(1, 1fr);
  }
  .grid-container.grid-columns-992-2 {
    grid-template: 1fr/repeat(2, 1fr);
  }
  .grid-container.grid-columns-992-3 {
    grid-template: 1fr/repeat(3, 1fr);
  }
  .grid-container.grid-columns-992-4 {
    grid-template: 1fr/repeat(4, 1fr);
  }
  .grid-container.grid-columns-992-5 {
    grid-template: 1fr/repeat(5, 1fr);
  }
  .grid-container.grid-columns-992-6 {
    grid-template: 1fr/repeat(6, 1fr);
  }
  .grid-container.grid-columns-992-7 {
    grid-template: 1fr/repeat(7, 1fr);
  }
  .grid-container.grid-columns-992-8 {
    grid-template: 1fr/repeat(8, 1fr);
  }
  .grid-container.grid-columns-992-9 {
    grid-template: 1fr/repeat(9, 1fr);
  }
  .grid-container.grid-columns-992-10 {
    grid-template: 1fr/repeat(10, 1fr);
  }
}
@media (max-width: 768px) {
  .grid-container.grid-columns-768-1 {
    grid-template: 1fr/repeat(1, 1fr);
  }
  .grid-container.grid-columns-768-2 {
    grid-template: 1fr/repeat(2, 1fr);
  }
  .grid-container.grid-columns-768-3 {
    grid-template: 1fr/repeat(3, 1fr);
  }
  .grid-container.grid-columns-768-4 {
    grid-template: 1fr/repeat(4, 1fr);
  }
  .grid-container.grid-columns-768-5 {
    grid-template: 1fr/repeat(5, 1fr);
  }
  .grid-container.grid-columns-768-6 {
    grid-template: 1fr/repeat(6, 1fr);
  }
  .grid-container.grid-columns-768-7 {
    grid-template: 1fr/repeat(7, 1fr);
  }
  .grid-container.grid-columns-768-8 {
    grid-template: 1fr/repeat(8, 1fr);
  }
  .grid-container.grid-columns-768-9 {
    grid-template: 1fr/repeat(9, 1fr);
  }
  .grid-container.grid-columns-768-10 {
    grid-template: 1fr/repeat(10, 1fr);
  }
}
@media (max-width: 600px) {
  .grid-container.grid-columns-600-1 {
    grid-template: 1fr/repeat(1, 1fr);
  }
  .grid-container.grid-columns-600-2 {
    grid-template: 1fr/repeat(2, 1fr);
  }
  .grid-container.grid-columns-600-3 {
    grid-template: 1fr/repeat(3, 1fr);
  }
  .grid-container.grid-columns-600-4 {
    grid-template: 1fr/repeat(4, 1fr);
  }
  .grid-container.grid-columns-600-5 {
    grid-template: 1fr/repeat(5, 1fr);
  }
  .grid-container.grid-columns-600-6 {
    grid-template: 1fr/repeat(6, 1fr);
  }
  .grid-container.grid-columns-600-7 {
    grid-template: 1fr/repeat(7, 1fr);
  }
  .grid-container.grid-columns-600-8 {
    grid-template: 1fr/repeat(8, 1fr);
  }
  .grid-container.grid-columns-600-9 {
    grid-template: 1fr/repeat(9, 1fr);
  }
  .grid-container.grid-columns-600-10 {
    grid-template: 1fr/repeat(10, 1fr);
  }
}
@media (max-width: 480px) {
  .grid-container.grid-columns-480-1 {
    grid-template: 1fr/repeat(1, 1fr);
  }
  .grid-container.grid-columns-480-2 {
    grid-template: 1fr/repeat(2, 1fr);
  }
  .grid-container.grid-columns-480-3 {
    grid-template: 1fr/repeat(3, 1fr);
  }
  .grid-container.grid-columns-480-4 {
    grid-template: 1fr/repeat(4, 1fr);
  }
  .grid-container.grid-columns-480-5 {
    grid-template: 1fr/repeat(5, 1fr);
  }
  .grid-container.grid-columns-480-6 {
    grid-template: 1fr/repeat(6, 1fr);
  }
  .grid-container.grid-columns-480-7 {
    grid-template: 1fr/repeat(7, 1fr);
  }
  .grid-container.grid-columns-480-8 {
    grid-template: 1fr/repeat(8, 1fr);
  }
  .grid-container.grid-columns-480-9 {
    grid-template: 1fr/repeat(9, 1fr);
  }
  .grid-container.grid-columns-480-10 {
    grid-template: 1fr/repeat(10, 1fr);
  }
}

.headline {
  max-width: 510px;
  display: flex;
}
.headline .headline-text .headline-label {
  animation: headline-label-appear 0.6s ease-in-out forwards;
  padding-bottom: 15px;
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
}
@keyframes headline-label-appear {
  0% {
    opacity: 0;
    transform: translateY(40px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.headline .headline-text .headline-title {
  animation: headline-title-appear 0.6s ease-in-out forwards;
  font-size: 60px;
  font-style: normal;
  font-weight: 900;
  line-height: 56px;
}
@keyframes headline-title-appear {
  0% {
    opacity: 0;
    transform: translateY(40px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.headline .headline-text .headline-description {
  animation: headline-description-appear 0.6s ease-in-out forwards;
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
  padding-top: 16px;
}
@keyframes headline-description-appear {
  0% {
    opacity: 0;
    transform: translateY(60px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.hover-menu {
  display: flex;
}
.hover-menu .hover-menu-dropdown-container {
  margin-top: 38px;
  pointer-events: none;
  position: absolute;
}
.hover-menu .hover-menu-dropdown-container .hover-menu-dropdown {
  background-color: #F3F3F0;
  border-radius: 4px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 40, 0.25);
  margin-bottom: 8px;
  min-width: 104px;
  padding: 10px 8px;
  pointer-events: auto;
  position: relative;
  white-space: nowrap;
  z-index: 2;
}
.hover-menu .hover-menu-dropdown-container .hover-menu-dropdown.opening {
  animation: hover-menu-dropdown-open 0.4s cubic-bezier(0.75, -0.02, 0.2, 0.97) forwards;
}
@keyframes hover-menu-dropdown-open {
  0% {
    opacity: 0;
    transform: translate(0, 20px);
  }
  100% {
    opacity: 1;
    transform: translate(0);
  }
}
.hover-menu .hover-menu-dropdown-container .hover-menu-dropdown.closing {
  animation: hover-menu-dropdown-close 0.4s cubic-bezier(0.75, -0.02, 0.2, 0.97) forwards;
}
@keyframes hover-menu-dropdown-close {
  0% {
    opacity: 1;
    transform: translate(0);
  }
  100% {
    opacity: 0;
    transform: translate(0, 20px);
  }
}
.hover-menu .hover-menu-dropdown-container .hover-menu-dropdown.closed {
  visibility: hidden;
}
.hover-menu.position-left {
  position: relative;
}
.hover-menu.position-left .hover-menu-dropdown-container .hover-menu-dropdown {
  right: calc(100% - 30px);
}
.hover-menu.position-right {
  position: relative;
}
.hover-menu.position-right .hover-menu-dropdown-container {
  margin-left: -6px;
}
.hover-menu.position-scroll .hover-menu-dropdown-container {
  margin-left: -6px;
}
.hover-menu.position-scroll-end .hover-menu-dropdown-container {
  right: 0;
}
.hover-menu.dark .hover-menu-dropdown-container .hover-menu-dropdown {
  background-color: #66667E;
}

.icon-button {
  color: white;
  cursor: pointer;
  display: inline-flex;
  outline: none;
  position: relative;
  user-select: none;
  z-index: 1;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.icon-button::before {
  border-radius: 4px;
  bottom: -6px;
  content: "";
  left: -6px;
  pointer-events: none;
  position: absolute;
  right: -6px;
  top: -6px;
  transition: background 0.3s ease-in-out;
  z-index: -1;
}
.icon-button svg {
  fill: #000028;
  transition: fill 0.3s ease-in-out;
}
.icon-button.icon-button-16 svg {
  width: 16px;
}
.icon-button.icon-button-20 svg {
  width: 20px;
}
.icon-button.icon-button-24 svg {
  width: 24px;
}
.icon-button.icon-button-40::before {
  bottom: -2px;
  left: -2px;
  right: -2px;
  top: -2px;
}
.icon-button.icon-button-40 svg {
  width: 40px;
}
.icon-button.secondary svg {
  fill: #7D8099;
}
.icon-button.transparent::before {
  opacity: 0.4;
}
.icon-button.disabled {
  cursor: not-allowed;
}
.icon-button.disabled svg {
  fill: #B3B3BE;
}
.icon-button:not(.disabled):hover.icon-button-layer-1::before, .icon-button:not(.disabled):active.icon-button-layer-1::before, .icon-button.open.icon-button-layer-1::before {
  background: #F3F3F0;
}
.icon-button:not(.disabled):hover.icon-button-layer-2::before, .icon-button:not(.disabled):active.icon-button-layer-2::before, .icon-button.open.icon-button-layer-2::before {
  background: #DFDFD9;
}
.icon-button:not(.disabled):hover.icon-button-layer-3::before, .icon-button:not(.disabled):active.icon-button-layer-3::before, .icon-button.open.icon-button-layer-3::before {
  background: #C5C5B8;
}
.icon-button.open svg {
  fill: #005159;
}
.icon-button:not(.disabled):focus-visible::before {
  outline: 1px solid #1491EB;
  outline-offset: -1px;
}
.icon-button.dark svg {
  fill: white;
}
.icon-button.dark.secondary svg {
  fill: #66667E;
}
.icon-button.dark.disabled svg {
  fill: #737389;
}
.icon-button.dark:not(.disabled):hover.icon-button-layer-1::before, .icon-button.dark:not(.disabled):active.icon-button-layer-1::before, .icon-button.dark.open.icon-button-layer-1::before {
  background: #23233C;
}
.icon-button.dark:not(.disabled):hover.icon-button-layer-2::before, .icon-button.dark:not(.disabled):active.icon-button-layer-2::before, .icon-button.dark.open.icon-button-layer-2::before {
  background: #37374D;
}
.icon-button.dark:not(.disabled):hover.icon-button-layer-3::before, .icon-button.dark:not(.disabled):active.icon-button-layer-3::before, .icon-button.dark.open.icon-button-layer-3::before {
  background: #4C4C68;
}
.icon-button.dark.open svg {
  fill: #00FFB9;
}

.image {
  width: 100%;
}

.input-button {
  background-color: #EBF7F8;
  border-bottom: 1px solid #4C4C68;
  color: #4C4C68;
  cursor: pointer;
  display: grid;
  gap: 5px;
  grid-template-columns: 1fr auto;
  min-width: 205px;
  outline: none;
  padding: 0 5px 0 14px;
  position: relative;
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out, border 0.3s ease-in-out;
  user-select: none;
}
.input-button .input-button-label {
  align-items: center;
  bottom: 2px;
  color: #4C4C68;
  display: flex;
  font-size: 16px;
  left: 14px;
  pointer-events: none;
  position: absolute;
  top: 2px;
  transition: color 0.3s ease-in-out, font-size 0.15s ease-in-out, bottom 0.15s ease-in-out;
  user-select: none;
}
.input-button .input-button-label.required::before {
  content: "*";
  position: absolute;
  right: calc(100% + 2px);
}
.input-button .input-button-value {
  opacity: 0;
  overflow: hidden;
  padding: 16px 0 6px;
  text-overflow: ellipsis;
  transition: opacity 0.3s ease-in-out;
  white-space: nowrap;
}
.input-button .input-button-reset {
  align-items: center;
  border-radius: 100%;
  cursor: pointer;
  display: flex;
  margin: 16px 0 6px;
  transition: background-color 0.2s ease-in-out;
}
.input-button .input-button-reset svg {
  fill: #4C4C68;
  padding: 0 2px;
  transition: fill 0.3s ease-in-out;
  width: 15px;
}
.input-button .input-button-reset:hover {
  background-color: #62EEC7;
}
.input-button .input-button-reset:hover svg {
  color: #000028;
}
.input-button .input-button-icon {
  align-items: center;
  display: flex;
}
.input-button .input-button-icon svg {
  fill: #4C4C68;
  transition: fill 0.3s ease-in-out;
  width: 20px;
}
.input-button.disabled {
  background-color: #EBF7F8;
  border-color: transparent;
  color: #7D8099;
  cursor: not-allowed;
}
.input-button.disabled .input-button-label {
  color: #7D8099;
}
.input-button.disabled .input-button-icon svg {
  fill: #7D8099;
}
.input-button.filled .input-button-label {
  bottom: 24px;
  font-size: 10px;
}
.input-button.filled .input-button-value {
  opacity: 1;
}
.input-button.highlighted:not(.disabled):not(:focus-within):not(:hover) {
  border-color: #007993;
}
.input-button.highlighted:not(.disabled):not(:focus-within):not(:hover) .input-button-label {
  color: #007993;
}
.input-button.with-reset {
  grid-template-columns: 1fr auto auto;
}
.input-button:hover, .input-button:focus-within {
  background-color: #D1FFF2;
  border-color: #00D7A0;
  color: #000028;
}
.input-button:hover .input-button-label, .input-button:focus-within .input-button-label {
  color: #000028;
}
.input-button:hover .input-button-reset svg, .input-button:focus-within .input-button-reset svg {
  fill: #000028;
}
.input-button:hover .input-button-icon svg, .input-button:focus-within .input-button-icon svg {
  fill: #000028;
}
.input-button.dark {
  background-color: #00183B;
  border-color: #7D8099;
  color: #EBEBEE;
}
.input-button.dark .input-button-label {
  color: #7D8099;
}
.input-button.dark .input-button-reset svg {
  fill: #EBEBEE;
}
.input-button.dark .input-button-reset:hover {
  background-color: #66667E;
}
.input-button.dark .input-button-reset:hover svg {
  color: #EBEBEE;
}
.input-button.dark .input-button-icon svg {
  fill: #7D8099;
}
.input-button.dark.disabled, .input-button.dark.disabled:hover {
  background-color: #00183B;
  border-color: transparent;
  color: #66667E;
}
.input-button.dark.disabled .input-button-label, .input-button.dark.disabled:hover .input-button-label {
  color: #66667E;
}
.input-button.dark.disabled .input-button-icon svg, .input-button.dark.disabled:hover .input-button-icon svg {
  fill: #66667E;
}
.input-button.dark.highlighted:not(.disabled):not(:focus-within):not(:hover) {
  border-color: #00CCCC;
}
.input-button.dark.highlighted:not(.disabled):not(:focus-within):not(:hover) .input-button-label {
  color: #00CCCC;
}
.input-button.dark:hover, .input-button.dark:focus-within {
  background-color: #001F39;
  border-color: #00FFB9;
  color: #EBEBEE;
}
.input-button.dark:hover .input-button-label, .input-button.dark:focus-within .input-button-label {
  color: #EBEBEE;
}
.input-button.dark:hover .input-button-reset svg, .input-button.dark:focus-within .input-button-reset svg {
  fill: #EBEBEE;
}
.input-button.dark:hover .input-button-icon svg, .input-button.dark:focus-within .input-button-icon svg {
  fill: #00FFB9;
}
.input-button.dark:hover.filled .input-button-label, .input-button.dark:focus-within.filled .input-button-label {
  color: #00FFB9;
}

.image-dropdown {
  background-color: #EBF7F8;
  border-bottom: 1px solid #B3B3BE;
  cursor: pointer;
  height: 68px;
  outline: none;
  position: relative;
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out, border 0.3s ease-in-out;
  width: 104px;
}
.image-dropdown .image-dropdown-opener {
  align-items: center;
  display: grid;
  grid-template-columns: 1fr auto;
}
.image-dropdown .image-dropdown-opener .image-dropdown-arrow {
  bottom: 0;
  display: flex;
  position: absolute;
  right: 0;
}
.image-dropdown .image-dropdown-opener .image-dropdown-arrow svg {
  fill: #4C4C68;
  transition: fill 0.3s ease-in-out, transform 0.5s ease-in-out;
  width: 20px;
}
.image-dropdown .image-dropdown-opener .image-dropdown-value {
  align-items: center;
  cursor: pointer;
  display: flex;
  transition: background-color 0.3s ease-in-out;
}
.image-dropdown .image-dropdown-opener .image-dropdown-value img, .image-dropdown .image-dropdown-opener .image-dropdown-value svg {
  height: 68px;
}
.image-dropdown .image-dropdown-opener:hover:not(.disabled) {
  background-color: #D1FFF2;
  border-color: #00D7A0;
}
.image-dropdown .image-dropdown-opener:hover:not(.disabled) .image-dropdown-arrow svg {
  fill: #000028;
}
.image-dropdown .image-dropdown-values {
  background-color: #D1FFF2;
  max-height: 0;
  overflow-y: auto;
  position: absolute;
  top: calc(100% + 1px);
  transition: max-height 0.5s ease-in-out;
  z-index: 2;
}
.image-dropdown .image-dropdown-values::-webkit-scrollbar {
  width: 6px;
}
.image-dropdown .image-dropdown-values::-webkit-scrollbar-track {
  background-color: inherit;
}
.image-dropdown .image-dropdown-values::-webkit-scrollbar-thumb {
  background-color: #91F3D7;
}
.image-dropdown .image-dropdown-values::-webkit-scrollbar-thumb:hover {
  background-color: #B0F6E3;
}
.image-dropdown .image-dropdown-values .image-dropdown-value {
  align-items: center;
  cursor: pointer;
  display: grid;
  grid-template-columns: minmax(60px, 100px) 1fr;
  overflow: hidden;
  position: relative;
}
.image-dropdown .image-dropdown-values .image-dropdown-value.selected:not(.disabled) {
  background-color: #62EEC7;
}
.image-dropdown .image-dropdown-values .image-dropdown-value.disabled {
  background-color: #EBF7F8;
  border-color: transparent;
  color: #66667E;
  cursor: not-allowed;
}
.image-dropdown .image-dropdown-values .image-dropdown-value img, .image-dropdown .image-dropdown-values .image-dropdown-value svg {
  height: 88px;
  width: 100%;
}
@media (max-width: 479px) {
  .image-dropdown .image-dropdown-values .image-dropdown-value img, .image-dropdown .image-dropdown-values .image-dropdown-value svg {
    height: 110px;
  }
}
.image-dropdown .image-dropdown-values .image-dropdown-value .image-dropdown-value-text-container {
  height: 68px;
  padding: 16px 16px 4px 10px;
}
@media (max-width: 479px) {
  .image-dropdown .image-dropdown-values .image-dropdown-value .image-dropdown-value-text-container {
    height: 110px;
  }
}
.image-dropdown .image-dropdown-values .image-dropdown-value .image-dropdown-value-text-container .image-dropdown-value-title {
  font-weight: 700;
}
.image-dropdown .image-dropdown-values .image-dropdown-value .image-dropdown-value-text-container .image-dropdown-value-description {
  font-size: 10px;
  padding-top: 8px;
}
.image-dropdown.disabled {
  background-color: #EBF7F8;
  border-color: transparent;
  color: #7D8099;
  cursor: not-allowed;
}
.image-dropdown.disabled .image-dropdown-value {
  cursor: not-allowed;
}
.image-dropdown.open {
  border-color: #00D7A0;
}
.image-dropdown.open .image-dropdown-arrow svg {
  fill: #000028;
}
.image-dropdown.open .image-dropdown-value {
  background-color: #D1FFF2;
}
.image-dropdown.open .image-dropdown-values {
  background-color: #D1FFF2;
}
.image-dropdown.open .image-dropdown-values.image-dropdown-values-1 {
  max-height: 88px;
}
.image-dropdown.open .image-dropdown-values.image-dropdown-values-2 {
  max-height: 176px;
}
.image-dropdown.open .image-dropdown-values.image-dropdown-values-3 {
  max-height: 264px;
}
.image-dropdown.open .image-dropdown-opener .image-dropdown-arrow svg {
  transform: rotate(-180deg);
}
.image-dropdown.dark .image-dropdown-value {
  background-color: #00183B;
}
.image-dropdown.dark .image-dropdown-opener .image-dropdown-arrow svg {
  fill: #7D8099;
}
.image-dropdown.dark .image-dropdown-value-text-container {
  color: white;
}
.image-dropdown.dark:hover:not(.disabled) {
  background-color: #001F39;
  border-bottom: 1px solid #00FFB9;
}
.image-dropdown.dark:hover:not(.disabled) .image-dropdown-arrow svg {
  fill: #00FFB9;
}
.image-dropdown.dark.disabled {
  background-color: #00183B;
  border-color: transparent;
  color: #7D8099;
  cursor: not-allowed;
}
.image-dropdown.dark.disabled .image-dropdown-opener .image-dropdown-arrow svg {
  fill: #66667E;
}
.image-dropdown.dark.disabled .image-dropdown-opener .image-dropdown-value {
  background-color: #00183B;
}
.image-dropdown.dark.open .image-dropdown-arrow svg {
  fill: #00FFB9;
}
.image-dropdown.dark .image-dropdown-values::-webkit-scrollbar-thumb {
  background-color: #37374D;
}
.image-dropdown.dark .image-dropdown-values::-webkit-scrollbar-track {
  background-color: #00183B;
}
.image-dropdown.dark .image-dropdown-values::-webkit-scrollbar-thumb:hover {
  background-color: #66667E;
}
.image-dropdown.dark .image-dropdown-values .image-dropdown-value {
  background-color: #00183B;
}
.image-dropdown.dark .image-dropdown-values .image-dropdown-value.selected:not(.disabled) {
  background-color: #005159;
}
.image-dropdown.dark .image-dropdown-values .image-dropdown-value.disabled {
  background-color: #001F39;
  border-color: transparent;
  color: #66667E;
  cursor: not-allowed;
}
.image-dropdown.dark .image-dropdown-values .image-dropdown-value.disabled .image-dropdown-value-text-container {
  color: #66667E;
}

.input-highlight {
  position: relative;
}
.input-highlight .input-highlight-bar {
  background-color: #007993;
  bottom: 0;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  transition: opacity 0.2s ease-out;
  width: 8px;
}
.input-highlight .input-highlight-container {
  padding-left: 0px;
  transition: padding 0.2s ease-out 0.1s;
}
.input-highlight.highlighted .input-highlight-bar {
  opacity: 1;
  transition: opacity 0.2s ease-out 0.1s;
}
.input-highlight.highlighted .input-highlight-container {
  padding-left: 16px;
  transition: padding 0.2s ease-out;
}
.input-highlight.dark .input-highlight-bar {
  background-color: #00CCCC;
}

.input-validation .input-validation-input-container .input-validation-message {
  display: none;
}
.input-validation:not(.valid) .input-validation-input-container {
  position: relative;
  z-index: 21;
}
.input-validation:not(.valid) .input-validation-input-container .input-validation-message {
  background-color: white;
  border-radius: 4px;
  box-shadow: 0px 0px 16px 0px #DFDFD9;
  display: block;
  padding: 12px;
  position: absolute;
  right: 50%;
  text-align: center;
  top: calc(100% + 16px);
  transform: translate(50%, 0px);
  width: 160px;
}
.input-validation:not(.valid) .input-validation-background {
  background-color: rgba(243, 243, 240, 0.7);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 20;
}
.input-validation.dark:not(.valid) .input-validation-input-container .input-validation-message {
  background-color: #37374D;
  box-shadow: 0px 0px 16px 0px #000028;
}
.input-validation.dark:not(.valid) .input-validation-background {
  background-color: rgba(0, 0, 40, 0.7);
}

.jumbotron {
  max-width: 600px;
  margin: 0 auto 0 0;
  padding: 20px;
  background: rgba(255, 255, 255, 0.95);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.06), 0 6px 8px 0 rgba(0, 0, 0, 0.08);
}

.key-visual {
  background-size: cover;
  background-position: center;
}

.line {
  border-bottom: 1px solid #CCCCD4;
}
.line.dark {
  border-color: #40415C;
}

.link {
  display: inline-flex;
  gap: 8px;
  align-items: center;
  font-weight: 700;
  color: #007993;
  text-decoration: underline;
  text-decoration-color: transparent;
  text-underline-position: under;
  text-underline-offset: 1px;
  outline: none;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  transition: color 0.3s ease-in-out, text-decoration-color 0.3s ease-in-out;
}
.link .link-icon-container {
  display: inline-flex;
  align-items: center;
}
.link .link-icon-container svg {
  width: 20px;
  flex-shrink: 0;
  fill: #007993;
  transition: fill 0.3s ease-in-out;
}
.link.no-wrap {
  display: flex;
  min-width: 0;
}
.link.no-wrap .link-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.link.link-icon-position-left {
  flex-direction: row-reverse;
}
.link.link-icon-position-right {
  flex-direction: row;
}
.link:hover, .link:active {
  color: #00646E;
  text-decoration-color: #00646E;
}
.link:hover .link-icon-container svg, .link:active .link-icon-container svg {
  fill: #00646E;
}
.link:focus-visible {
  outline: 1px solid #1491EB;
  outline-offset: 2px;
}
.link.dark {
  color: #00CCCC;
}
.link.dark .link-icon-container svg {
  fill: #00CCCC;
}
.link.dark:hover, .link.dark:active {
  color: #00FFB9;
  text-decoration-color: #00FFB9;
}
.link.dark:hover .link-icon-container svg, .link.dark:active .link-icon-container svg {
  fill: #00FFB9;
}

.loader-container {
  position: relative;
  background-color: inherit;
}
.loader-container .loading-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(162, 178, 186, 0.3764705882);
  visibility: hidden;
}
.loader-container .loading-overlay.loading-animation.loading {
  animation: appear 0.3s ease-in-out forwards;
}
@keyframes appear {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}
.loader-container .loading-overlay.loading-animation:not(.loading) {
  animation: disappear 0.3s ease-in-out forwards;
}
@keyframes disappear {
  0% {
    opacity: 1;
    visibility: visible;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}
.loader-container .loading-overlay.transparent {
  background-color: transparent;
}
.loader-container .loading-overlay .loader-icon {
  width: 50px;
  height: 50px;
  animation: rotate 1s linear infinite;
}
@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
.loader-container .loading-overlay .loader-icon circle {
  fill: none;
  stroke: #000028;
  stroke-width: 4px;
  stroke-dasharray: 50, 42;
  stroke-dashoffset: 8;
}
.loader-container.dark .loading-overlay {
  background-color: rgba(31, 38, 41, 0.5019607843);
}
.loader-container.dark .loading-overlay .loader-icon circle {
  stroke: white;
}

.menu {
  border-radius: 4px;
  color: #007993;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  line-height: 24px;
  margin: -4px 0 -4px -6px;
  outline: none;
  padding: 4px 0 4px 6px;
  position: relative;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.menu > svg {
  fill: #007993;
  transition: fill 0.2s ease-in-out;
  width: 24px;
}
.menu .menu-dropdown {
  background-color: #F3F3F0;
  border-radius: 4px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 40, 0.25);
  cursor: default;
  min-width: 200px;
  padding: 10px 8px;
  position: absolute;
  top: calc(100% + 8px);
  white-space: nowrap;
  z-index: 2;
}
.menu.opening .menu-dropdown {
  animation: menu-dropdown-open 0.4s cubic-bezier(0.75, -0.02, 0.2, 0.97) forwards;
}
@keyframes menu-dropdown-open {
  0% {
    opacity: 0;
    transform: translate(0, 20px);
  }
  100% {
    opacity: 1;
    transform: translate(0);
  }
}
.menu.closing .menu-dropdown {
  animation: menu-dropdown-close 0.4s cubic-bezier(0.75, -0.02, 0.2, 0.97) forwards;
}
@keyframes menu-dropdown-close {
  0% {
    opacity: 1;
    transform: translate(0);
  }
  100% {
    opacity: 0;
    transform: translate(0, 20px);
  }
}
.menu.closed .menu-dropdown {
  visibility: hidden;
}
.menu:hover, .menu.opening, .menu.open {
  background-color: #C2FFEE;
  color: #005159;
}
.menu:hover > svg, .menu.opening > svg, .menu.open > svg {
  fill: #005159;
}
.menu:focus-visible {
  outline: 1px solid #1491EB;
  outline-offset: 1px;
}
.menu.dark {
  color: #00CCCC;
}
.menu.dark > svg {
  fill: #00CCCC;
}
.menu.dark .menu-dropdown {
  background-color: #66667E;
}
.menu.dark:hover, .menu.dark.opening, .menu.dark.open {
  background-color: #001F39;
  color: #00FFB9;
}
.menu.dark:hover > svg, .menu.dark.opening > svg, .menu.dark.open > svg {
  fill: #00FFB9;
}

.menu-divider-item {
  padding: 10px 0;
}
.menu-divider-item::before {
  border-bottom: 1px solid #D9D9D6;
  border-radius: 1px;
  content: "";
  display: block;
  width: 100%;
}
.menu-divider-item.dark::before {
  border-color: #7D8099;
}

.menu-dropdown-item {
  position: relative;
  outline: none;
}
.menu-dropdown-item .menu-dropdown-item-button {
  align-items: center;
  color: #000028;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  justify-content: space-between;
  line-height: 16px;
  padding: 2px 0 2px 8px;
  user-select: none;
  z-index: 1;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.menu-dropdown-item .menu-dropdown-item-button::before {
  border-radius: 8px;
  bottom: -1px;
  content: "";
  left: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: -1px;
  z-index: -1;
}
.menu-dropdown-item .menu-dropdown-item-button svg {
  fill: #000028;
  width: 24px;
}
.menu-dropdown-item .menu-dropdown-item-button:hover {
  color: white;
}
.menu-dropdown-item .menu-dropdown-item-button:hover::before {
  background-color: #00646E;
}
.menu-dropdown-item .menu-dropdown-item-button:hover svg {
  fill: white;
}
.menu-dropdown-item .menu-dropdown-item-dropdown {
  background-color: #F3F3F0;
  border-radius: 4px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 40, 0.25);
  min-width: 200px;
  padding: 10px 8px;
  position: absolute;
  top: -10px;
  visibility: hidden;
  white-space: nowrap;
  z-index: 2;
}
.menu-dropdown-item:hover > .menu-dropdown-item-button:not(:hover)::before {
  background-color: #DFDFD9;
}
.menu-dropdown-item:focus-visible > .menu-dropdown-item-button::before {
  outline: 1px solid #1491EB;
  outline-offset: -1px;
}
.menu-dropdown-item.dark .menu-dropdown-item-button {
  color: white;
}
.menu-dropdown-item.dark .menu-dropdown-item-button svg {
  fill: white;
}
.menu-dropdown-item.dark .menu-dropdown-item-button:hover::before {
  background-color: #002949;
}
.menu-dropdown-item.dark .menu-dropdown-item-dropdown {
  background-color: #66667E;
}
.menu-dropdown-item.dark:hover > .menu-dropdown-item-button:not(:hover)::before {
  background-color: #7D8099;
}

.menu:not(.closed) .menu-dropdown-item.open > .menu-dropdown-item-dropdown {
  visibility: visible;
}

.menu-icon {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  outline: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.menu-icon .menu-bar {
  height: 2px;
  width: 100%;
  background-color: #000028;
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  will-change: opacity, transform;
}
.menu-icon .menu-bar:nth-child(2) {
  width: 66%;
}
.menu-icon.open .menu-bar:first-child {
  transform: translate(0px, 6px) rotate(45deg);
}
.menu-icon.open .menu-bar:nth-child(2) {
  opacity: 0;
}
.menu-icon.open .menu-bar:last-child {
  transform: translate(0px, -6px) rotate(-45deg);
}
.menu-icon:focus-visible {
  outline: 1px solid #1491EB;
  outline-offset: 1px;
}

.menu-item {
  color: #000028;
  cursor: pointer;
  font-size: 16px;
  line-height: 16px;
  outline: none;
  padding: 6px 8px;
  position: relative;
  user-select: none;
  z-index: 1;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.menu-item::before {
  border-radius: 8px;
  bottom: -1px;
  content: "";
  left: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: -1px;
  z-index: -1;
}
.menu-item.disabled {
  color: #7D8099;
  cursor: default;
}
.menu-item:not(.disabled):hover {
  color: white;
}
.menu-item:not(.disabled):hover::before {
  background-color: #00646E;
}
.menu-item:focus-visible::before {
  outline: 1px solid #1491EB;
  outline-offset: -1px;
}
.menu-item.dark {
  color: white;
}
.menu-item.dark.disabled {
  color: #B3B3BE;
}
.menu-item.dark:not(.disabled):hover::before {
  background-color: #002949;
}

.menu-label-item {
  color: #000028;
  font-size: 16px;
  font-weight: 700;
  line-height: 16px;
  padding: 6px 8px;
  user-select: none;
}
.menu-label-item.dark {
  color: white;
}

.modal-background {
  align-items: center;
  background-color: rgba(243, 243, 240, 0.7);
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  transition: padding 0.5s ease-in-out;
  z-index: 20;
}
.modal-background.clickable {
  cursor: pointer;
}
.modal-background .modal {
  --offset-x: 0px;
  --offset-y: 0px;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0px 0px 28px 8px #DFDFD9;
  cursor: default;
  margin: 0 10px;
  max-height: calc(100% - 100px);
  min-width: 0;
  outline: none;
  padding: 28px 24px 32px 24px;
  position: relative;
  transform: translate(var(--offset-x), var(--offset-y));
}
.modal-background .modal:focus-visible {
  outline: 1px solid #1491EB;
  outline-offset: 2px;
}
.modal-background .modal .move-handle {
  cursor: move;
  height: 20px;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.modal-background.opening {
  animation: modal-background-appear 0.2s ease-in-out forwards;
}
@keyframes modal-background-appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.modal-background.opening .modal {
  animation: modal-appear 0.2s ease-in-out forwards;
}
@keyframes modal-appear {
  0% {
    transform: scale(1.1) translate(var(--offset-x), var(--offset-y));
  }
  100% {
    transform: scale(1) translate(var(--offset-x), var(--offset-y));
  }
}
.modal-background.closing {
  animation: modal-background-disappear 0.2s ease-in-out forwards;
}
@keyframes modal-background-disappear {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.modal-background.closing .modal {
  animation: modal-disappear 0.2s ease-in-out forwards;
}
@keyframes modal-disappear {
  0% {
    transform: scale(1) translate(var(--offset-x), var(--offset-y));
  }
  100% {
    transform: scale(1.1) translate(var(--offset-x), var(--offset-y));
  }
}
.modal-background.closed {
  display: none;
}
.modal-background.dark {
  background-color: rgba(0, 0, 40, 0.7);
}
.modal-background.dark .modal {
  background-color: #23233C;
  box-shadow: 0px 0px 28px 8px #000028;
  color: white;
}
.modal-background .icon-button {
  position: fixed;
  top: 8px;
  right: 8px;
}

.multiselect {
  position: relative;
  min-width: 205px;
  color: #4C4C68;
  background-color: #EBF7F8;
  border-bottom: 1px solid #4C4C68;
  outline: none;
  user-select: none;
  cursor: pointer;
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out, border 0.3s ease-in-out;
}
.multiselect .select-opener {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 5px;
  padding: 0 5px 0 14px;
}
.multiselect .select-opener.with-reset {
  grid-template-columns: 1fr auto auto;
}
.multiselect .select-opener.filled .select-label {
  bottom: 24px;
  font-size: 10px;
}
.multiselect .select-opener.filled .select-value {
  opacity: 1;
}
.multiselect .select-opener .select-label {
  position: absolute;
  top: 2px;
  bottom: 2px;
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #4C4C68;
  user-select: none;
  pointer-events: none;
  transition: color 0.3s ease-in-out, font-size 0.15s ease-in-out, bottom 0.15s ease-in-out;
}
.multiselect .select-opener .select-label.required::before {
  position: absolute;
  content: "*";
  right: calc(100% + 2px);
}
.multiselect .select-opener .select-value {
  padding: 16px 0 6px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}
.multiselect .select-opener .select-reset {
  display: flex;
  align-items: center;
  margin: 16px 0 6px;
  border-radius: 100%;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}
.multiselect .select-opener .select-reset:hover {
  background-color: #62EEC7;
}
.multiselect .select-opener .select-reset:hover svg {
  color: #000028;
}
.multiselect .select-opener .select-reset svg {
  width: 15px;
  padding: 0 2px;
  fill: #4C4C68;
  transition: fill 0.3s ease-in-out;
}
.multiselect .select-opener .select-arrow {
  display: flex;
  align-items: center;
}
.multiselect .select-opener .select-arrow svg {
  width: 20px;
  fill: #4C4C68;
  transition: fill 0.3s ease-in-out, transform 0.5s ease-in-out;
}
.multiselect .select-values {
  position: absolute;
  top: calc(100% + 1px);
  z-index: 2;
  max-height: 0;
  width: 100%;
  overflow-y: auto;
  background-color: #EBF7F8;
  transition: max-height 0.5s ease-in-out, background-color 0.5s ease-in-out;
}
.multiselect .select-values::-webkit-scrollbar {
  width: 6px;
}
.multiselect .select-values::-webkit-scrollbar-track {
  background-color: inherit;
}
.multiselect .select-values::-webkit-scrollbar-thumb {
  background-color: #D9EFF1;
}
.multiselect .select-values .select-value {
  position: relative;
  z-index: 1;
  display: grid;
  grid-template-columns: 16px 1fr;
  gap: 5px;
  align-items: baseline;
  padding: 5px 14px;
  user-select: none;
  cursor: pointer;
}
.multiselect .select-values .select-value .checkmark {
  height: 13px;
  width: 8px;
  transform: translateX(5px) rotate(40deg);
}
.multiselect .select-values .select-value .checkmark::before, .multiselect .select-values .select-value .checkmark::after {
  position: absolute;
  content: "";
  background-color: #000028;
}
.multiselect .select-values .select-value .checkmark::before {
  width: 0;
  height: 2px;
  top: calc(100% - 2px);
  left: 0;
  transition: width 0.15s ease-in-out 0.15s, background-color 0.3s ease-in-out 0s;
}
.multiselect .select-values .select-value .checkmark::after {
  width: 2px;
  height: 0;
  bottom: 0;
  left: calc(100% - 2px);
  transition: height 0.15s ease-in-out 0s, background-color 0.3s ease-in-out 0s;
}
.multiselect .select-values .select-value .checkmark.checked::before {
  width: 100%;
  transition: width 0.15s ease-in-out 0s, background-color 0.3s ease-in-out 0s;
}
.multiselect .select-values .select-value .checkmark.checked::after {
  height: 100%;
  transition: height 0.15s ease-in-out 0.15s, background-color 0.3s ease-in-out 0s;
}
.multiselect .select-values .select-value .select-value-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.multiselect.open .select-values.select-values-1 {
  max-height: 29px;
}
.multiselect.open .select-values.select-values-2 {
  max-height: 58px;
}
.multiselect.open .select-values.select-values-3 {
  max-height: 87px;
}
.multiselect.open .select-values.select-values-4 {
  max-height: 116px;
}
.multiselect.open .select-values.select-values-5 {
  max-height: 145px;
}
.multiselect.open .select-opener .select-arrow svg {
  transform: rotate(-180deg);
}
.multiselect.open, .multiselect:hover {
  color: #000028;
  background-color: #D1FFF2;
  border-color: #00D7A0;
}
.multiselect.open .select-opener .select-label, .multiselect:hover .select-opener .select-label {
  color: #000028;
}
.multiselect.open .select-opener .select-reset svg, .multiselect:hover .select-opener .select-reset svg {
  fill: #000028;
}
.multiselect.open .select-opener .select-arrow svg, .multiselect:hover .select-opener .select-arrow svg {
  fill: #000028;
}
.multiselect.open .select-values, .multiselect:hover .select-values {
  background-color: #D1FFF2;
}
.multiselect.open .select-values::-webkit-scrollbar-thumb, .multiselect:hover .select-values::-webkit-scrollbar-thumb {
  background-color: #91F3D7;
}
.multiselect.open .select-values::-webkit-scrollbar-thumb:hover, .multiselect:hover .select-values::-webkit-scrollbar-thumb:hover {
  background-color: #B0F6E3;
}
.multiselect.open .select-values .select-value.selected, .multiselect:hover .select-values .select-value.selected {
  background-color: #62EEC7;
}
.multiselect.disabled {
  color: #7D8099;
  background-color: #EBF7F8;
  border-color: transparent;
  cursor: not-allowed;
}
.multiselect.disabled .select-opener .select-label {
  color: #7D8099;
}
.multiselect.disabled .select-opener .select-arrow svg {
  fill: #7D8099;
}
.multiselect.error:not(.disabled):not(:focus-within) {
  color: #000028;
  background-color: #FCCCD7;
  border-color: #FF2640;
}
.multiselect.error:not(.disabled):not(:focus-within) .select-opener .select-label {
  color: #FF2640;
}
.multiselect.error:not(.disabled):not(:focus-within) .select-opener .select-reset:hover {
  background-color: #FFA8B3;
}
.multiselect.error:not(.disabled):not(:focus-within) .select-opener .select-reset:hover svg {
  color: #000028;
}
.multiselect.error:not(.disabled):not(:focus-within) .select-opener .select-reset svg {
  fill: #000028;
}
.multiselect.error:not(.disabled):not(:focus-within) .select-opener .select-arrow svg {
  fill: #000028;
}
.multiselect.error:not(.disabled):not(:focus-within) .select-values {
  background-color: #FCCCD7;
}
.multiselect.error:not(.disabled):not(:focus-within) .select-values::-webkit-scrollbar-thumb {
  background-color: #FFA8B3;
}
.multiselect.error:not(.disabled):not(:focus-within) .select-values .select-value.selected {
  background-color: inherit !important;
}
.multiselect.dark {
  color: #EBEBEE;
  background-color: #00183B;
  border-color: #7D8099;
}
.multiselect.dark .select-opener .select-label {
  color: #7D8099;
}
.multiselect.dark .select-opener .select-reset:hover {
  background-color: #66667E;
}
.multiselect.dark .select-opener .select-reset:hover svg {
  color: #EBEBEE;
}
.multiselect.dark .select-opener .select-reset svg {
  fill: #EBEBEE;
}
.multiselect.dark .select-opener .select-arrow svg {
  fill: #7D8099;
}
.multiselect.dark .select-values {
  background-color: #00183B;
}
.multiselect.dark .select-values::-webkit-scrollbar-thumb {
  background-color: #37374D;
}
.multiselect.dark .select-values::-webkit-scrollbar-thumb:hover {
  background-color: #66667E;
}
.multiselect.dark .select-values .checkmark::before, .multiselect.dark .select-values .checkmark::after {
  background-color: #EBEBEE;
}
.multiselect.dark.open, .multiselect.dark:hover {
  color: #EBEBEE;
  background-color: #001F39;
  border-color: #00FFB9;
}
.multiselect.dark.open .select-opener .select-label, .multiselect.dark:hover .select-opener .select-label {
  color: #EBEBEE;
}
.multiselect.dark.open .select-opener .select-reset svg, .multiselect.dark:hover .select-opener .select-reset svg {
  fill: #EBEBEE;
}
.multiselect.dark.open .select-opener .select-arrow svg, .multiselect.dark:hover .select-opener .select-arrow svg {
  fill: #00FFB9;
}
.multiselect.dark.open .select-opener.filled .select-label, .multiselect.dark:hover .select-opener.filled .select-label {
  color: #00FFB9;
}
.multiselect.dark.open .select-values, .multiselect.dark:hover .select-values {
  background-color: #001F39;
}
.multiselect.dark.open .select-values .select-value.selected, .multiselect.dark:hover .select-values .select-value.selected {
  background-color: #005159;
}
.multiselect.dark.disabled, .multiselect.dark.disabled:hover {
  color: #66667E;
  background-color: #00183B;
  border-color: transparent;
}
.multiselect.dark.disabled .select-opener .select-label, .multiselect.dark.disabled:hover .select-opener .select-label {
  color: #66667E;
}
.multiselect.dark.disabled .select-opener .select-arrow svg, .multiselect.dark.disabled:hover .select-opener .select-arrow svg {
  fill: #66667E;
}
.multiselect.dark.error:not(.disabled):not(:focus-within) {
  color: #EBEBEE;
  background-color: #331131;
  border-color: #FF5454;
}
.multiselect.dark.error:not(.disabled):not(:focus-within) .select-opener .select-label {
  color: #FF5454;
}
.multiselect.dark.error:not(.disabled):not(:focus-within) .select-opener .select-reset:hover {
  background-color: #4D1A4A;
}
.multiselect.dark.error:not(.disabled):not(:focus-within) .select-opener .select-reset:hover svg {
  color: #EBEBEE;
}
.multiselect.dark.error:not(.disabled):not(:focus-within) .select-opener .select-reset svg {
  fill: #EBEBEE;
}
.multiselect.dark.error:not(.disabled):not(:focus-within) .select-opener .select-arrow svg {
  fill: #FF5454;
}
.multiselect.dark.error:not(.disabled):not(:focus-within) .select-values {
  background-color: #331131;
}
.multiselect.dark.error:not(.disabled):not(:focus-within) .select-values::-webkit-scrollbar-thumb {
  background-color: #4D1A4A;
}

.navigation-button {
  background-color: #F3F3F0;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  gap: 16px;
  height: 64px;
  justify-content: space-between;
  outline: none;
  overflow: hidden;
  transition: background-color 0.2s ease-in-out;
}
.navigation-button .button-content {
  align-items: center;
  display: flex;
  font-size: 16px;
  gap: 8px;
  line-height: 24px;
  user-select: none;
}
.navigation-button .button-content .button-selection {
  align-self: stretch;
  flex-shrink: 0;
  transition: background-color 0.2s ease-in-out;
  width: 8px;
}
.navigation-button .button-content .button-selection.selected {
  background-color: #0098A6;
}
.navigation-button .button-content svg {
  width: 32px;
}
.navigation-button .button-tail {
  display: flex;
  gap: 10px;
}
.navigation-button .button-tail .button-dot {
  align-items: center;
  display: flex;
}
.navigation-button .button-tail .button-arrow {
  align-items: center;
  border-left: 1px white solid;
  display: flex;
}
.navigation-button .button-tail .button-arrow svg {
  fill: #007993;
  transition: fill 0.2s ease-in-out;
  width: 24px;
}
.navigation-button:hover {
  background-color: #D1FFF2;
}
.navigation-button:hover .button-tail .button-arrow svg {
  fill: #005159;
}
.navigation-button:focus-visible {
  outline: 1px #1491EB solid;
  outline-offset: 1px;
}
.navigation-button.dark {
  background-color: #23233C;
}
.navigation-button.dark .button-content .button-selection.selected {
  background-color: #00E6DC;
}
.navigation-button.dark .button-content svg {
  fill: white;
}
.navigation-button.dark .button-tail .button-arrow {
  border-color: #000028;
}
.navigation-button.dark .button-tail .button-arrow svg {
  fill: #00CCCC;
}
.navigation-button.dark:hover {
  background-color: #001F39;
}
.navigation-button.dark:hover .button-tail .button-arrow svg {
  fill: #00FFB9;
}

.navigation-button-compact {
  background-color: #F3F3F0;
  cursor: pointer;
  display: grid;
  gap: 4px;
  grid-template-columns: 4px auto;
  outline: none;
  transition: background-color 0.2s ease-in-out;
}
.navigation-button-compact .navigation-button-selection {
  transition: background-color 0.2s ease-in-out;
}
.navigation-button-compact .navigation-button-selection.selected {
  background-color: #0098A6;
}
.navigation-button-compact .navigation-button-container {
  display: flex;
  gap: 8px;
  padding: 8px 8px 8px 0;
}
.navigation-button-compact .navigation-button-container .navigation-button-icons {
  align-self: start;
  display: flex;
}
.navigation-button-compact .navigation-button-container .navigation-button-icons svg {
  width: 24px;
}
.navigation-button-compact .navigation-button-container .navigation-button-icons .navigation-button-arrow-icon {
  display: flex;
  transition: transform 0.2s ease-in-out;
}
.navigation-button-compact .navigation-button-container .navigation-button-icons .navigation-button-arrow-icon.rotated {
  transform: rotate(-180deg);
}
.navigation-button-compact .navigation-button-container .navigation-button-content {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  gap: 4px;
  line-height: 24px;
  user-select: none;
}
.navigation-button-compact .navigation-button-container .navigation-button-content .navigation-button-label {
  font-weight: 700;
}
.navigation-button-compact:hover {
  background-color: #D1FFF2;
}
.navigation-button-compact:focus-visible {
  outline: 1px #1491EB solid;
  outline-offset: 1px;
}
.navigation-button-compact.dark {
  background-color: #23233C;
}
.navigation-button-compact.dark .navigation-button-selection.selected {
  background-color: #00E6DC;
}
.navigation-button-compact.dark .navigation-button-container .navigation-button-icons > svg {
  fill: white;
}
.navigation-button-compact.dark .navigation-button-container .navigation-button-icons .navigation-button-arrow-icon svg {
  fill: white;
}
.navigation-button-compact.dark:hover {
  background-color: #001F39;
}

.pagination {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}
.pagination .pagination-button {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 20px;
  padding: 5px;
  color: #007993;
  outline: none;
  cursor: pointer;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.pagination .pagination-button svg {
  width: 20px;
  fill: #007993;
}
.pagination .pagination-button.active {
  color: white;
  background-color: #007993;
  cursor: default;
}
.pagination .pagination-button.active svg {
  fill: white;
}
.pagination .pagination-button.active.disabled {
  background-color: #4C4C68;
  cursor: not-allowed;
}
.pagination .pagination-button:not(.active).disabled {
  color: #7D8099;
  cursor: not-allowed;
}
.pagination .pagination-button:not(.active).disabled svg {
  fill: #7D8099;
}
.pagination .pagination-button.divider, .pagination .pagination-button.divider.disabled {
  cursor: default;
}
.pagination .pagination-button:not(.active):not(.disabled):not(.divider):hover {
  color: #005159;
  background-color: #C5FFEF;
}
.pagination .pagination-button:not(.active):not(.disabled):not(.divider):hover svg {
  fill: #005159;
}
.pagination .pagination-button:not(.disabled):focus-visible {
  outline: 1px solid #1491EB;
}
.pagination.dark .pagination-button {
  color: #00CCCC;
}
.pagination.dark .pagination-button svg {
  fill: #00CCCC;
}
.pagination.dark .pagination-button.active {
  color: #000028;
  background-color: #00CCCC;
}
.pagination.dark .pagination-button.active svg {
  fill: #000028;
}
.pagination.dark .pagination-button.active.disabled {
  background-color: #B3B3BE;
}
.pagination.dark .pagination-button:not(.active).disabled {
  color: #B3B3BE;
}
.pagination.dark .pagination-button:not(.active).disabled svg {
  fill: #B3B3BE;
}
.pagination.dark .pagination-button:not(.active):not(.disabled):not(.divider):hover {
  color: #00FFB9;
  background-color: #001F39;
}
.pagination.dark .pagination-button:not(.active):not(.disabled):not(.divider):hover svg {
  fill: #00FFB9;
}

.radio-button-container {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-wrap: nowrap;
  gap: 5px;
  outline: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.radio-button-container .radio-button {
  align-items: center;
  background-color: #E0F1F4;
  border: 2px solid #4C4C68;
  border-radius: 100%;
  display: flex;
  flex-shrink: 0;
  height: 16px;
  justify-content: center;
  outline: none;
  transition: background 0.3s ease-in-out, border 0.3s ease-in-out;
  width: 16px;
}
.radio-button-container .radio-button::before {
  background-color: #4C4C68;
  border-radius: 100%;
  content: "";
  height: 8px;
  transform: scale(0);
  transition: background 0.3s ease-in-out, transform 0.3s ease-in-out;
  width: 8px;
}
.radio-button-container .radio-button.selected::before {
  transform: scale(1);
}
.radio-button-container .radio-button-label {
  color: #4C4C68;
  font-size: 16px;
  transition: color 0.3s ease-in-out;
  user-select: none;
}
.radio-button-container:not(.disabled):hover .radio-button, .radio-button-container:not(.disabled):active .radio-button {
  background-color: #C2FFEE;
  border-color: #005159;
}
.radio-button-container:not(.disabled):hover .radio-button::before, .radio-button-container:not(.disabled):active .radio-button::before {
  background-color: #005159;
}
.radio-button-container:not(.disabled):hover .radio-button-label, .radio-button-container:not(.disabled):active .radio-button-label {
  color: #000028;
}
.radio-button-container:not(:disabled):focus-visible .radio-button {
  outline: 1px solid #1491EB;
  outline-offset: 2px;
}
.radio-button-container:not(.disabled) .radio-button.selected + .radio-button-label {
  color: #000028;
}
.radio-button-container:not(.disabled) .radio-button.select-highlight.selected {
  border-color: #005159;
}
.radio-button-container:not(.disabled) .radio-button.select-highlight.selected::before {
  background-color: #005159;
}
.radio-button-container.disabled {
  cursor: not-allowed;
}
.radio-button-container.disabled .radio-button {
  background-color: transparent;
  border-color: #7D8099;
}
.radio-button-container.disabled .radio-button::before {
  background-color: #7D8099;
}
.radio-button-container.disabled .radio-button-label {
  color: #7D8099;
}
.radio-button-container.dark .radio-button {
  background-color: #262648;
  border-color: #B3B3BE;
}
.radio-button-container.dark .radio-button::before {
  background-color: #B3B3BE;
}
.radio-button-container.dark .radio-button-label {
  color: #B3B3BE;
}
.radio-button-container.dark:not(.disabled):hover .radio-button, .radio-button-container.dark:not(.disabled):active .radio-button {
  background-color: #001F39;
  border-color: #00FFB9;
}
.radio-button-container.dark:not(.disabled):hover .radio-button::before, .radio-button-container.dark:not(.disabled):active .radio-button::before {
  background-color: #00FFB9;
}
.radio-button-container.dark:not(.disabled):hover .radio-button-label, .radio-button-container.dark:not(.disabled):active .radio-button-label {
  color: #EBEBEE;
}
.radio-button-container.dark:not(.disabled) .radio-button.selected + .radio-button-label {
  color: #EBEBEE;
}
.radio-button-container.dark:not(.disabled) .radio-button.select-highlight.selected {
  border-color: #00FFB9;
}
.radio-button-container.dark:not(.disabled) .radio-button.select-highlight.selected::before {
  background-color: #00FFB9;
}
.radio-button-container.dark.disabled .radio-button {
  background-color: transparent;
  border-color: #66667E;
}
.radio-button-container.dark.disabled .radio-button::before {
  background-color: #66667E;
}
.radio-button-container.dark.disabled .radio-button-label {
  color: #66667E;
}

.search {
  height: 100%;
  position: relative;
}
.search .search-input-field input {
  background-color: #EBF7F8;
  border: none;
  border-bottom: 1px solid #4C4C68;
  border-radius: 0;
  box-sizing: border-box;
  color: #4C4C68;
  font: inherit;
  margin: 0;
  outline: none;
  padding: 16px 14px 6px;
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out, border 0.3s ease-in-out;
  width: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.search .search-input-field input::placeholder {
  color: #4C4C68;
  transition: color 0.3s ease-in-out;
  user-select: none;
}
.search .search-input-field input:focus, .search .search-input-field input:hover:not(:disabled) {
  background-color: #D1FFF2;
  border-color: #00D7A0;
  color: #000028;
}
.search .search-input-field input:focus::placeholder, .search .search-input-field input:hover:not(:disabled)::placeholder {
  color: #000028;
}
.search .search-input-field input:focus ~ .search-icon::before, .search .search-input-field input:hover:not(:disabled) ~ .search-icon::before {
  opacity: 1;
}
.search .search-input-field input:disabled {
  background-color: #EBF7F8;
  border-color: transparent;
  color: #7D8099;
  cursor: not-allowed;
  -webkit-text-fill-color: #7D8099;
}
.search .search-input-field input:focus + .search-label {
  bottom: 24px;
  color: #000028;
  font-size: 10px;
}
.search .search-input-field input:hover:not(:disabled) + .search-label {
  color: #000028;
}
.search .search-input-field input:disabled + .search-label {
  color: #7D8099;
}
.search .search-input-field .search-label {
  align-items: center;
  bottom: 2px;
  color: #4C4C68;
  display: flex;
  font-size: 16px;
  margin-left: 14px;
  pointer-events: none;
  position: absolute;
  top: 2px;
  transition: color 0.3s ease-in-out, font-size 0.15s ease-in-out, bottom 0.15s ease-in-out;
  user-select: none;
}
.search .search-input-field .search-label.filled {
  bottom: 24px;
  font-size: 10px;
}
.search .search-input-field .search-label.required::before {
  content: "*";
  position: absolute;
  right: calc(100% + 2px);
}
.search .search-input-field .search-icon {
  align-items: center;
  bottom: 1px;
  cursor: pointer;
  display: flex;
  padding: 0 11px;
  pointer-events: none;
  position: absolute;
  top: 0;
  z-index: 1;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.search .search-input-field .search-icon svg {
  fill: #000028;
  width: 20px;
}
.search .search-input-field .search-icon::before {
  bottom: 0;
  content: "";
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: opacity 0.3s ease-in-out;
  z-index: -1;
}
.search .search-input-field.search-input-field-icon-left input {
  padding-left: 37px;
}
.search .search-input-field.search-input-field-icon-left .search-label {
  margin-left: 37px;
}
.search .search-input-field.search-input-field-icon-left .search-icon {
  left: 0;
}
.search .search-input-field.search-input-field-icon-right input {
  padding-right: 37px;
}
.search .search-input-field.search-input-field-icon-right .search-icon {
  right: 0;
}
.search .search-input-field.search-input-field-icon-highlighted .search-icon {
  background: linear-gradient(#00FFB9, #00E6DC);
}
.search .search-input-field.search-input-field-icon-highlighted .search-icon::before {
  background-color: #62EEC7;
}
.search .search-input-field.search-input-field-icon-highlighted.search-input-field-icon-left input {
  padding-left: 56px;
}
.search .search-input-field.search-input-field-icon-highlighted.search-input-field-icon-left .search-label {
  margin-left: 56px;
}
.search .search-input-field.search-input-field-icon-highlighted.search-input-field-icon-right input {
  padding-right: 56px;
}
.search .search-results {
  background-color: #EBF7F8;
  max-height: 0;
  overflow-y: auto;
  position: absolute;
  top: 100%;
  transition: max-height 0.5s ease-in-out, background-color 0.5s ease-in-out;
  width: 100%;
  z-index: 2;
}
.search .search-results::-webkit-scrollbar {
  width: 6px;
}
.search .search-results::-webkit-scrollbar-track {
  background-color: inherit;
}
.search .search-results::-webkit-scrollbar-thumb {
  background-color: #D9EFF1;
}
.search .search-results .search-result {
  cursor: pointer;
  overflow: hidden;
  padding: 5px 14px;
  position: relative;
  text-overflow: ellipsis;
  user-select: none;
  white-space: nowrap;
  z-index: 1;
}
.search.open .search-results.search-results-1 {
  max-height: 29px;
}
.search.open .search-results.search-results-2 {
  max-height: 58px;
}
.search.open .search-results.search-results-3 {
  max-height: 87px;
}
.search.open .search-results.search-results-4 {
  max-height: 116px;
}
.search.open .search-results.search-results-5 {
  max-height: 145px;
}
.search.open .search-results, .search:hover .search-results {
  background-color: #D1FFF2;
}
.search.open .search-results::-webkit-scrollbar-thumb, .search:hover .search-results::-webkit-scrollbar-thumb {
  background-color: #91F3D7;
}
.search.open .search-results::-webkit-scrollbar-thumb:hover, .search:hover .search-results::-webkit-scrollbar-thumb:hover {
  background-color: #B0F6E3;
}
.search.open .search-results .search-result.selected, .search:hover .search-results .search-result.selected {
  background-color: #62EEC7;
}
.search.condensed .search-input-field input {
  padding: 6px 14px;
}
.search.condensed .search-input-field .search-icon {
  padding: 0 6px;
}
.search.condensed .search-input-field.search-input-field-icon-left input {
  padding-left: 33px;
}
.search.condensed .search-input-field.search-input-field-icon-left .search-label {
  margin-left: 33px;
}
.search.condensed .search-input-field.search-input-field-icon-right input {
  padding-right: 33px;
}
.search.condensed .search-input-field.search-input-field-icon-highlighted.search-input-field-icon-left input {
  padding-left: 39px;
}
.search.condensed .search-input-field.search-input-field-icon-highlighted.search-input-field-icon-left .search-label {
  margin-left: 39px;
}
.search.condensed .search-input-field.search-input-field-icon-highlighted.search-input-field-icon-right input {
  padding-right: 39px;
}
.search.error:not(.disabled):not(:focus-within) .search-input-field input {
  background-color: #FCCCD7;
  border-color: #FF2640;
  color: #000028;
}
.search.error:not(.disabled):not(:focus-within) .search-input-field input::placeholder {
  color: #000028;
}
.search.error:not(.disabled):not(:focus-within) .search-input-field .search-label {
  color: #FF2640 !important;
}
.search.error:not(.disabled):not(:focus-within) .search-results {
  background-color: #FCCCD7;
}
.search.error:not(.disabled):not(:focus-within) .search-results::-webkit-scrollbar-thumb {
  background-color: #FFA8B3;
}
.search.error:not(.disabled):not(:focus-within) .search-results .search-result.selected {
  background-color: inherit !important;
}
.search.dark .search-input-field input {
  background-color: #00183B;
  border-color: #7D8099;
  color: #EBEBEE;
}
.search.dark .search-input-field input::placeholder {
  color: #7D8099;
}
.search.dark .search-input-field input:focus, .search.dark .search-input-field input:hover:not(:disabled) {
  background-color: #001F39;
  border-color: #00FFB9;
  color: #EBEBEE;
}
.search.dark .search-input-field input:focus::placeholder, .search.dark .search-input-field input:hover:not(:disabled)::placeholder {
  color: #EBEBEE;
}
.search.dark .search-input-field input:disabled {
  background-color: #00183B;
  border-color: transparent;
  color: #66667E;
  -webkit-text-fill-color: #66667E;
}
.search.dark .search-input-field input:hover:not(:disabled) + .search-label {
  color: #EBEBEE;
}
.search.dark .search-input-field input:hover:not(:disabled) + .search-label.filled, .search.dark .search-input-field input:focus + .search-label, .search.dark .search-input-field input:focus:hover + .search-label {
  color: #00FFB9;
}
.search.dark .search-input-field input:disabled + .search-label {
  color: #66667E;
}
.search.dark .search-input-field .search-label {
  color: #7D8099;
}
.search.dark .search-input-field .search-icon svg {
  fill: white;
}
.search.dark .search-input-field.search-input-field-icon-highlighted .search-icon svg {
  fill: #000028;
}
.search.dark .search-input-field.search-input-field-icon-highlighted .search-icon::before {
  background-color: #C5FFEF;
}
.search.dark .search-results {
  background-color: #00183B;
}
.search.dark .search-results::-webkit-scrollbar-thumb {
  background-color: #37374D;
}
.search.dark .search-results::-webkit-scrollbar-thumb:hover {
  background-color: #66667E;
}
.search.dark.open .search-results, .search.dark:hover .search-results {
  background-color: #001F39;
}
.search.dark.open .search-results .search-result.selected, .search.dark:hover .search-results .search-result.selected {
  background-color: #005159;
}
.search.dark.error:not(.disabled):not(:focus-within) .search-input-field input {
  background-color: #331131;
  border-color: #FF5454;
  color: #EBEBEE;
}
.search.dark.error:not(.disabled):not(:focus-within) .search-input-field input::placeholder {
  color: #EBEBEE;
}
.search.dark.error:not(.disabled):not(:focus-within) .search-input-field .search-label {
  color: #FF5454 !important;
}
.search.dark.error:not(.disabled):not(:focus-within) .search-results {
  background-color: #331131;
}
.search.dark.error:not(.disabled):not(:focus-within) .search-results::-webkit-scrollbar-thumb {
  background-color: #4D1A4A;
}

.section {
  padding: 30px 0;
}
.section.deep-blue {
  color: #EBEBEE;
  background-color: #000028;
}
.section.light-sand {
  background-color: #F3F3F0;
}
.section.white {
  background-color: white;
}

.select {
  position: relative;
  min-width: 205px;
  color: #4C4C68;
  background-color: #EBF7F8;
  border-bottom: 1px solid #4C4C68;
  outline: none;
  user-select: none;
  cursor: pointer;
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out, border 0.3s ease-in-out;
}
.select .select-opener {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 5px;
  padding: 0 5px 0 14px;
}
.select .select-opener.with-reset {
  grid-template-columns: 1fr auto auto;
}
.select .select-opener.filled .select-label {
  bottom: 24px;
  font-size: 10px;
}
.select .select-opener.filled .select-value {
  opacity: 1;
}
.select .select-opener .select-label {
  position: absolute;
  top: 2px;
  bottom: 2px;
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #4C4C68;
  user-select: none;
  pointer-events: none;
  transition: color 0.3s ease-in-out, font-size 0.15s ease-in-out, bottom 0.15s ease-in-out;
}
.select .select-opener .select-label.required::before {
  position: absolute;
  content: "*";
  right: calc(100% + 2px);
}
.select .select-opener .select-value {
  padding: 16px 0 6px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}
.select .select-opener .select-reset {
  display: flex;
  align-items: center;
  margin: 16px 0 6px;
  border-radius: 100%;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}
.select .select-opener .select-reset:hover {
  background-color: #62EEC7;
}
.select .select-opener .select-reset:hover svg {
  color: #000028;
}
.select .select-opener .select-reset svg {
  width: 15px;
  padding: 0 2px;
  fill: #4C4C68;
  transition: fill 0.3s ease-in-out;
}
.select .select-opener .select-arrow {
  display: flex;
  align-items: center;
}
.select .select-opener .select-arrow svg {
  width: 20px;
  fill: #4C4C68;
  transition: fill 0.3s ease-in-out, transform 0.5s ease-in-out;
}
.select .select-values {
  position: absolute;
  top: calc(100% + 1px);
  z-index: 2;
  max-height: 0;
  width: 100%;
  overflow-y: auto;
  background-color: #EBF7F8;
  transition: max-height 0.5s ease-in-out, background-color 0.5s ease-in-out;
}
.select .select-values::-webkit-scrollbar {
  width: 6px;
}
.select .select-values::-webkit-scrollbar-track {
  background-color: inherit;
}
.select .select-values::-webkit-scrollbar-thumb {
  background-color: #D9EFF1;
}
.select .select-values .select-value {
  position: relative;
  z-index: 1;
  padding: 5px 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: none;
  cursor: pointer;
}
.select.open .select-values.select-values-1 {
  max-height: 29px;
}
.select.open .select-values.select-values-2 {
  max-height: 58px;
}
.select.open .select-values.select-values-3 {
  max-height: 87px;
}
.select.open .select-values.select-values-4 {
  max-height: 116px;
}
.select.open .select-values.select-values-5 {
  max-height: 145px;
}
.select.open .select-opener .select-arrow svg {
  transform: rotate(-180deg);
}
.select.open, .select:hover {
  color: #000028;
  background-color: #D1FFF2;
  border-color: #00D7A0;
}
.select.open .select-opener .select-label, .select:hover .select-opener .select-label {
  color: #000028;
}
.select.open .select-opener .select-reset svg, .select:hover .select-opener .select-reset svg {
  fill: #000028;
}
.select.open .select-opener .select-arrow svg, .select:hover .select-opener .select-arrow svg {
  fill: #000028;
}
.select.open .select-values, .select:hover .select-values {
  background-color: #D1FFF2;
}
.select.open .select-values::-webkit-scrollbar-thumb, .select:hover .select-values::-webkit-scrollbar-thumb {
  background-color: #91F3D7;
}
.select.open .select-values::-webkit-scrollbar-thumb:hover, .select:hover .select-values::-webkit-scrollbar-thumb:hover {
  background-color: #B0F6E3;
}
.select.open .select-values .select-value.selected, .select:hover .select-values .select-value.selected {
  background-color: #62EEC7;
}
.select.disabled {
  color: #7D8099;
  background-color: #EBF7F8;
  border-color: transparent;
  cursor: not-allowed;
}
.select.disabled .select-opener .select-label {
  color: #7D8099;
}
.select.disabled .select-opener .select-arrow svg {
  fill: #7D8099;
}
.select.error:not(.disabled):not(:focus-within) {
  color: #000028;
  background-color: #FCCCD7;
  border-color: #FF2640;
}
.select.error:not(.disabled):not(:focus-within) .select-opener .select-label {
  color: #FF2640;
}
.select.error:not(.disabled):not(:focus-within) .select-opener .select-reset:hover {
  background-color: #FFA8B3;
}
.select.error:not(.disabled):not(:focus-within) .select-opener .select-reset:hover svg {
  color: #000028;
}
.select.error:not(.disabled):not(:focus-within) .select-opener .select-reset svg {
  fill: #000028;
}
.select.error:not(.disabled):not(:focus-within) .select-opener .select-arrow svg {
  fill: #000028;
}
.select.error:not(.disabled):not(:focus-within) .select-values {
  background-color: #FCCCD7;
}
.select.error:not(.disabled):not(:focus-within) .select-values::-webkit-scrollbar-thumb {
  background-color: #FFA8B3;
}
.select.error:not(.disabled):not(:focus-within) .select-values .select-value.selected {
  background-color: inherit !important;
}
.select.highlighted:not(.disabled):not(:focus-within):not(:hover):not(.error) {
  border-color: #007993;
}
.select.highlighted:not(.disabled):not(:focus-within):not(:hover):not(.error) .select-opener .select-label {
  color: #007993;
}
.select.dark {
  color: #EBEBEE;
  background-color: #00183B;
  border-color: #7D8099;
}
.select.dark .select-opener .select-label {
  color: #7D8099;
}
.select.dark .select-opener .select-reset:hover {
  background-color: #66667E;
}
.select.dark .select-opener .select-reset:hover svg {
  color: #EBEBEE;
}
.select.dark .select-opener .select-reset svg {
  fill: #EBEBEE;
}
.select.dark .select-opener .select-arrow svg {
  fill: #7D8099;
}
.select.dark .select-values {
  background-color: #00183B;
}
.select.dark .select-values::-webkit-scrollbar-thumb {
  background-color: #37374D;
}
.select.dark .select-values::-webkit-scrollbar-thumb:hover {
  background-color: #66667E;
}
.select.dark.open, .select.dark:hover {
  color: #EBEBEE;
  background-color: #001F39;
  border-color: #00FFB9;
}
.select.dark.open .select-opener .select-label, .select.dark:hover .select-opener .select-label {
  color: #EBEBEE;
}
.select.dark.open .select-opener .select-reset svg, .select.dark:hover .select-opener .select-reset svg {
  fill: #EBEBEE;
}
.select.dark.open .select-opener .select-arrow svg, .select.dark:hover .select-opener .select-arrow svg {
  fill: #00FFB9;
}
.select.dark.open .select-opener.filled .select-label, .select.dark:hover .select-opener.filled .select-label {
  color: #00FFB9;
}
.select.dark.open .select-values, .select.dark:hover .select-values {
  background-color: #001F39;
}
.select.dark.open .select-values .select-value.selected, .select.dark:hover .select-values .select-value.selected {
  background-color: #005159;
}
.select.dark.disabled, .select.dark.disabled:hover {
  color: #66667E;
  background-color: #00183B;
  border-color: transparent;
}
.select.dark.disabled .select-opener .select-label, .select.dark.disabled:hover .select-opener .select-label {
  color: #66667E;
}
.select.dark.disabled .select-opener .select-arrow svg, .select.dark.disabled:hover .select-opener .select-arrow svg {
  fill: #66667E;
}
.select.dark.error:not(.disabled):not(:focus-within) {
  color: #EBEBEE;
  background-color: #331131;
  border-color: #FF5454;
}
.select.dark.error:not(.disabled):not(:focus-within) .select-opener .select-label {
  color: #FF5454;
}
.select.dark.error:not(.disabled):not(:focus-within) .select-opener .select-reset:hover {
  background-color: #4D1A4A;
}
.select.dark.error:not(.disabled):not(:focus-within) .select-opener .select-reset:hover svg {
  color: #EBEBEE;
}
.select.dark.error:not(.disabled):not(:focus-within) .select-opener .select-reset svg {
  fill: #EBEBEE;
}
.select.dark.error:not(.disabled):not(:focus-within) .select-opener .select-arrow svg {
  fill: #FF5454;
}
.select.dark.error:not(.disabled):not(:focus-within) .select-values {
  background-color: #331131;
}
.select.dark.error:not(.disabled):not(:focus-within) .select-values::-webkit-scrollbar-thumb {
  background-color: #4D1A4A;
}
.select.dark.highlighted:not(.disabled):not(:focus-within):not(:hover):not(.error) {
  border-color: #00CCCC;
}
.select.dark.highlighted:not(.disabled):not(:focus-within):not(:hover):not(.error) .select-opener .select-label {
  color: #00CCCC;
}

.simple-accordion {
  display: grid;
  transition: grid-template-rows 0.5s ease-out;
}
.simple-accordion .simple-accordion-container {
  overflow: hidden;
  grid-row: 1/span 2;
}
.simple-accordion.closed {
  grid-template-rows: 0fr;
}
.simple-accordion.open {
  grid-template-rows: 1fr;
}

.sina-save-image {
  display: flex;
  height: 68px;
}
.sina-save-image path, .sina-save-image polygon, .sina-save-image rect {
  fill: #000;
}
.sina-save-image.big {
  height: 88px;
  width: 100%;
}
.sina-save-image.dark path, .sina-save-image.dark polygon, .sina-save-image.dark rect {
  fill: #fff;
}
.sina-save-image.disabled {
  filter: invert(50%);
  cursor: not-allowed;
}

.rc-slider {
  margin: 0 7px;
}
.rc-slider .rc-slider-rail {
  background-color: #EBEBEE;
}
.rc-slider .rc-slider-track {
  background-color: #00FFB9;
}
.rc-slider .rc-slider-handle {
  border: none;
  background-color: #0098A6;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.4);
}
.rc-slider .rc-slider-handle:hover, .rc-slider .rc-slider-handle:active, .rc-slider .rc-slider-handle.rc-slider-handle-dragging {
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.4);
}
.rc-slider .rc-slider-handle:hover, .rc-slider .rc-slider-handle:active {
  background-color: #00646E;
}
.rc-slider .rc-slider-handle:focus-visible {
  background-color: #00646E;
}
.rc-slider.rc-slider-disabled {
  background: none;
}
.rc-slider.rc-slider-disabled .rc-slider-handle {
  background-color: #D9D9D6;
}
.rc-slider.dark .rc-slider-rail {
  background-color: #DFDFD9;
}
.rc-slider.dark .rc-slider-track {
  background-color: #00FFB9;
}
.rc-slider.dark .rc-slider-handle {
  background-color: white;
}
.rc-slider.dark .rc-slider-handle:hover, .rc-slider.dark .rc-slider-handle:active {
  background-color: #CCCCD4;
}
.rc-slider.dark .rc-slider-handle:hover, .rc-slider.dark .rc-slider-handle:active, .rc-slider.dark .rc-slider-handle:focus-visible {
  background-color: #CCCCD4;
}
.rc-slider.dark.rc-slider-disabled {
  background: none;
}
.rc-slider.dark.rc-slider-disabled .rc-slider-rail {
  background-color: #737389;
}
.rc-slider.dark.rc-slider-disabled .rc-slider-track {
  background-color: #006662;
}
.rc-slider.dark.rc-slider-disabled .rc-slider-handle {
  background-color: #D9D9D6;
}

.snackbar {
  pointer-events: all;
  position: relative;
}
.snackbar .snackbar-container {
  background-color: white;
  border-radius: 4px;
  bottom: 0;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 40, 0.25);
  color: #000028;
  display: grid;
  gap: 8px;
  grid-template-columns: 4px 1fr;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
}
.snackbar .snackbar-container .snackbar-highlight {
  align-self: stretch;
}
.snackbar .snackbar-container .snackbar-content {
  align-items: start;
  display: grid;
  gap: 8px;
  grid-template-columns: auto 1fr auto;
  padding: 8px 8px 8px 0;
}
.snackbar .snackbar-container .snackbar-content .status-icon svg {
  width: 24px;
}
.snackbar .snackbar-container .snackbar-content .snackbar-text-container {
  display: flex;
  flex-direction: column;
}
.snackbar .snackbar-container .snackbar-content .snackbar-text-container .snackbar-title {
  font-size: 16px;
  font-weight: 900;
  line-height: 24px;
}
.snackbar .snackbar-container .snackbar-content .snackbar-text-container .snackbar-description {
  font-size: 16px;
  line-height: 24px;
}
.snackbar.closed {
  left: 425px;
  opacity: 0;
}
.snackbar.opening {
  left: 0;
  opacity: 1;
  transition: height 0.3s ease-out, left 0.3s ease-in-out 0.3s, opacity 0.3s ease-in 0.3s;
}
.snackbar.closing {
  left: 425px;
  opacity: 0;
  transition: height 0.3s ease-out 0.3s, left 0.3s ease-in-out, opacity 0.3s ease-in;
}
.snackbar.error .snackbar-container .snackbar-highlight {
  background-color: #D72339;
}
.snackbar.info .snackbar-container .snackbar-highlight {
  background-color: #007EB1;
}
.snackbar.success .snackbar-container .snackbar-highlight {
  background-color: #01893A;
}
.snackbar.warning .snackbar-container .snackbar-highlight {
  background-color: #E96401;
}
.snackbar.dark .snackbar-container {
  background-color: #23233C;
  color: white;
}
.snackbar.dark.error .snackbar-container .snackbar-highlight {
  background-color: #FF2640;
}
.snackbar.dark.info .snackbar-container .snackbar-highlight {
  background-color: #00BEDC;
}
.snackbar.dark.success .snackbar-container .snackbar-highlight {
  background-color: #01D65A;
}
.snackbar.dark.warning .snackbar-container .snackbar-highlight {
  background-color: #FF9000;
}

.spacing-container > *:not(:first-child) {
  margin-top: var(--spacing);
}

.status-icon {
  display: flex;
}
.status-icon svg {
  width: var(--icon-width);
}
.status-icon.status-icon-error svg {
  fill: #D72339;
}
.status-icon.status-icon-info svg {
  fill: #007EB1;
}
.status-icon.status-icon-success svg {
  fill: #01893A;
}
.status-icon.status-icon-warning svg {
  fill: #E96401;
}
.status-icon.dark.status-icon-error svg {
  fill: #FF2640;
}
.status-icon.dark.status-icon-info svg {
  fill: #00BEDC;
}
.status-icon.dark.status-icon-success svg {
  fill: #01D65A;
}
.status-icon.dark.status-icon-warning svg {
  fill: #FF9000;
}

.sticky {
  position: sticky;
  z-index: 3;
}
.sticky.stuck {
  background-color: white;
}
.sticky.dark.stuck {
  background-color: #000028;
}

.tab-content-container {
  display: grid;
  grid-template: auto/100%;
  margin: 0 -20px;
  padding: 0 20px;
}
.tab-content-container .tab-content {
  grid-column: 1/span 1;
  grid-row: 1/span 1;
}
.tab-content-container .tab-content.open-left {
  animation: open-left 0.5s ease-in-out forwards;
}
@keyframes open-left {
  0% {
    opacity: 0;
    transform: translateX(-20px);
    visibility: hidden;
  }
  50% {
    opacity: 0;
    transform: translateX(-20px);
    visibility: visible;
  }
  100% {
    opacity: 1;
    transform: translateX(0);
    visibility: visible;
  }
}
.tab-content-container .tab-content.open-right {
  animation: open-right 0.5s ease-in-out forwards;
}
@keyframes open-right {
  0% {
    opacity: 0;
    transform: translateX(20px);
    visibility: hidden;
  }
  50% {
    opacity: 0;
    transform: translateX(20px);
    visibility: visible;
  }
  100% {
    opacity: 1;
    transform: translateX(0);
    visibility: visible;
  }
}
.tab-content-container .tab-content.close-left {
  animation: close-left 0.5s ease-in-out forwards;
}
@keyframes close-left {
  0% {
    opacity: 1;
    transform: translateX(0);
    visibility: visible;
  }
  50% {
    opacity: 0;
    transform: translateX(20px);
    visibility: visible;
  }
  100% {
    opacity: 0;
    transform: translateX(20px);
    visibility: hidden;
  }
}
.tab-content-container .tab-content.close-right {
  animation: close-right 0.5s ease-in-out forwards;
}
@keyframes close-right {
  0% {
    opacity: 1;
    transform: translateX(0);
    visibility: visible;
  }
  50% {
    opacity: 0;
    transform: translateX(-20px);
    visibility: visible;
  }
  100% {
    opacity: 0;
    transform: translateX(-20px);
    visibility: hidden;
  }
}

.table {
  background-color: inherit;
  position: relative;
}
.table::before, .table::after {
  bottom: 0;
  content: "";
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 10px;
}
.table::before {
  left: -11px;
}
.table::after {
  right: -10px;
}
.table .table-container {
  display: grid;
  height: 100%;
  margin: 0 -10px;
  overflow-x: auto;
  overflow-y: hidden;
  padding: 0 10px;
}
.table .table-container::-webkit-scrollbar {
  height: 24px;
}
.table .table-container::-webkit-scrollbar-track {
  background-clip: content-box;
  background-color: #D9D9D6;
  border: 11px transparent solid;
}
.table .table-container::-webkit-scrollbar-thumb {
  background-clip: content-box;
  background-color: #007993;
  border: 10px transparent solid;
}
.table .table-container::-webkit-scrollbar-thumb:hover {
  background-color: #00646E;
}
.table .table-container .table-grid {
  column-gap: 20px;
  display: grid;
  height: 100%;
}
.table .table-container .table-grid .table-row {
  display: contents;
}
.table .table-container .table-grid .table-row .table-cell {
  border: 1px solid;
  border-left: none;
  border-right: none;
  color: #000028;
  font-weight: 400;
  outline: none;
  padding: 16px;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.table .table-container .table-grid .table-row .table-cell:focus-visible {
  outline: 1px solid #1491EB;
  outline-offset: -1px;
}
.table .table-container .table-grid .table-row .table-cell.table-header-cell {
  align-items: center;
  border-color: #000028;
  border-top: none;
  display: grid;
  gap: 5px;
  grid-template: minmax(20px, auto)/1fr 20px;
  user-select: none;
}
.table .table-container .table-grid .table-row .table-cell.table-header-cell svg {
  fill: #000028;
  transition: fill 0.2s ease-in-out;
  width: 20px;
}
.table .table-container .table-grid .table-row .table-cell.table-header-cell.clickable {
  cursor: pointer;
}
.table .table-container .table-grid .table-row .table-cell.table-header-cell.clickable:hover {
  background-color: #D1FFF2;
  color: #00646E;
}
.table .table-container .table-grid .table-row .table-cell.table-header-cell.clickable:hover svg {
  fill: #00646E;
}
.table .table-container .table-grid .table-row .table-cell.table-header-cell.bold {
  font-weight: 700;
}
.table .table-container .table-grid .table-row .table-cell.table-header-cell.highlighted {
  border-width: 3px;
  font-weight: 700;
}
.table .table-container .table-grid .table-row .table-cell.table-header-cell.uninteractive {
  border-color: transparent;
  display: block;
  padding: 0;
}
.table .table-container .table-grid .table-row .table-cell.table-header-cell.selected {
  border-color: #005159;
}
.table .table-container .table-grid .table-row .table-cell.table-data-cell:not(.uninteractive) {
  border-color: #B3B3BE;
}
.table .table-container .table-grid .table-row .table-cell.table-data-cell.single-line {
  white-space: nowrap;
}
.table .table-container .table-grid .table-row .table-cell.table-data-cell.sized {
  overflow: hidden;
  text-overflow: ellipsis;
}
.table .table-container .table-grid .table-row .table-cell.table-data-cell.input-style {
  padding: 5px;
  overflow: visible;
}
.table .table-container .table-grid .table-row .table-cell.table-data-cell.input-style > * {
  min-width: auto;
}
.table .table-container .table-grid .table-row .table-cell.table-data-cell.highlighted {
  font-weight: 700;
}
.table .table-container .table-grid .table-row .table-cell.table-data-cell.uninteractive {
  border-color: transparent;
  padding: 0;
}
.table .table-container .table-grid .table-row .table-cell.table-data-cell.svg-coloring svg {
  fill: #000028;
  transition: fill 0.2s ease-in-out;
  width: 20px;
}
.table .table-container .table-grid .table-row .table-cell.table-data-cell .divider-content {
  height: 110px;
}
.table .table-container .table-grid .table-row.hoverable:hover .table-data-cell:not(.uninteractive), .table .table-container .table-grid .table-row.clickable:hover .table-data-cell:not(.uninteractive) {
  background-color: #D1FFF2;
  border-color: #00646E;
  color: #00646E;
}
.table .table-container .table-grid .table-row.hoverable:hover .table-data-cell:not(.uninteractive).svg-coloring svg, .table .table-container .table-grid .table-row.clickable:hover .table-data-cell:not(.uninteractive).svg-coloring svg {
  fill: #00646E;
}
.table .table-container .table-grid .table-row.hoverable:hover + .table-row .table-data-cell:not(.uninteractive), .table .table-container .table-grid .table-row.clickable:hover + .table-row .table-data-cell:not(.uninteractive) {
  border-top-color: #00646E;
}
.table .table-container .table-grid .table-row.hoverable:hover:last-child .table-data-cell.highlighted, .table .table-container .table-grid .table-row.clickable:hover:last-child .table-data-cell.highlighted {
  border-bottom-color: #000028;
}
.table .table-container .table-grid .table-row.selected .table-data-cell:not(.uninteractive) {
  border-color: #005159 !important;
  color: #00646E;
}
.table .table-container .table-grid .table-row.selected .table-data-cell:not(.uninteractive).svg-coloring svg {
  fill: #00646E;
}
.table .table-container .table-grid .table-row.selected + .table-row .table-data-cell:not(.uninteractive) {
  border-top-color: #005159 !important;
}
.table .table-container .table-grid .table-row.clickable .table-data-cell:not(.not-clickable) {
  cursor: pointer;
}
.table .table-container .table-grid .table-row:not(.no-header):nth-child(2) .table-data-cell {
  border-top: none;
}
.table .table-container .table-grid .table-row:not(:last-child) .table-data-cell {
  border-bottom: none;
}
.table .table-container .table-grid .table-row:last-child.no-border-last .table-data-cell {
  border-bottom: none;
}
.table .table-container .table-grid .table-row:last-child .table-data-cell.highlighted {
  border-bottom-color: #000028;
  border-bottom-width: 3px;
}
.table .table-container.no-overscroll {
  overscroll-behavior-x: none;
}
.table .table-container.no-scrollbar {
  scrollbar-width: none;
}
.table .table-container.no-scrollbar::-webkit-scrollbar {
  display: none;
}
.table.table-layer-1::before {
  background: linear-gradient(to right, white, rgba(255, 255, 255, 0));
}
.table.table-layer-1::after {
  background: linear-gradient(to left, white, rgba(255, 255, 255, 0));
}
.table.table-layer-2::before {
  background: linear-gradient(to right, #F3F3F0, rgba(243, 243, 240, 0));
}
.table.table-layer-2::after {
  background: linear-gradient(to left, #F3F3F0, rgba(243, 243, 240, 0));
}
.table.table-layer-3::before {
  background: linear-gradient(to right, #DFDFD9, rgba(223, 223, 217, 0));
}
.table.table-layer-3::after {
  background: linear-gradient(to left, #DFDFD9, rgba(223, 223, 217, 0));
}
.table.dark .table-container::-webkit-scrollbar-track {
  background-color: white;
}
.table.dark .table-container::-webkit-scrollbar-thumb {
  background-color: #00CCCC;
}
.table.dark .table-container::-webkit-scrollbar-thumb:hover {
  background-color: #00FFB9;
}
.table.dark .table-container .table-grid .table-row .table-cell {
  color: white;
}
.table.dark .table-container .table-grid .table-row .table-cell.table-header-cell:not(.uninteractive) {
  border-color: white;
}
.table.dark .table-container .table-grid .table-row .table-cell.table-header-cell:not(.uninteractive) svg {
  fill: white;
}
.table.dark .table-container .table-grid .table-row .table-cell.table-header-cell:not(.uninteractive).clickable:hover {
  background-color: #001F39;
  color: #00FFB9;
}
.table.dark .table-container .table-grid .table-row .table-cell.table-header-cell:not(.uninteractive).clickable:hover svg {
  fill: #00FFB9;
}
.table.dark .table-container .table-grid .table-row .table-cell.table-header-cell:not(.uninteractive).selected {
  border-color: #00FFB9;
}
.table.dark .table-container .table-grid .table-row .table-cell.table-data-cell:not(.uninteractive) {
  border-color: #4C4C68;
}
.table.dark .table-container .table-grid .table-row .table-cell.table-data-cell.svg-coloring svg {
  fill: white;
}
.table.dark .table-container .table-grid .table-row.hoverable:hover .table-data-cell:not(.uninteractive), .table.dark .table-container .table-grid .table-row.clickable:hover .table-data-cell:not(.uninteractive) {
  background-color: #001F39;
  border-color: #00646E;
  color: #00FFB9;
}
.table.dark .table-container .table-grid .table-row.hoverable:hover .table-data-cell:not(.uninteractive).svg-coloring svg, .table.dark .table-container .table-grid .table-row.clickable:hover .table-data-cell:not(.uninteractive).svg-coloring svg {
  fill: #00FFB9;
}
.table.dark .table-container .table-grid .table-row.hoverable:hover + .table-row .table-data-cell:not(.uninteractive), .table.dark .table-container .table-grid .table-row.clickable:hover + .table-row .table-data-cell:not(.uninteractive) {
  border-top-color: #00646E;
}
.table.dark .table-container .table-grid .table-row.hoverable:hover:last-child .table-data-cell.highlighted, .table.dark .table-container .table-grid .table-row.clickable:hover:last-child .table-data-cell.highlighted {
  border-bottom-color: white;
}
.table.dark .table-container .table-grid .table-row.selected .table-data-cell:not(.uninteractive) {
  border-color: #00FFB9 !important;
  color: #00FFB9;
}
.table.dark .table-container .table-grid .table-row.selected .table-data-cell:not(.uninteractive).svg-coloring svg {
  fill: #00FFB9;
}
.table.dark .table-container .table-grid .table-row.selected + .table-row .table-data-cell:not(.uninteractive) {
  border-top-color: #00FFB9 !important;
}
.table.dark .table-container .table-grid .table-row:last-child .table-data-cell.highlighted {
  border-bottom-color: white;
}
.table.dark.table-layer-1::before {
  background: linear-gradient(to right, #000028, rgba(0, 0, 40, 0));
}
.table.dark.table-layer-1::after {
  background: linear-gradient(to left, #000028, rgba(0, 0, 40, 0));
}
.table.dark.table-layer-2::before {
  background: linear-gradient(to right, #23233C, rgba(35, 35, 60, 0));
}
.table.dark.table-layer-2::after {
  background: linear-gradient(to left, #23233C, rgba(35, 35, 60, 0));
}
.table.dark.table-layer-3::before {
  background: linear-gradient(to right, #37374D, rgba(55, 55, 77, 0));
}
.table.dark.table-layer-3::after {
  background: linear-gradient(to left, #37374D, rgba(55, 55, 77, 0));
}

.tab-switcher-container {
  min-width: 0;
  outline: none;
  position: relative;
}
.tab-switcher-container::before, .tab-switcher-container::after {
  bottom: 0;
  content: "";
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 10px;
  z-index: 1;
}
.tab-switcher-container::before {
  left: -11px;
}
.tab-switcher-container::after {
  right: -10px;
}
.tab-switcher-container:focus-visible .tab-switcher .tab.active {
  background-color: #C5FFEF;
}
.tab-switcher-container .tab-switcher {
  display: flex;
  margin: 0 -10px;
  overflow-x: auto;
  padding: 0 10px;
  position: relative;
}
.tab-switcher-container .tab-switcher::-webkit-scrollbar {
  height: 24px;
}
.tab-switcher-container .tab-switcher::-webkit-scrollbar-track {
  background-clip: content-box;
  background-color: #D9D9D6;
  border: 11px transparent solid;
}
.tab-switcher-container .tab-switcher::-webkit-scrollbar-thumb {
  background-clip: content-box;
  background-color: #007993;
  border: 10px transparent solid;
}
.tab-switcher-container .tab-switcher::-webkit-scrollbar-thumb:hover {
  background-color: #00646E;
}
.tab-switcher-container .tab-switcher .tab {
  align-items: center;
  border-bottom: 2px solid #CCCCD4;
  color: #000028;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  font-weight: 900;
  justify-content: center;
  padding: 10px 32px;
  user-select: none;
  white-space: nowrap;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.tab-switcher-container .tab-switcher .tab:hover, .tab-switcher-container .tab-switcher .tab:active {
  background-color: #C5FFEF;
}
.tab-switcher-container .tab-switcher .tab.required::before {
  content: "*";
  margin-right: 2px;
}
.tab-switcher-container .tab-switcher .tab.with-children .icon-button:not(.disabled):hover.icon-button-16 svg, .tab-switcher-container .tab-switcher .tab.with-children .icon-button:not(.disabled):hover:hover, .tab-switcher-container .tab-switcher .tab.with-children .icon-button:not(.disabled):active.icon-button-16 svg, .tab-switcher-container .tab-switcher .tab.with-children .icon-button:not(.disabled):active:hover, .tab-switcher-container .tab-switcher .tab.with-children .icon-button.open.icon-button-16 svg, .tab-switcher-container .tab-switcher .tab.with-children .icon-button.open:hover {
  transform: scale(110%);
  transition: transform 0.2s ease-in-out;
}
.tab-switcher-container .tab-switcher .tab.with-children .icon-button:not(.disabled):hover.icon-button-layer-1::before, .tab-switcher-container .tab-switcher .tab.with-children .icon-button:not(.disabled):active.icon-button-layer-1::before, .tab-switcher-container .tab-switcher .tab.with-children .icon-button.open.icon-button-layer-1::before {
  background: initial;
}
.tab-switcher-container .tab-switcher .tab.with-children .icon-button:not(.disabled):hover.icon-button-layer-2::before, .tab-switcher-container .tab-switcher .tab.with-children .icon-button:not(.disabled):active.icon-button-layer-2::before, .tab-switcher-container .tab-switcher .tab.with-children .icon-button.open.icon-button-layer-2::before {
  background: initial;
}
.tab-switcher-container .tab-switcher .tab.with-children .icon-button:not(.disabled):hover.icon-button-layer-3::before, .tab-switcher-container .tab-switcher .tab.with-children .icon-button:not(.disabled):active.icon-button-layer-3::before, .tab-switcher-container .tab-switcher .tab.with-children .icon-button.open.icon-button-layer-3::before {
  background: initial;
}
.tab-switcher-container .tab-switcher .tab-switcher-underline {
  border-bottom: 2px solid #0098A6;
  bottom: 0;
  left: 0;
  position: absolute;
  transition: left 0.5s ease-in-out, width 0.5s ease-in-out;
  width: 0;
}
.tab-switcher-container.tab-switcher-layer-1::before {
  background: linear-gradient(to right, white, rgba(255, 255, 255, 0));
}
.tab-switcher-container.tab-switcher-layer-1::after {
  background: linear-gradient(to left, white, rgba(255, 255, 255, 0));
}
.tab-switcher-container.tab-switcher-layer-2::before {
  background: linear-gradient(to right, #F3F3F0, rgba(243, 243, 240, 0));
}
.tab-switcher-container.tab-switcher-layer-2::after {
  background: linear-gradient(to left, #F3F3F0, rgba(243, 243, 240, 0));
}
.tab-switcher-container.tab-switcher-layer-3::before {
  background: linear-gradient(to right, #DFDFD9, rgba(223, 223, 217, 0));
}
.tab-switcher-container.tab-switcher-layer-3::after {
  background: linear-gradient(to left, #DFDFD9, rgba(223, 223, 217, 0));
}
.tab-switcher-container.dark::before {
  background: linear-gradient(to right, #000028, rgba(0, 0, 40, 0));
}
.tab-switcher-container.dark::after {
  background: linear-gradient(to left, #000028, rgba(0, 0, 40, 0));
}
.tab-switcher-container.dark:focus-visible .tab-switcher .tab.active {
  background-color: #001F39;
}
.tab-switcher-container.dark .tab-switcher::-webkit-scrollbar-track {
  background-color: white;
}
.tab-switcher-container.dark .tab-switcher::-webkit-scrollbar-thumb {
  background-color: #00CCCC;
}
.tab-switcher-container.dark .tab-switcher::-webkit-scrollbar-thumb:hover {
  background-color: #00FFB9;
}
.tab-switcher-container.dark .tab-switcher .tab {
  border-color: #66667E;
  color: white;
}
.tab-switcher-container.dark .tab-switcher .tab:hover, .tab-switcher-container.dark .tab-switcher .tab:active {
  background-color: #001F39;
}
.tab-switcher-container.dark .tab-switcher .tab-switcher-underline {
  border-color: #00FFB9;
}
.tab-switcher-container.dark.tab-switcher-layer-1::before {
  background: linear-gradient(to right, #000028, rgba(0, 0, 40, 0));
}
.tab-switcher-container.dark.tab-switcher-layer-1::after {
  background: linear-gradient(to left, #000028, rgba(0, 0, 40, 0));
}
.tab-switcher-container.dark.tab-switcher-layer-2::before {
  background: linear-gradient(to right, #23233C, rgba(35, 35, 60, 0));
}
.tab-switcher-container.dark.tab-switcher-layer-2::after {
  background: linear-gradient(to left, #23233C, rgba(35, 35, 60, 0));
}
.tab-switcher-container.dark.tab-switcher-layer-3::before {
  background: linear-gradient(to right, #37374D, rgba(55, 55, 77, 0));
}
.tab-switcher-container.dark.tab-switcher-layer-3::after {
  background: linear-gradient(to left, #37374D, rgba(55, 55, 77, 0));
}

.tag {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 8px;
  padding: 6px 16px;
  color: #007993;
  background-color: transparent;
  border: 1px solid #007993;
  border-radius: 17px;
  outline: none;
  cursor: pointer;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;
}
.tag:hover, .tag:active {
  color: #005159;
  background-color: #C2FFEE;
  border-color: #005159;
}
.tag:hover svg, .tag:active svg {
  fill: #005159;
}
.tag.disabled {
  color: #7D8099;
  background-color: transparent;
  border-color: #7D8099;
  cursor: not-allowed;
}
.tag.disabled svg {
  fill: #7D8099;
}
.tag.display {
  color: #000028;
  background-color: transparent;
  border-color: #000028;
  cursor: default;
}
.tag.display svg {
  fill: #000028;
}
.tag:focus-visible::before {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  outline: 1px solid #1491EB;
  outline-offset: 2px;
}
.tag .tag-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.tag svg {
  fill: #007993;
  width: 20px;
  transition: fill 0.3s ease-in-out;
}
.tag.dark {
  color: #00CCCC;
  border-color: #00CCCC;
}
.tag.dark:hover, .tag.dark:active {
  color: #00FFB9;
  background-color: #001F39;
  border-color: #00FFB9;
}
.tag.dark:hover svg, .tag.dark:active svg {
  fill: #00FFB9;
}
.tag.dark.disabled {
  color: #66667E;
  background-color: transparent;
  border-color: #66667E;
}
.tag.dark.disabled svg {
  fill: #66667E;
}
.tag.dark.display {
  color: #B3B3BE;
  background-color: transparent;
  border-color: #B3B3BE;
}
.tag.dark.display svg {
  fill: #B3B3BE;
}
.tag.dark svg {
  fill: #00CCCC;
}

.tag-bar {
  display: flex;
  justify-content: space-between;
  gap: 25px;
}
@media (min-width: 576px) {
  .tag-bar {
    gap: 15px;
  }
}
.tag-bar .tags-container {
  position: relative;
  min-width: 0;
}
.tag-bar .tags-container::before, .tag-bar .tags-container::after {
  position: absolute;
  content: "";
  top: 0;
  bottom: 0;
  z-index: 2;
  width: 10px;
  pointer-events: none;
}
.tag-bar .tags-container::before {
  left: -11px;
}
.tag-bar .tags-container::after {
  right: -10px;
}
.tag-bar .tags-container .tags {
  display: flex;
  gap: 5px;
  flex-wrap: nowrap;
  margin: 0 -10px;
  padding: 2px 10px;
  overflow-x: auto;
}
@media (min-width: 576px) {
  .tag-bar .tags-container .tags {
    flex-wrap: wrap;
  }
}
.tag-bar .tags-container .tags::-webkit-scrollbar {
  height: 4px;
}
.tag-bar .tags-container .tags::-webkit-scrollbar-track {
  background: transparent;
}
.tag-bar .tags-container .tags::-webkit-scrollbar-thumb {
  background: #CCCCD4;
}
.tag-bar .tags-container .tags .tag {
  max-width: 140px;
}
.tag-bar.deep-blue .tags-container::before {
  background: linear-gradient(to right, #000028, rgba(0, 0, 40, 0));
}
.tag-bar.deep-blue .tags-container::after {
  background: linear-gradient(to left, #000028, rgba(0, 0, 40, 0));
}
.tag-bar.deep-blue .tags-container .tags::-webkit-scrollbar-thumb {
  background: #4C4C68;
}
.tag-bar.light-sand .tags-container::before {
  background: linear-gradient(to right, #F3F3F0, rgba(243, 243, 240, 0));
}
.tag-bar.light-sand .tags-container::after {
  background: linear-gradient(to left, #F3F3F0, rgba(243, 243, 240, 0));
}
.tag-bar.white .tags-container::before {
  background: linear-gradient(to right, white, rgba(255, 255, 255, 0));
}
.tag-bar.white .tags-container::after {
  background: linear-gradient(to left, white, rgba(255, 255, 255, 0));
}

.tag-input .tag-input-field {
  min-width: 205px;
  box-sizing: border-box;
  padding: 4px 14px 6px 14px;
  color: #4C4C68;
  background-color: #EBF7F8;
  border-bottom: 1px solid #4C4C68;
  outline: none;
  user-select: none;
  cursor: text;
  transition: color 0.3s ease-in-out, background 0.3s ease-in-out, border 0.3s ease-in-out;
}
.tag-input .tag-input-field .tag-input-label {
  position: relative;
  font-size: 10px;
  user-select: none;
  pointer-events: none;
  transition: color 0.3s ease-in-out;
}
.tag-input .tag-input-field .tag-input-label.required::before {
  position: absolute;
  content: "*";
  right: calc(100% + 2px);
}
.tag-input .tag-input-field .tag-input-container {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  padding-top: 5px;
}
.tag-input .tag-input-field .tag-input-container .tag {
  padding: 4px 12px;
  gap: 6px;
}
.tag-input .tag-input-field .tag-input-container input {
  height: 30px;
  box-sizing: border-box;
  padding: 0 2px;
  flex-grow: 1;
  font-size: 16px;
  color: #000028;
  background: none;
  border: none;
  outline: none;
}
.tag-input .tag-input-field:hover, .tag-input .tag-input-field:focus-within {
  color: #000028;
  background-color: #D1FFF2;
  border-color: #00D7A0;
}
.tag-input .tag-input-field:hover .tag-input-label, .tag-input .tag-input-field:focus-within .tag-input-label {
  color: #000028;
}
.tag-input .tag-input-message {
  margin: 3px 14px 0;
  font-size: 10px;
  color: #000028;
  user-select: none;
}
.tag-input.error:not(.disabled) .tag-input-field:not(:focus-within) {
  color: #000028;
  background-color: #FCCCD7;
  border-color: #FF2640;
}
.tag-input.error:not(.disabled) .tag-input-field:not(:focus-within) .tag-input-label {
  color: #FF2640 !important;
}
.tag-input.error:not(.disabled) .tag-input-message {
  color: #FF2640;
}
.tag-input.disabled .tag-input-field {
  color: #7D8099;
  background-color: #EBF7F8;
  border-color: transparent;
  cursor: not-allowed;
}
.tag-input.disabled .tag-input-field .tag-input-label {
  color: #7D8099;
}
.tag-input.disabled .tag-input-field .tag-input-container .tag {
  cursor: not-allowed;
}
.tag-input.disabled .tag-input-field .tag-input-container input {
  cursor: not-allowed;
}
.tag-input.dark .tag-input-field {
  color: #EBEBEE;
  background-color: #00183B;
  border-color: #7D8099;
}
.tag-input.dark .tag-input-field .tag-input-label {
  color: #7D8099;
}
.tag-input.dark .tag-input-field .tag-input-container input {
  color: white;
}
.tag-input.dark .tag-input-field:hover, .tag-input.dark .tag-input-field:focus-within {
  color: #EBEBEE;
  background-color: #001F39;
  border-color: #00FFB9;
}
.tag-input.dark .tag-input-field:hover .tag-input-label, .tag-input.dark .tag-input-field:focus-within .tag-input-label {
  color: #00FFB9;
}
.tag-input.dark .tag-input-message {
  color: #EBEBEE;
}
.tag-input.dark.error:not(.disabled) .tag-input-field:not(:focus-within) {
  color: #EBEBEE;
  background-color: #331131;
  border-color: #FF5454;
}
.tag-input.dark.error:not(.disabled) .tag-input-field:not(:focus-within) .tag-input-label {
  color: #FF5454 !important;
}
.tag-input.dark.error:not(.disabled) .tag-input-message {
  color: #7D8099;
}
.tag-input.dark.disabled .tag-input-field, .tag-input.dark.disabled:hover .tag-input-field {
  color: #66667E;
  background-color: #00183B;
  border-color: transparent;
}
.tag-input.dark.disabled .tag-input-field .tag-input-label, .tag-input.dark.disabled:hover .tag-input-field .tag-input-label {
  color: #66667E;
}

.text.text-box-title {
  color: #000028;
  font-size: 20px;
  line-height: 26px;
}
.text.text-box-title.dark {
  color: white;
}
.text.text-label {
  color: #000028;
  font-size: 18px;
  line-height: 28px;
}
.text.text-label.dark {
  color: white;
}
.text.text-normal {
  color: #000028;
  font-size: 16px;
  line-height: 24px;
}
.text.text-normal.dark {
  color: white;
}
.text.text-small {
  color: #000028;
  font-size: 14px;
  line-height: 20px;
}
.text.text-small.dark {
  color: white;
}
.text.text-subordinate {
  color: #7D8099;
  font-size: 14px;
  line-height: 20px;
}
.text.text-subordinate.dark {
  color: #B3B3BE;
}
.text.text-subtitle {
  color: #000028;
  font-size: 26px;
  font-weight: 900;
  line-height: 34px;
}
.text.text-subtitle.dark {
  color: white;
}
.text.text-subtitle-small {
  color: #000028;
  font-size: 20px;
  font-weight: 900;
  line-height: 26px;
}
.text.text-subtitle-small.dark {
  color: white;
}
.text.text-subtitle-very-small {
  color: #000028;
  font-size: 16px;
  font-weight: 900;
  line-height: 24px;
}
.text.text-subtitle-very-small.dark {
  color: white;
}
.text.text-subtitle-very-small-light {
  color: #000028;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}
.text.text-subtitle-very-small-light.dark {
  color: white;
}
.text.text-title {
  color: #000028;
  font-size: 40px;
  line-height: 56px;
}
.text.text-title.dark {
  color: white;
}
.text.text-title-label {
  color: #7D8099;
  font-size: 16px;
  line-height: 24px;
}
.text.text-title-label.dark {
  color: #B3B3BE;
}
.text.text-color-reference {
  color: #553BA3;
}
.text.text-color-reference.dark {
  color: #805CFF;
}
.text.text-color-alternative {
  color: #EC6602;
}
.text.text-color-alternative.dark {
  color: #FF9000;
}

.text-area .text-area-field {
  overflow: hidden;
  position: relative;
}
.text-area .text-area-field textarea {
  background-color: #EBF7F8;
  border: none;
  border-bottom: 1px solid #4C4C68;
  border-radius: 0;
  color: #4C4C68;
  font: inherit;
  margin: 0;
  outline: none;
  padding: 16px 14px 6px;
  resize: none;
  scroll-padding-top: 16px;
  transition: color 0.3s ease-in-out, background 0.3s ease-in-out, border 0.3s ease-in-out;
  width: calc(100% - 28px);
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.text-area .text-area-field textarea::placeholder {
  color: #4C4C68;
  transition: color 0.3s ease-in-out;
  user-select: none;
}
.text-area .text-area-field textarea::-webkit-scrollbar {
  width: 6px;
}
.text-area .text-area-field textarea::-webkit-scrollbar-track {
  background-color: inherit;
}
.text-area .text-area-field textarea::-webkit-scrollbar-thumb {
  background-color: #D9EFF1;
  cursor: pointer;
}
.text-area .text-area-field textarea.error:not(:disabled):not(:focus) {
  background-color: #FCCCD7;
  border-color: #FF2640;
  color: #000028;
}
.text-area .text-area-field textarea.error:not(:disabled):not(:focus)::-webkit-scrollbar-thumb {
  background-color: #FFA8B3;
}
.text-area .text-area-field textarea.error:not(:disabled):not(:focus)::-webkit-scrollbar-thumb:hover {
  background-color: #FFB9C1;
}
.text-area .text-area-field textarea:focus, .text-area .text-area-field textarea:hover:not(:disabled) {
  background-color: #D1FFF2;
  border-color: #00D7A0;
  color: #000028;
}
.text-area .text-area-field textarea:focus::placeholder, .text-area .text-area-field textarea:hover:not(:disabled)::placeholder {
  color: #000028;
}
.text-area .text-area-field textarea:focus::-webkit-scrollbar-thumb, .text-area .text-area-field textarea:hover:not(:disabled)::-webkit-scrollbar-thumb {
  background-color: #91F3D7;
}
.text-area .text-area-field textarea:focus::-webkit-scrollbar-thumb:hover, .text-area .text-area-field textarea:hover:not(:disabled)::-webkit-scrollbar-thumb:hover {
  background-color: #B0F6E3;
}
.text-area .text-area-field textarea:disabled {
  background-color: #EBF7F8 !important;
  border-color: transparent;
  color: #7D8099 !important;
  cursor: not-allowed;
  -webkit-text-fill-color: #7D8099;
}
.text-area .text-area-field textarea:read-only {
  background-color: transparent;
  border-color: transparent;
  color: #4C4C68;
  cursor: not-allowed;
}
.text-area .text-area-field textarea.error:not(:disabled):not(:focus) + .text-area-label {
  background-color: #FCCCD7;
  box-shadow: -2px -4px 3px 6px #FCCCD7;
  color: #FF2640 !important;
}
.text-area .text-area-field textarea:focus + .text-area-label {
  background-color: #D1FFF2;
  box-shadow: -2px -4px 3px 6px #D1FFF2;
  color: #000028;
  font-size: 10px;
  top: 2px;
}
.text-area .text-area-field textarea:hover + .text-area-label {
  background-color: #D1FFF2;
  box-shadow: -2px -4px 3px 6px #D1FFF2;
  color: #000028;
}
.text-area .text-area-field textarea:disabled + .text-area-label {
  background-color: #EBF7F8 !important;
  box-shadow: -2px -4px 3px 6px #EBF7F8 !important;
  color: #7D8099 !important;
}
.text-area .text-area-field textarea:read-only + .text-area-label {
  background-color: white;
  box-shadow: -2px -4px 3px 6px white;
  color: #4C4C68;
}
.text-area .text-area-field textarea:required + .text-area-label::before {
  content: "*";
  position: absolute;
  right: calc(100% + 2px);
}
.text-area .text-area-field .text-area-label {
  align-items: center;
  background-color: #EBF7F8;
  box-shadow: -2px -4px 3px 6px #EBF7F8;
  color: #4C4C68;
  display: flex;
  font-size: 16px;
  margin-left: 14px;
  padding-right: 2px;
  pointer-events: none;
  position: absolute;
  top: 16px;
  transition: color 0.3s ease-in-out, font-size 0.15s ease-in-out, top 0.15s ease-in-out, background 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  user-select: none;
}
.text-area .text-area-field .text-area-label.filled {
  font-size: 10px;
  top: 2px;
}
.text-area .text-area-message {
  color: #000028;
  font-size: 10px;
  margin: 3px 14px 0;
  user-select: none;
}
.text-area .text-area-message.error {
  color: #FF2640;
}
.text-area.dark .text-area-field textarea {
  background-color: #00183B;
  border-color: #7D8099;
  color: #EBEBEE;
}
.text-area.dark .text-area-field textarea::placeholder {
  color: #EBEBEE;
}
.text-area.dark .text-area-field textarea::-webkit-scrollbar-thumb {
  background-color: #37374D !important;
}
.text-area.dark .text-area-field textarea::-webkit-scrollbar-thumb:hover {
  background-color: #66667E !important;
}
.text-area.dark .text-area-field textarea.error:not(:disabled):not(:focus) {
  background-color: #331131;
  border-color: #FF5454;
  color: #EBEBEE;
}
.text-area.dark .text-area-field textarea.error:not(:disabled):not(:focus)::-webkit-scrollbar-thumb {
  background-color: #4D1A4A !important;
}
.text-area.dark .text-area-field textarea.error:not(:disabled):not(:focus)::-webkit-scrollbar-thumb:hover {
  background-color: #5A1E57 !important;
}
.text-area.dark .text-area-field textarea:focus, .text-area.dark .text-area-field textarea:hover {
  background-color: #001F39;
  border-color: #00FFB9;
  color: #EBEBEE;
}
.text-area.dark .text-area-field textarea:disabled {
  background-color: #00183B !important;
  border-color: transparent;
  color: #66667E !important;
  -webkit-text-fill-color: #66667E;
}
.text-area.dark .text-area-field textarea:read-only {
  background-color: transparent;
  border-color: transparent;
  color: #7D8099;
}
.text-area.dark .text-area-field textarea.error:not(:disabled):not(:focus) + .text-area-label {
  background-color: #331131 !important;
  box-shadow: -2px -4px 3px 6px #331131 !important;
  color: #FF5454 !important;
}
.text-area.dark .text-area-field textarea:hover + .text-area-label {
  background-color: #001F39;
  box-shadow: -2px -4px 3px 6px #001F39;
  color: #EBEBEE;
}
.text-area.dark .text-area-field textarea:hover + .text-area-label.filled, .text-area.dark .text-area-field textarea:focus + .text-area-label, .text-area.dark .text-area-field textarea:focus:hover + .text-area-label {
  background-color: #001F39;
  box-shadow: -2px -4px 3px 6px #001F39;
  color: #00FFB9;
}
.text-area.dark .text-area-field textarea:disabled + .text-area-label, .text-area.dark .text-area-field textarea:disabled:hover + .text-area-label {
  background-color: #00183B !important;
  box-shadow: -2px -4px 3px 6px #00183B !important;
  color: #66667E !important;
}
.text-area.dark .text-area-field textarea:read-only + .text-area-label, .text-area.dark .text-area-field textarea:read-only:hover + .text-area-label, .text-area.dark .text-area-field textarea:read-only:focus + .text-area-label {
  background-color: #000028;
  box-shadow: -2px -4px 3px 6px #000028;
  color: #7D8099;
}
.text-area.dark .text-area-field .text-area-label {
  background-color: #00183B;
  box-shadow: -2px -4px 3px 6px #00183B;
  color: #7D8099;
}
.text-area.dark .text-area-message {
  color: #EBEBEE;
}
.text-area.dark .text-area-message.error {
  color: #7D8099;
}

.text-input .text-input-field {
  position: relative;
}
.text-input .text-input-field input {
  width: calc(100% - 28px);
  margin: 0;
  padding: 16px 14px 6px;
  font: inherit;
  color: #4C4C68;
  background-color: #EBF7F8;
  border: none;
  border-bottom: 1px solid #4C4C68;
  border-radius: 0;
  outline: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  transition: color 0.3s ease-in-out, background 0.3s ease-in-out, border 0.3s ease-in-out;
}
.text-input .text-input-field input::placeholder {
  color: #4C4C68;
  user-select: none;
  transition: color 0.3s ease-in-out;
}
.text-input .text-input-field input.error:not(:disabled):not(:focus) {
  color: #000028;
  background-color: #FCCCD7;
  border-color: #FF2640;
}
.text-input .text-input-field input.highlighted:not(:disabled):not(:focus):not(:hover):not(.error) {
  border-color: #007993;
}
.text-input .text-input-field input.highlighted:not(:disabled):not(:focus):not(:hover):not(.error) + .text-input-label {
  color: #007993;
}
.text-input .text-input-field input:focus, .text-input .text-input-field input:hover {
  color: #000028;
  background-color: #D1FFF2;
  border-color: #00D7A0;
}
.text-input .text-input-field input:focus::placeholder, .text-input .text-input-field input:hover::placeholder {
  color: #000028;
}
.text-input .text-input-field input:disabled {
  color: #7D8099 !important;
  -webkit-text-fill-color: #7D8099;
  background-color: #EBF7F8 !important;
  border-color: transparent;
  cursor: not-allowed;
}
.text-input .text-input-field input:read-only {
  color: #4C4C68;
  background-color: transparent;
  border-color: transparent;
  cursor: not-allowed;
}
.text-input .text-input-field input.error:not(:disabled):not(:focus) + .text-input-label {
  color: #FF2640 !important;
}
.text-input .text-input-field input:focus + .text-input-label {
  bottom: 24px;
  font-size: 10px;
  color: #000028;
}
.text-input .text-input-field input:hover + .text-input-label {
  color: #000028;
}
.text-input .text-input-field input:disabled + .text-input-label {
  color: #7D8099 !important;
}
.text-input .text-input-field input:read-only + .text-input-label {
  color: #4C4C68;
}
.text-input .text-input-field input:required + .text-input-label::before {
  position: absolute;
  content: "*";
  right: calc(100% + 2px);
}
.text-input .text-input-field input.with-full-border {
  border: 1px solid #CCCCD4;
  padding: 15px 13px 6px;
}
.text-input .text-input-field .text-input-label {
  position: absolute;
  top: 2px;
  bottom: 2px;
  display: flex;
  align-items: center;
  margin-left: 14px;
  font-size: 16px;
  color: #4C4C68;
  user-select: none;
  pointer-events: none;
  transition: color 0.3s ease-in-out, font-size 0.15s ease-in-out, bottom 0.15s ease-in-out;
}
.text-input .text-input-field .text-input-label.filled {
  bottom: 24px;
  font-size: 10px;
}
.text-input .text-input-message {
  margin: 3px 14px 0;
  font-size: 10px;
  color: #000028;
  user-select: none;
}
.text-input .text-input-message.error {
  color: #FF2640;
}
.text-input.dark .text-input-field input {
  color: #EBEBEE;
  background-color: #00183B;
  border-color: #7D8099;
}
.text-input.dark .text-input-field input::placeholder {
  color: #EBEBEE;
}
.text-input.dark .text-input-field input.error:not(:disabled):not(:focus) {
  color: #EBEBEE;
  background-color: #331131;
  border-color: #FF5454;
}
.text-input.dark .text-input-field input.highlighted:not(:disabled):not(:focus):not(:hover):not(.error) {
  border-color: #00CCCC;
}
.text-input.dark .text-input-field input.highlighted:not(:disabled):not(:focus):not(:hover):not(.error) + .text-input-label {
  color: #00CCCC;
}
.text-input.dark .text-input-field input:focus, .text-input.dark .text-input-field input:hover {
  color: #EBEBEE;
  background-color: #001F39;
  border-color: #00FFB9;
}
.text-input.dark .text-input-field input:disabled {
  color: #66667E !important;
  -webkit-text-fill-color: #66667E;
  background-color: #00183B !important;
  border-color: transparent;
}
.text-input.dark .text-input-field input:read-only {
  color: #B3B3BE;
  background-color: transparent;
  border-color: transparent;
}
.text-input.dark .text-input-field input.error:not(:disabled):not(:focus) + .text-input-label {
  color: #FF5454 !important;
}
.text-input.dark .text-input-field input:hover + .text-input-label {
  color: #EBEBEE;
}
.text-input.dark .text-input-field input:hover + .text-input-label.filled, .text-input.dark .text-input-field input:focus + .text-input-label, .text-input.dark .text-input-field input:focus:hover + .text-input-label {
  color: #00FFB9;
}
.text-input.dark .text-input-field input:disabled + .text-input-label, .text-input.dark .text-input-field input:disabled:hover + .text-input-label {
  color: #66667E !important;
}
.text-input.dark .text-input-field input:read-only + .text-input-label, .text-input.dark .text-input-field input:read-only:hover + .text-input-label, .text-input.dark .text-input-field input:read-only:focus + .text-input-label {
  color: #B3B3BE;
}
.text-input.dark .text-input-field input.with-full-border {
  border-color: #40415C;
}
.text-input.dark .text-input-field .text-input-label {
  color: #7D8099;
}
.text-input.dark .text-input-message {
  color: #EBEBEE;
}
.text-input.dark .text-input-message.error {
  color: #7D8099;
}

.tile {
  background-color: #F3F3F0;
  border-radius: 4px;
  padding: 16px 16px 10px;
}
.tile .tile-label {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 6px;
}
.tile .tile-value {
  font-size: 40px;
  font-weight: 900;
  line-height: 56px;
  margin-left: -2px;
}
.tile .tile-value.color-blue {
  color: #007EB1;
}
.tile .tile-value.color-green {
  color: #01893A;
}
.tile .tile-value.color-red {
  color: #D72339;
}
.tile .tile-value.color-yellow {
  color: #E9C32A;
}
.tile.dark {
  background-color: #23233C;
}
.tile.dark .tile-value.color-blue {
  color: #00BEDC;
}
.tile.dark .tile-value.color-green {
  color: #01D65A;
}
.tile.dark .tile-value.color-red {
  color: #FF2640;
}
.tile.dark .tile-value.color-yellow {
  color: #FFD732;
}

.tile-with-actions {
  background-color: #F3F3F0;
  border-radius: 4px;
  grid-column: span 2;
  padding: 16px 16px 24px;
}
.tile-with-actions .tile-label {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 8px;
}
.tile-with-actions .tile-actions {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.tile-with-actions .tile-actions .tile-action {
  align-items: center;
  background-color: #DFDFD9;
  border-radius: 4px;
  cursor: pointer;
  display: grid;
  gap: 8px;
  grid-template-columns: auto 1fr auto;
  outline: none;
  padding: 8px;
  transition: background-color 0.2s ease-in-out;
}
.tile-with-actions .tile-actions .tile-action .tile-action-icon {
  justify-self: center;
}
.tile-with-actions .tile-actions .tile-action .tile-action-icon svg {
  width: 24px;
}
.tile-with-actions .tile-actions .tile-action .tile-action-text-container {
  user-select: none;
}
.tile-with-actions .tile-actions .tile-action .tile-action-text-container .tile-action-title {
  color: #7D8099;
  font-size: 12px;
  line-height: 16px;
}
.tile-with-actions .tile-actions .tile-action .tile-action-text-container .tile-action-text {
  color: #000028;
  font-size: 14px;
  line-height: 20px;
}
.tile-with-actions .tile-actions .tile-action .tile-action-arrow {
  display: flex;
}
.tile-with-actions .tile-actions .tile-action .tile-action-arrow svg {
  transition: fill 0.2s ease-in-out;
  width: 24px;
}
.tile-with-actions .tile-actions .tile-action:hover {
  background-color: #B0F6E3;
}
.tile-with-actions .tile-actions .tile-action:hover .tile-action-arrow svg {
  fill: #005159;
}
.tile-with-actions .tile-actions .tile-action:focus-visible {
  outline: 1px solid #1491EB;
  outline-offset: 1px;
}
.tile-with-actions.dark {
  background-color: #23233C;
}
.tile-with-actions.dark .tile-actions .tile-action {
  background-color: #37374D;
}
.tile-with-actions.dark .tile-actions .tile-action .tile-action-icon > svg {
  fill: white;
}
.tile-with-actions.dark .tile-actions .tile-action .tile-action-text-container .tile-action-title {
  color: #B3B3BE;
}
.tile-with-actions.dark .tile-actions .tile-action .tile-action-text-container .tile-action-text {
  color: white;
}
.tile-with-actions.dark .tile-actions .tile-action .tile-action-arrow svg {
  fill: white;
}
.tile-with-actions.dark .tile-actions .tile-action:hover {
  background-color: #002949;
}
.tile-with-actions.dark .tile-actions .tile-action:hover .tile-action-arrow svg {
  fill: #00FFB9;
}

.tile-with-bar {
  background-color: #F3F3F0;
  border-radius: 4px;
  grid-column: span 2;
}
.tile-with-bar .tile-content {
  padding: 16px 16px 0;
}
.tile-with-bar .tile-content .tile-lable {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 6px;
}
.tile-with-bar .tile-content .tile-value {
  font-size: 40px;
  font-weight: 900;
  line-height: 56px;
  margin-left: -2px;
}
.tile-with-bar .tile-content .tile-value.color-blue {
  color: #007EB1;
}
.tile-with-bar .tile-content .tile-value.color-green {
  color: #01893A;
}
.tile-with-bar .tile-content .tile-value.color-red {
  color: #D72339;
}
.tile-with-bar .tile-content .tile-value.color-yellow {
  color: #E9C32A;
}
.tile-with-bar .tile-content .tile-bar {
  border-radius: 5px;
  display: flex;
  overflow: hidden;
}
.tile-with-bar .tile-content .tile-bar .tile-bar-segment {
  height: 10px;
}
.tile-with-bar .tile-content .tile-bar .tile-bar-segment:not(:last-child) {
  border-right: 1px solid #D9D9D6;
}
.tile-with-bar .tile-content .tile-bar .tile-bar-segment.color-0 {
  background-color: #D72339;
}
.tile-with-bar .tile-content .tile-bar .tile-bar-segment.color-1 {
  background-color: #E9C32A;
}
.tile-with-bar .tile-content .tile-bar .tile-bar-segment.color-2 {
  background-color: #007EB1;
}
.tile-with-bar .tile-content .tile-bar .tile-bar-segment.color-3 {
  background-color: #01893A;
}
.tile-with-bar .tile-content .tile-bar-labels {
  display: flex;
  gap: 16px;
  margin-bottom: 20px;
  margin-top: 12px;
}
.tile-with-bar .tile-content .tile-bar-labels .tile-bar-label {
  align-items: baseline;
  display: flex;
}
.tile-with-bar .tile-content .tile-bar-labels .tile-bar-label .tile-bar-label-dot {
  border-radius: 4px;
  height: 8px;
  margin-right: 4px;
  width: 8px;
}
.tile-with-bar .tile-content .tile-bar-labels .tile-bar-label .tile-bar-label-text {
  font-size: 12px;
  line-height: 16px;
}
.tile-with-bar .tile-content .tile-bar-labels .tile-bar-label:nth-child(1) .tile-bar-label-dot {
  background-color: #D72339;
}
.tile-with-bar .tile-content .tile-bar-labels .tile-bar-label:nth-child(2) .tile-bar-label-dot {
  background-color: #E9C32A;
}
.tile-with-bar .tile-content .tile-bar-labels .tile-bar-label:nth-child(3) .tile-bar-label-dot {
  background-color: #007EB1;
}
.tile-with-bar .tile-content .tile-bar-labels .tile-bar-label:nth-child(4) .tile-bar-label-dot {
  background-color: #01893A;
}
.tile-with-bar .tile-action {
  border-top: 1px solid white;
  cursor: pointer;
  outline: none;
  padding: 8px 16px;
  transition: background-color 0.2s ease-in-out;
}
.tile-with-bar .tile-action .tile-action-text {
  align-items: center;
  color: #007993;
  display: flex;
  font-size: 16px;
  line-height: 24px;
  user-select: none;
  transition: color 0.2s ease-in-out;
}
.tile-with-bar .tile-action .tile-action-text svg {
  fill: #007993;
  margin-left: 1px;
  width: 16px;
  transition: fill 0.2s ease-in-out;
}
.tile-with-bar .tile-action:hover {
  background-color: #C5FFEF;
}
.tile-with-bar .tile-action:hover .tile-action-text {
  color: #005159;
}
.tile-with-bar .tile-action:hover .tile-action-text svg {
  fill: #005159;
}
.tile-with-bar .tile-action:focus-visible {
  outline: 1px solid #1491EB;
  outline-offset: 1px;
}
.tile-with-bar.dark {
  background-color: #23233C;
}
.tile-with-bar.dark .tile-content .tile-value.color-blue {
  color: #00BEDC;
}
.tile-with-bar.dark .tile-content .tile-value.color-green {
  color: #01D65A;
}
.tile-with-bar.dark .tile-content .tile-value.color-red {
  color: #FF2640;
}
.tile-with-bar.dark .tile-content .tile-value.color-yellow {
  color: #FFD732;
}
.tile-with-bar.dark .tile-content .tile-bar .tile-bar-segment {
  border-color: #000028;
}
.tile-with-bar.dark .tile-content .tile-bar .tile-bar-segment.color-0 {
  background-color: #FF2640;
}
.tile-with-bar.dark .tile-content .tile-bar .tile-bar-segment.color-1 {
  background-color: #FFD732;
}
.tile-with-bar.dark .tile-content .tile-bar .tile-bar-segment.color-2 {
  background-color: #00BEDC;
}
.tile-with-bar.dark .tile-content .tile-bar .tile-bar-segment.color-3 {
  background-color: #01D65A;
}
.tile-with-bar.dark .tile-content .tile-bar-labels .tile-bar-label:nth-child(1) .tile-bar-label-dot {
  background-color: #FF2640;
}
.tile-with-bar.dark .tile-content .tile-bar-labels .tile-bar-label:nth-child(2) .tile-bar-label-dot {
  background-color: #FFD732;
}
.tile-with-bar.dark .tile-content .tile-bar-labels .tile-bar-label:nth-child(3) .tile-bar-label-dot {
  background-color: #00BEDC;
}
.tile-with-bar.dark .tile-content .tile-bar-labels .tile-bar-label:nth-child(4) .tile-bar-label-dot {
  background-color: #01D65A;
}
.tile-with-bar.dark .tile-action {
  border-color: #000028;
}
.tile-with-bar.dark .tile-action .tile-action-text {
  color: #00CCCC;
}
.tile-with-bar.dark .tile-action .tile-action-text svg {
  fill: #00CCCC;
}
.tile-with-bar.dark .tile-action:hover {
  background-color: #001F39;
}
.tile-with-bar.dark .tile-action:hover .tile-action-text {
  color: #00FFB9;
}
.tile-with-bar.dark .tile-action:hover .tile-action-text svg {
  fill: #00FFB9;
}

.timeline {
  background-color: #F3F3F0;
  border-radius: 4px;
  grid-column: span 2;
  padding: 16px 16px 24px;
}
.timeline .timeline-label {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 8px;
}
.timeline .timeline-container {
  display: grid;
  gap: 8px;
  grid-template: 1fr/9px auto;
}
.timeline .timeline-container .timeline-line {
  border-left: 1px dashed #000028;
  justify-self: center;
}
.timeline .timeline-container .timeline-entries {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 5px;
}
.timeline .timeline-container .timeline-entries .timeline-entry .timeline-entry-date {
  color: #B3B3BE;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 2px;
  position: relative;
}
.timeline .timeline-container .timeline-entries .timeline-entry .timeline-entry-date .timeline-entry-dot {
  border-radius: 4px;
  height: 8px;
  left: -16px;
  position: absolute;
  top: 4px;
  width: 8px;
}
.timeline .timeline-container .timeline-entries .timeline-entry .timeline-entry-date .timeline-entry-dot.color-blue {
  background-color: #007EB1;
}
.timeline .timeline-container .timeline-entries .timeline-entry .timeline-entry-date .timeline-entry-dot.color-green {
  background-color: #01893A;
}
.timeline .timeline-container .timeline-entries .timeline-entry .timeline-entry-date .timeline-entry-dot.color-red {
  background-color: #D72339;
}
.timeline .timeline-container .timeline-entries .timeline-entry .timeline-entry-date .timeline-entry-dot.color-yellow {
  background-color: #E9C32A;
}
.timeline .timeline-container .timeline-entries .timeline-entry .timeline-entry-container {
  background-color: #DFDFD9;
  border-radius: 4px;
  padding: 8px;
}
.timeline .timeline-container .timeline-entries .timeline-entry .timeline-entry-container .timeline-entry-title {
  color: #7D8099;
  font-size: 12px;
  line-height: 16px;
}
.timeline .timeline-container .timeline-entries .timeline-entry .timeline-entry-container .timeline-entry-text {
  color: #000028;
  font-size: 14px;
  line-height: 20px;
}
.timeline.dark {
  background-color: #23233C;
}
.timeline.dark .timeline-container .timeline-line {
  border-color: #737389;
}
.timeline.dark .timeline-container .timeline-entries .timeline-entry .timeline-entry-date {
  color: #737389;
}
.timeline.dark .timeline-container .timeline-entries .timeline-entry .timeline-entry-date .timeline-entry-dot.color-blue {
  background-color: #00BEDC;
}
.timeline.dark .timeline-container .timeline-entries .timeline-entry .timeline-entry-date .timeline-entry-dot.color-green {
  background-color: #01D65A;
}
.timeline.dark .timeline-container .timeline-entries .timeline-entry .timeline-entry-date .timeline-entry-dot.color-red {
  background-color: #FF2640;
}
.timeline.dark .timeline-container .timeline-entries .timeline-entry .timeline-entry-date .timeline-entry-dot.color-yellow {
  background-color: #FFD732;
}
.timeline.dark .timeline-container .timeline-entries .timeline-entry .timeline-entry-container {
  background-color: #37374D;
}
.timeline.dark .timeline-container .timeline-entries .timeline-entry .timeline-entry-container .timeline-entry-title {
  color: #B3B3BE;
}
.timeline.dark .timeline-container .timeline-entries .timeline-entry .timeline-entry-container .timeline-entry-text {
  color: white;
}

.toggle {
  position: relative;
  height: 20px;
  width: 48px;
  padding: 4px;
  outline: none;
  cursor: pointer;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.toggle::before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background-color: #EBEBEE;
  border-radius: 10px;
  transition: background 0.3s ease-out;
}
.toggle::after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  display: block;
  width: 28px;
  height: 28px;
  background-color: #0098A6;
  border-radius: 100%;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.4);
  transition: left 0.3s ease-in-out, background 0.3s ease-in-out;
}
.toggle.on::before {
  background-color: #00FFB9;
  transition: background 0.3s ease-in;
}
.toggle.on::after {
  left: 28px;
}
.toggle:not(.disabled):hover::after, .toggle:not(.disabled):active::after {
  background-color: #00646E;
}
.toggle.disabled {
  cursor: not-allowed;
}
.toggle.disabled::after {
  background-color: #D9D9D6;
}
.toggle:focus-visible {
  outline: 1px solid #1491EB;
  outline-offset: 1px;
}
.toggle.dark::before {
  background-color: #DFDFD9;
}
.toggle.dark::after {
  background-color: white;
}
.toggle.dark.on::before {
  background-color: #00FFB9;
}
.toggle.dark:not(.disabled):hover:not(.on)::before, .toggle.dark:not(.disabled):active:not(.on)::before {
  background-color: white;
}
.toggle.dark:not(.disabled):hover:not(.on)::after, .toggle.dark:not(.disabled):active:not(.on)::after {
  background-color: white;
}
.toggle.dark:not(.disabled):hover.on::before, .toggle.dark:not(.disabled):active.on::before {
  background-color: #00FFB9;
}
.toggle.dark:not(.disabled):hover.on::after, .toggle.dark:not(.disabled):active.on::after {
  background-color: #CCCCD4;
}
.toggle.dark.disabled::after {
  background-color: #B3B3BE;
}
.toggle.dark.disabled:not(.on)::before {
  background-color: #737389;
}
.toggle.dark.disabled.on::before {
  background-color: #006662;
}

.tooltip {
  overflow: hidden;
  text-overflow: ellipsis;
}

.wizard {
  position: relative;
}
.wizard.wizard-steps-1 .wizard-steps {
  grid-template-columns: repeat(1, 1fr);
}
.wizard.wizard-steps-1 .wizard-line-container {
  left: calc(50% / 1);
  width: calc(100% - (100% / 1));
}
.wizard.wizard-steps-2 .wizard-steps {
  grid-template-columns: repeat(2, 1fr);
}
.wizard.wizard-steps-2 .wizard-line-container {
  left: calc(50% / 2);
  width: calc(100% - (100% / 2));
}
.wizard.wizard-steps-3 .wizard-steps {
  grid-template-columns: repeat(3, 1fr);
}
.wizard.wizard-steps-3 .wizard-line-container {
  left: calc(50% / 3);
  width: calc(100% - (100% / 3));
}
.wizard.wizard-steps-4 .wizard-steps {
  grid-template-columns: repeat(4, 1fr);
}
.wizard.wizard-steps-4 .wizard-line-container {
  left: calc(50% / 4);
  width: calc(100% - (100% / 4));
}
.wizard.wizard-steps-5 .wizard-steps {
  grid-template-columns: repeat(5, 1fr);
}
.wizard.wizard-steps-5 .wizard-line-container {
  left: calc(50% / 5);
  width: calc(100% - (100% / 5));
}
.wizard.wizard-steps-6 .wizard-steps {
  grid-template-columns: repeat(6, 1fr);
}
.wizard.wizard-steps-6 .wizard-line-container {
  left: calc(50% / 6);
  width: calc(100% - (100% / 6));
}
.wizard.wizard-steps-7 .wizard-steps {
  grid-template-columns: repeat(7, 1fr);
}
.wizard.wizard-steps-7 .wizard-line-container {
  left: calc(50% / 7);
  width: calc(100% - (100% / 7));
}
.wizard.wizard-steps-8 .wizard-steps {
  grid-template-columns: repeat(8, 1fr);
}
.wizard.wizard-steps-8 .wizard-line-container {
  left: calc(50% / 8);
  width: calc(100% - (100% / 8));
}
.wizard.wizard-steps-9 .wizard-steps {
  grid-template-columns: repeat(9, 1fr);
}
.wizard.wizard-steps-9 .wizard-line-container {
  left: calc(50% / 9);
  width: calc(100% - (100% / 9));
}
.wizard .wizard-steps {
  position: relative;
  z-index: 1;
  display: grid;
  grid-template-rows: 1fr;
  gap: 5px;
}
.wizard .wizard-steps .wizard-step {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.wizard .wizard-steps .wizard-step .wizard-step-number {
  position: relative;
  height: 50px;
  width: 50px;
  z-index: 1;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: 700;
  color: #7D8099;
  background-color: white;
  border: 3px solid #7D8099;
  border-radius: 50%;
  outline: none;
  user-select: none;
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.wizard .wizard-steps .wizard-step .wizard-step-number:focus-visible::after {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  outline: 1px solid #1491EB;
  outline-offset: 4px;
}
.wizard .wizard-steps .wizard-step .wizard-step-name {
  font-size: 16px;
  font-weight: 700;
  color: #7D8099;
  user-select: none;
  transition: color 0.5s ease-in-out;
}
.wizard .wizard-steps .wizard-step.passed {
  cursor: pointer;
}
.wizard .wizard-steps .wizard-step.passed .wizard-step-number {
  color: #007993;
  border-color: #007993;
}
.wizard .wizard-steps .wizard-step.passed .wizard-step-name {
  color: #007993;
}
.wizard .wizard-steps .wizard-step.passed:hover .wizard-step-number {
  color: white;
  background-color: #00646E;
  border-color: #00646E;
}
.wizard .wizard-steps .wizard-step.passed:hover .wizard-step-name {
  color: #00646E;
}
.wizard .wizard-steps .wizard-step.active .wizard-step-number {
  color: white;
  background-color: #007993;
  border-color: #007993;
}
.wizard .wizard-steps .wizard-step.active .wizard-step-name {
  color: #007993;
}
.wizard .wizard-line-container {
  position: absolute;
  top: 23px;
  height: 3px;
}
.wizard .wizard-line-container .wizard-line {
  height: 100%;
  background-color: #007993;
  transition: width 0.3s ease-in-out;
}
.wizard.dark .wizard-steps .wizard-step .wizard-step-number {
  color: #7D8099;
  background-color: #000028;
  border: 3px solid #7D8099;
}
.wizard.dark .wizard-steps .wizard-step .wizard-step-name {
  color: #7D8099;
}
.wizard.dark .wizard-steps .wizard-step.passed .wizard-step-number {
  color: #00CCCC;
  border-color: #00CCCC;
}
.wizard.dark .wizard-steps .wizard-step.passed .wizard-step-name {
  color: #00CCCC;
}
.wizard.dark .wizard-steps .wizard-step.passed:hover .wizard-step-number {
  color: #000028;
  background-color: #00FFB9;
  border-color: #00FFB9;
}
.wizard.dark .wizard-steps .wizard-step.passed:hover .wizard-step-name {
  color: #00FFB9;
}
.wizard.dark .wizard-steps .wizard-step.active .wizard-step-number {
  color: #000028;
  background-color: #00CCCC;
  border-color: #00CCCC;
}
.wizard.dark .wizard-steps .wizard-step.active .wizard-step-name {
  color: #00CCCC;
}
.wizard.dark .wizard-line-container .wizard-line {
  background-color: #00CCCC;
}

.breadcrumbs {
  align-items: center;
  display: flex;
}
.breadcrumbs svg {
  fill: #007993;
  flex-shrink: 0;
  width: 24px;
}
.breadcrumbs.dark svg {
  fill: #00CCCC;
}

footer {
  background-color: white;
  padding: 30px 0;
}
footer .footer-container {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 1fr auto;
}
footer .footer-container .footer-social-links {
  display: flex;
  justify-content: flex-end;
  gap: 15px;
}
@media (min-width: 431px) {
  footer .footer-container .footer-social-links {
    grid-column: 2;
  }
}
@media (max-width: 430px) {
  footer .footer-container .footer-social-links {
    grid-column: 1;
    justify-content: flex-start;
  }
}
footer .footer-container .footer-social-links .social-link {
  outline: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
footer .footer-container .footer-social-links .social-link svg {
  fill: #000028;
  width: 20px;
}
footer .footer-container .footer-social-links .social-link:hover svg {
  fill: #00646E;
}
footer .footer-container .footer-social-links .social-link:focus-visible svg {
  outline: 1px solid #1491EB;
  outline-offset: 1px;
}
footer .footer-container .footer-contact {
  align-items: flex-start;
  display: flex;
  justify-content: flex-end;
}
@media (min-width: 431px) {
  footer .footer-container .footer-contact {
    grid-column: 2;
  }
}
@media (max-width: 430px) {
  footer .footer-container .footer-contact {
    grid-column: 1;
    justify-content: flex-start;
  }
}
footer .footer-container .footer-contact .footer-contact-button {
  align-items: center;
  background-color: #000028;
  color: white;
  cursor: pointer;
  display: flex;
  font-weight: 700;
  gap: 10px;
  outline: none;
  padding: 10px 25px;
  text-decoration: none;
  transition: background-color 0.3s ease-in-out;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
footer .footer-container .footer-contact .footer-contact-button:hover {
  background-color: #00646E;
}
footer .footer-container .footer-contact .footer-contact-button:focus-visible {
  outline: 1px solid #1491EB;
  outline-offset: 2px;
}
footer .footer-container .footer-contact .footer-contact-button svg {
  fill: white;
  width: 20px;
}
footer .footer-container .footer-links {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}
@media (min-width: 990px) {
  footer .footer-container .footer-links.footer-legal {
    grid-row: 2;
  }
}
@media (min-width: 431px) and (max-width: 989px) {
  footer .footer-container .footer-links.footer-legal {
    grid-column: 1/span 2;
    grid-row: 3;
  }
}
@media (max-width: 430px) {
  footer .footer-container .footer-links.footer-legal {
    align-items: flex-start;
    flex-direction: column;
    grid-column: 1;
  }
}
@media (min-width: 431px) {
  footer .footer-container .footer-links.footer-global-website {
    grid-row: 1;
  }
}
@media (max-width: 430px) {
  footer .footer-container .footer-links.footer-global-website {
    display: none;
  }
}
@media (min-width: 990px) {
  footer .footer-container .footer-links.footer-siemens {
    display: none;
  }
}
@media (min-width: 431px) and (max-width: 989px) {
  footer .footer-container .footer-links.footer-siemens {
    grid-row: 2;
  }
}
@media (max-width: 430px) {
  footer .footer-container .footer-links.footer-siemens {
    display: none;
  }
}
footer .footer-container .footer-links .footer-link {
  color: #000028;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  outline: none;
  text-decoration-color: transparent;
  text-underline-offset: 4px;
  text-underline-position: under;
  transition: text-decoration-color 0.3s ease-in-out;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
footer .footer-container .footer-links .footer-link:hover {
  text-decoration-color: #000028;
}
footer .footer-container .footer-links .footer-link:focus-visible {
  outline: 1px solid #1491EB;
  outline-offset: 2px;
}
footer .footer-container .footer-links .footer-link.no-link {
  cursor: default;
  text-decoration-color: transparent;
}
@media (min-width: 431px) {
  footer .footer-container .footer-links .footer-link.footer-global-website-link {
    display: none;
  }
}
@media (min-width: 431px) and (max-width: 989px) {
  footer .footer-container .footer-links .footer-link.footer-siemens-link {
    display: none;
  }
}
footer.dark {
  background-color: #000028;
}
footer.dark .footer-container .footer-social-links .social-link svg {
  fill: white;
}
footer.dark .footer-container .footer-social-links .social-link:hover svg {
  fill: #00FFB9;
}
footer.dark .footer-container .footer-contact .footer-contact-button {
  background-color: white;
  color: #000028;
}
footer.dark .footer-container .footer-contact .footer-contact-button:hover {
  background-color: #00FFB9;
}
footer.dark .footer-container .footer-contact .footer-contact-button svg {
  fill: #000028;
}
footer.dark .footer-container .footer-links .footer-link {
  color: white;
}
footer.dark .footer-container .footer-links .footer-link:hover {
  text-decoration-color: white;
}

header {
  color: #000028;
  pointer-events: none;
  position: absolute;
  width: 100%;
  z-index: 11;
}
header .main-row-container {
  pointer-events: auto;
  position: relative;
  z-index: 1;
}
header .main-row-container .main-row {
  align-items: center;
  display: flex;
  gap: 16px;
  padding: 32px 0 56px 0;
}
header .main-row-container .main-row .skip-button {
  background: linear-gradient(to right, #00FFB9, #00E6DC);
  color: #000028;
  cursor: default;
  font-size: 16px;
  font-weight: 700;
  left: 50%;
  outline: 1px solid #1491EB;
  outline-offset: 1px;
  padding: 10px 25px;
  position: absolute;
  top: 10px;
  transform: translate(-50%, -100px);
  user-select: none;
  white-space: nowrap;
  z-index: 1;
}
header .main-row-container .main-row .skip-button:focus {
  transform: translate(-50%, 0);
}
header .main-row-container .main-row .siemens-logo {
  fill: #000028;
  height: 20px;
  transition: fill 0.3s ease-in-out;
  user-select: none;
}
@media (max-width: 350px) {
  header .main-row-container .main-row .siemens-logo {
    display: none;
  }
}
header .main-row-container .main-row .header-title {
  font-size: 16px;
  line-height: 24px;
  transition: color 0.3s ease-in-out;
  user-select: none;
}
@media (max-width: 432px) {
  header .main-row-container .main-row .header-title {
    display: none;
  }
}
header .main-row-container .main-row .header-buttons {
  align-items: center;
  display: flex;
  flex-grow: 1;
  gap: 16px;
  justify-content: flex-end;
}
header .main-row-container .main-row .header-buttons .mega-menu-button {
  display: flex;
}
@media (min-width: 768px) {
  header .main-row-container .main-row .header-buttons .mega-menu-button {
    display: none;
  }
}
header .addtitional-rows-container {
  pointer-events: auto;
  transition: transform 0.5s ease-in-out;
  will-change: transform;
}
@media (max-width: 767px) {
  header .addtitional-rows-container {
    transform: translateY(-28px);
  }
  header .addtitional-rows-container .secondary-row-container .content-container .secondary-row {
    opacity: 0 !important;
  }
}
header .addtitional-rows-container .secondary-row-container .content-container .secondary-row {
  display: flex;
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
  will-change: opacity;
}
header.dark {
  color: white;
}
header.dark .main-row-container .main-row .siemens-logo {
  fill: white;
}

.header-key-visual {
  animation: header-key-visual-appear 0.6s ease-in-out forwards;
  background-position: center;
  background-size: cover;
  position: absolute;
  width: 100%;
}
@keyframes header-key-visual-appear {
  0% {
    opacity: 0;
    transform: translateY(40px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.header-key-visual.hidden {
  display: none;
}

.header-mega-menu {
  height: 100vh;
  left: 0;
  position: fixed;
  right: 0;
  z-index: 12;
}
@media (min-width: 768px) {
  .header-mega-menu {
    display: none;
  }
}
.header-mega-menu .header-mega-menu-background {
  background-color: #F3F3F0;
  border-bottom-left-radius: 100%;
  position: absolute;
  right: 0;
  top: 0;
}
.header-mega-menu .header-mega-menu-content {
  margin: 56px auto 0;
  max-width: 400px;
  position: relative;
}
.header-mega-menu .header-mega-menu-content .header-mega-menu-header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0 16px 32px;
}
.header-mega-menu .header-mega-menu-content .header-mega-menu-header .header-mega-menu-header-title {
  font-size: 40px;
  line-height: 56px;
}
.header-mega-menu.opening .header-mega-menu-background {
  animation: header-mega-menu-background-open 0.5s ease-in-out forwards;
}
@keyframes header-mega-menu-background-open {
  0% {
    height: 0px;
    width: 0px;
  }
  100% {
    height: max(300vh, 300vw);
    width: max(300vh, 300vw);
  }
}
.header-mega-menu.opening .header-mega-menu-content {
  animation: header-mega-menu-content-open 0.5s ease-in-out forwards;
}
@keyframes header-mega-menu-content-open {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.header-mega-menu.closing .header-mega-menu-background {
  animation: header-mega-menu-background-close 0.4s ease-in-out forwards;
}
@keyframes header-mega-menu-background-close {
  0% {
    height: max(300vh, 300vw);
    width: max(300vh, 300vw);
  }
  100% {
    height: 0px;
    width: 0px;
  }
}
.header-mega-menu.closing .header-mega-menu-content {
  animation: header-mega-menu-content-close 0.4s ease-in-out forwards;
}
@keyframes header-mega-menu-content-close {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.header-mega-menu.closed {
  display: none;
}
.header-mega-menu.open .header-mega-menu-background {
  height: max(300vh, 300vw);
  width: max(300vh, 300vw);
}
.header-mega-menu.dark .header-mega-menu-background {
  background-color: #23233C;
}

.header-mega-menu-button {
  color: #000028;
  cursor: pointer;
  font-size: 18px;
  font-weight: 900;
  line-height: 26px;
  margin: 0 16px;
  outline: none;
  padding: 12px 16px;
  transition: background-color 0.2s ease-in-out;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.header-mega-menu-button:hover {
  background-color: #DFDFD9;
}
.header-mega-menu-button:focus-visible {
  outline: 1px solid #1491EB;
}
.header-mega-menu-button.dark {
  color: white;
}
.header-mega-menu-button.dark:hover {
  background-color: #37374D;
}

.header-tabs {
  display: flex;
  position: relative;
  gap: 32px;
}
.header-tabs .tab {
  cursor: pointer;
  font-size: 18px;
  font-weight: 900;
  line-height: 26px;
  outline: none;
  padding-bottom: 2px;
  transition: color 0.3s ease-in-out;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.header-tabs .tab:focus-visible {
  outline: 1px solid #1491EB;
  outline-offset: -1px;
}
.header-tabs .header-tab-underline {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: -1;
  width: 0;
  border-bottom: 2px solid #009999;
  transition: color 0.3s ease-in-out, left 0.5s ease-in-out, width 0.5s ease-in-out;
}
.header-tabs.dark .header-tab-underline {
  border-color: #00FFB9;
}

body {
  font-family: SiemensSans;
  margin: 0;
  overflow-x: hidden;
}
body .layout {
  background-color: white;
  color: #000028;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-height: 100dvh;
}
body .layout main {
  flex-grow: 1;
  outline: none;
  padding-top: 140px;
  position: relative;
  transition: padding 0.5s ease-in-out;
}
@media (max-width: 767px) {
  body .layout main {
    padding-top: 112px;
  }
}
body .layout svg {
  fill: #000028;
}
body .layout.dark {
  background-color: #000028;
  color: white;
}

.snackbars {
  display: flex;
  flex-direction: column;
  gap: 8px;
  pointer-events: none;
  position: fixed;
  right: 100px;
  top: 64px;
  width: 368px;
  z-index: 30;
}
@media (max-width: 478px) {
  .snackbars {
    left: 10px;
    right: 10px;
    width: auto;
  }
}

