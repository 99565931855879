.card {
    .card-image-container {
        display: flex;
        justify-content: center;
    }

    .card-description {
        font-size: 18px;
        font-weight: 700;
        padding-top: 24px;
    }

    &.interactive {
        .card-title {
            font-size: 48px;
            font-weight: 900;
            line-height: 60px;
            padding-top: 55px;
        }

        .card-description {
            line-height: 28px;
            margin-bottom: 0;
            padding-top: 18px;
        }

        .card-button-container {
            padding-top: 32px;
        }
    }
}