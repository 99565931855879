.header-mega-menu-button {
    color: $deep-blue;
    cursor: pointer;
    font-size: 18px;
    font-weight: 900;
    line-height: 26px;
    margin: 0 16px;
    outline: none;
    padding: 12px 16px;
    transition: background-color 0.2s ease-in-out;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    &:hover {
        background-color: $bright-sand;
    }

    &:focus-visible {
        outline: 1px solid $focus-blue;
    }

    &.dark {
        color: white;

        &:hover {
            background-color: $deep-blue-80;
        }
    }
}
