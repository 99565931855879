.search {
    height: 100%;
    position: relative;

    .search-input-field {
        input {
            background-color: $interactive-cyan-8;
            border: none;
            border-bottom: 1px solid $deep-blue-70;
            border-radius: 0;
            box-sizing: border-box;
            color: $deep-blue-70;
            font: inherit;
            margin: 0;
            outline: none;
            padding: 16px 14px 6px;
            transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out, border 0.3s ease-in-out;
            width: 100%;
            -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

            &::placeholder {
                color: $deep-blue-70;
                transition: color 0.3s ease-in-out;
                user-select: none;
            }

            &:focus, &:hover:not(:disabled) {
                background-color: $bold-green-18;
                border-color: $green;
                color: $deep-blue;

                &::placeholder {
                    color: $deep-blue;
                }

                & ~ .search-icon::before {
                    opacity: 1;
                }
            }

            &:disabled {
                background-color: $interactive-cyan-8;
                border-color: transparent;
                color: $deep-blue-50;
                cursor: not-allowed;
                -webkit-text-fill-color: $deep-blue-50;
            }

            &:focus + .search-label {
                bottom: 24px;
                color: $deep-blue;
                font-size: 10px;
            }

            &:hover:not(:disabled) + .search-label {
                color: $deep-blue;
            }

            &:disabled + .search-label {
                color: $deep-blue-50;
            }
        }

        .search-label {
            align-items: center;
            bottom: 2px;
            color: $deep-blue-70;
            display: flex;
            font-size: 16px;
            margin-left: 14px;
            pointer-events: none;
            position: absolute;
            top: 2px;
            transition: color 0.3s ease-in-out, font-size 0.15s ease-in-out, bottom 0.15s ease-in-out;
            user-select: none;

            &.filled {
                bottom: 24px;
                font-size: 10px;
            }

            &.required::before {
                content: "*";
                position: absolute;
                right: calc(100% + 2px);
            }
        }

        .search-icon {
            align-items: center;
            bottom: 1px;
            cursor: pointer;
            display: flex;
            padding: 0 11px;
            pointer-events: none;
            position: absolute;
            top: 0;
            z-index: 1;
            -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

            svg {
                fill: $deep-blue;
                width: 20px;
            }

            &::before {
                bottom: 0;
                content: "";
                left: 0;
                opacity: 0;
                position: absolute;
                right: 0;
                top: 0;
                transition: opacity 0.3s ease-in-out;
                z-index: -1;
            }
        }

        &.search-input-field-icon-left {
            input {
                padding-left: 37px;
            }

            .search-label {
                margin-left: 37px;
            }

            .search-icon {
                left: 0;
            }
        }

        &.search-input-field-icon-right {
            input {
                padding-right: 37px;
            }

            .search-icon {
                right: 0;
            }
        }

        &.search-input-field-icon-highlighted {
            .search-icon {
                background: linear-gradient($bold-green, $bold-blue);

                &::before {
                    background-color: $light-green;
                }
            }

            &.search-input-field-icon-left {
                input {
                    padding-left: 56px;
                }

                .search-label {
                    margin-left: 56px;
                }
            }

            &.search-input-field-icon-right input {
                padding-right: 56px;
            }
        }
    }

    .search-results {
        background-color: $interactive-cyan-8;
        max-height: 0;
        overflow-y: auto;
        position: absolute;
        top: 100%;
        transition: max-height 0.5s ease-in-out, background-color 0.5s ease-in-out;
        width: 100%;
        z-index: 2;

        &::-webkit-scrollbar {
            width: 6px;
        }

        &::-webkit-scrollbar-track {
            background-color: inherit;
        }

        &::-webkit-scrollbar-thumb {
            background-color: $interactive-cyan-10;
        }

        .search-result {
            cursor: pointer;
            overflow: hidden;
            padding: 5px 14px;
            position: relative;
            text-overflow: ellipsis;
            user-select: none;
            white-space: nowrap;
            z-index: 1;
        }
    }

    &.open {
        .search-results {
            &.search-results-1 {
                max-height: 29px;
            }

            &.search-results-2 {
                max-height: 58px;
            }

            &.search-results-3 {
                max-height: 87px;
            }

            &.search-results-4 {
                max-height: 116px;
            }

            &.search-results-5 {
                max-height: 145px;
            }
        }
    }

    &.open, &:hover {
        .search-results {
            background-color: $bold-green-18;

            &::-webkit-scrollbar-thumb {
                background-color: $light-green-70;
            }

            &::-webkit-scrollbar-thumb:hover {
                background-color: $light-green-50;
            }

            .search-result.selected {
                background-color: $light-green;
            }
        }
    }

    &.condensed .search-input-field {
        input {
            padding: 6px 14px;
        }

        .search-icon {
            padding: 0 6px;
        }

        &.search-input-field-icon-left {
            input {
                padding-left: 33px;
            }

            .search-label {
                margin-left: 33px;
            }
        }

        &.search-input-field-icon-right input {
            padding-right: 33px;
        }

        &.search-input-field-icon-highlighted {
            &.search-input-field-icon-left {
                input {
                    padding-left: 39px;
                }

                .search-label {
                    margin-left: 39px;
                }
            }

            &.search-input-field-icon-right input {
                padding-right: 39px;
            }
        }
    }

    &.error:not(.disabled):not(:focus-within) {
        .search-input-field {
            input {
                background-color: $light-red;
                border-color: $feedback-red-dark;
                color: $deep-blue;

                &::placeholder {
                    color: $deep-blue;
                }
            }

            .search-label {
                color: $feedback-red-dark !important;
            }
        }

        .search-results {
            background-color: $light-red;

            &::-webkit-scrollbar-thumb {
                background-color: $feedback-red-dark-40;
            }

            .search-result.selected {
                background-color: inherit !important;
            }
        }
    }

    &.dark {
        .search-input-field {
            input {
                background-color: $interactive-coral-12-db;
                border-color: $deep-blue-50;
                color: $deep-blue-8;

                &::placeholder {
                    color: $deep-blue-50;
                }

                &:focus, &:hover:not(:disabled) {
                    background-color: $bold-green-12-db;
                    border-color: $bold-green;
                    color: $deep-blue-8;

                    &::placeholder {
                        color: $deep-blue-8;
                    }
                }

                &:disabled {
                    background-color: $interactive-coral-12-db;
                    border-color: transparent;
                    color: $deep-blue-60;
                    -webkit-text-fill-color: $deep-blue-60;
                }

                &:hover:not(:disabled) + .search-label {
                    color: $deep-blue-8;
                }

                &:hover:not(:disabled) + .search-label.filled, &:focus + .search-label, &:focus:hover + .search-label {
                    color: $bold-green;
                }

                &:disabled + .search-label {
                    color: $deep-blue-60;
                }
            }

            .search-label {
                color: $deep-blue-50;
            }

            .search-icon svg {
                fill: white
            }

            &.search-input-field-icon-highlighted .search-icon {
                svg {
                    fill: $deep-blue;
                }

                &::before {
                    background-color: $light-green-40;
                }
            }
        }

        .search-results {
            background-color: $interactive-coral-12-db;

            &::-webkit-scrollbar-thumb {
                background-color: $deep-blue-80;
            }

            &::-webkit-scrollbar-thumb:hover {
                background-color: $deep-blue-60;
            }
        }

        &.open, &:hover {
            .search-results {
                background-color: $bold-green-12-db;

                .search-result.selected {
                    background-color: $teal;
                }
            }
        }

        &.error:not(.disabled):not(:focus-within) {
            .search-input-field {
                input {
                    background-color: $dark-red;
                    border-color: $medium-red;
                    color: $deep-blue-8;

                    &::placeholder {
                        color: $deep-blue-8;
                    }
                }

                .search-label {
                    color: $medium-red !important;
                }
            }

            .search-results {
                background-color: $dark-red;

                &::-webkit-scrollbar-thumb {
                    background-color: $dark-red-92;
                }
            }
        }
    }
}
