body {
    font-family: SiemensSans;
    margin: 0;
    overflow-x: hidden;

    .layout {
        background-color: white;
        color: $deep-blue;
        display: flex;
        flex-direction: column;
        min-height: 100vh;
        min-height: 100dvh;

        main {
            flex-grow: 1;
            outline: none;
            padding-top: 140px;
            position: relative;
            transition: padding 0.5s ease-in-out;

            @media (max-width: $hide-tabs-max) {
                padding-top: 112px;
            }
        }

        svg {
            fill: $deep-blue;
        }

        &.dark {
            background-color: $deep-blue;
            color: white;
        }
    }
}
