.breadcrumb {
    border-radius: 4px;
    color: $interactive-blue;
    cursor: pointer;
    outline: none;
    padding: 8px;
    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
    user-select: none;

    &.disabled {
        color: $deep-blue-50;
        cursor: default;
    }

    &:not(.disabled):hover {
        background-color: $bold-green-20;
        color: $teal;
    }

    &:focus-visible {
        outline: 1px $focus-blue solid;
        outline-offset: 1px;
    }

    &.dark {
        color: $interactive-coral;

        &.disabled {
            color: $deep-blue-30;
        }

        &:not(.disabled):hover {
            background-color: $bold-green-12-db;
            color: $bold-green;
        }
    }
}