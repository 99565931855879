﻿.tile {
    background-color: $light-sand;
    border-radius: 4px;
    padding: 16px 16px 10px;

    .tile-label {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 6px;
    }

    .tile-value {
        font-size: 40px;
        font-weight: 900;
        line-height: 56px;
        margin-left: -2px;

        &.color-blue {
            color: $feedback-blue-light;
        }

        &.color-green {
            color: $feedback-green-light;
        }

        &.color-red {
            color: $feedback-red-light;
        }

        &.color-yellow {
            color: $feedback-yellow-light;
        }
    }

    &.dark {
        background-color: $deep-blue-90;

        .tile-value {
            &.color-blue {
                color: $feedback-blue-dark;
            }

            &.color-green {
                color: $feedback-green-dark;
            }

            &.color-red {
                color: $feedback-red-dark;
            }

            &.color-yellow {
                color: $feedback-yellow-dark;
            }
        }
    }
}
