﻿.snackbar {
    pointer-events: all;
    position: relative;

    .snackbar-container {
        background-color: white;
        border-radius: 4px;
        bottom: 0;
        box-shadow: 0 2px 5px 0 rgba($deep-blue, 0.25);
        color: $deep-blue;
        display: grid;
        gap: 8px;
        grid-template-columns: 4px 1fr;
        left: 0;
        overflow: hidden;
        position: absolute;
        right: 0;

        .snackbar-highlight {
            align-self: stretch;
        }

        .snackbar-content {
            align-items: start;
            display: grid;
            gap: 8px;
            grid-template-columns: auto 1fr auto;
            padding: 8px 8px 8px 0;

            .status-icon svg {
                width: 24px;
            }

            .snackbar-text-container {
                display: flex;
                flex-direction: column;

                .snackbar-title {
                    font-size: 16px;
                    font-weight: 900;
                    line-height: 24px;
                }

                .snackbar-description {
                    font-size: 16px;
                    line-height: 24px;
                }
            }
        }
    }

    &.closed {
        left: 425px;
        opacity: 0;
    }

    &.opening {
        left: 0;
        opacity: 1;
        transition: height 0.3s ease-out, left 0.3s ease-in-out 0.3s, opacity 0.3s ease-in 0.3s;
    }

    &.closing {
        left: 425px;
        opacity: 0;
        transition: height 0.3s ease-out 0.3s, left 0.3s ease-in-out, opacity 0.3s ease-in;
    }

    &.error .snackbar-container .snackbar-highlight {
        background-color: $feedback-red-light;
    }

    &.info .snackbar-container .snackbar-highlight {
        background-color: $feedback-blue-light;
    }

    &.success .snackbar-container .snackbar-highlight {
        background-color: $feedback-green-light;
    }

    &.warning .snackbar-container .snackbar-highlight {
        background-color: $feedback-orange-light;
    }

    &.dark {
        .snackbar-container {
            background-color: $deep-blue-90;
            color: white;
        }

        &.error .snackbar-container .snackbar-highlight {
            background-color: $feedback-red-dark;
        }

        &.info .snackbar-container .snackbar-highlight {
            background-color: $feedback-blue-dark;
        }

        &.success .snackbar-container .snackbar-highlight {
            background-color: $feedback-green-dark;
        }

        &.warning .snackbar-container .snackbar-highlight {
            background-color: $feedback-orange-dark;
        }
    }
}
