.image-dropdown {
    background-color: $interactive-cyan-8;
    border-bottom: 1px solid $deep-blue-30;
    cursor: pointer;
    height: 68px;
    outline: none;
    position: relative;
    transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out, border 0.3s ease-in-out;
    width: 104px;

    .image-dropdown-opener {
        align-items: center;
        display: grid;
        grid-template-columns: 1fr auto;

        .image-dropdown-arrow {
            bottom: 0;
            display: flex;
            position: absolute;
            right: 0;

            svg {
                fill: $deep-blue-70;
                transition: fill 0.3s ease-in-out, transform 0.5s ease-in-out;
                width: 20px;
            }
        }

        .image-dropdown-value {
            align-items: center;
            cursor: pointer;
            display: flex;
            transition: background-color 0.3s ease-in-out;

            img, svg {
                height: 68px;
            }
        }

        &:hover:not(.disabled) {
            background-color: $bold-green-18;
            border-color: $green;

            .image-dropdown-arrow svg {
                fill: $deep-blue;
            }
        }
    }

    .image-dropdown-values {
        background-color: $bold-green-18;
        max-height: 0;
        overflow-y: auto;
        position: absolute;
        top: calc(100% + 1px);
        transition: max-height 0.5s ease-in-out;
        z-index: 2;

        &::-webkit-scrollbar {
            width: 6px;
        }

        &::-webkit-scrollbar-track {
            background-color: inherit;
        }

        &::-webkit-scrollbar-thumb {
            background-color: $light-green-70;
        }

        &::-webkit-scrollbar-thumb:hover {
            background-color: $light-green-50;
        }

        .image-dropdown-value {
            align-items: center;
            cursor: pointer;
            display: grid;
            grid-template-columns: minmax(60px, 100px) 1fr;
            overflow: hidden;
            position: relative;

            &.selected:not(.disabled) {
                background-color: $light-green;
            }

            &.disabled {
                background-color: $interactive-cyan-8;
                border-color: transparent;
                color: $deep-blue-60;
                cursor: not-allowed;
            }

            img, svg  {
                height: 88px;
                width: 100%;

                @media(max-width: calc(480px - 1px)) {
                    height: 110px;
                }
            }

            .image-dropdown-value-text-container {
                height: 68px;
                padding: 16px 16px 4px 10px;

                @media(max-width: calc(480px - 1px)) {
                    height: 110px;
                }

                .image-dropdown-value-title {
                    font-weight: 700;
                }

                .image-dropdown-value-description {
                    font-size: 10px;
                    padding-top: 8px;
                }
            }
        }
    }

    &.disabled {
        background-color: $interactive-cyan-8;
        border-color: transparent;
        color: $deep-blue-50;
        cursor: not-allowed;

        .image-dropdown-value {
            cursor: not-allowed;
        }
    }

    &.open {
        border-color: $green;

        .image-dropdown-arrow svg {
            fill: $deep-blue;
        }

        .image-dropdown-value {
            background-color: $bold-green-18;
        }

        .image-dropdown-values {
            background-color: $bold-green-18;

            &.image-dropdown-values-1 {
                max-height: 88px;
            }

            &.image-dropdown-values-2 {
                max-height: 176px;
            }

            &.image-dropdown-values-3 {
                max-height: 264px;
            }
        }

        .image-dropdown-opener .image-dropdown-arrow svg {
            transform: rotate(-180deg);
        }
    }

    &.dark {
        .image-dropdown-value {
            background-color: $interactive-coral-12-db;
        }

        .image-dropdown-opener .image-dropdown-arrow svg {
            fill: $deep-blue-50;
        }

        .image-dropdown-value-text-container {
            color: white
        }

        &:hover:not(.disabled) {
            background-color: $bold-green-12-db;
            border-bottom: 1px solid $bold-green;

            .image-dropdown-arrow svg {
                fill: $bold-green;
            }
        }

        &.disabled {
            background-color: $interactive-coral-12-db;
            border-color: transparent;
            color: $deep-blue-50;
            cursor: not-allowed;

            .image-dropdown-opener {
                .image-dropdown-arrow svg {
                    fill: $deep-blue-60;
                }

                .image-dropdown-value {
                    background-color: $interactive-coral-12-db;
                }
            }
        }

        &.open .image-dropdown-arrow svg {
            fill: $bold-green;
        }

        .image-dropdown-values {
            &::-webkit-scrollbar-thumb {
                background-color: $deep-blue-80;
            }

            &::-webkit-scrollbar-track {
                background-color: $interactive-coral-12-db;
            }

            &::-webkit-scrollbar-thumb:hover {
                background-color: $deep-blue-60;
            }

            .image-dropdown-value {
                background-color: $interactive-coral-12-db;

                &.selected:not(.disabled) {
                    background-color: $teal;
                }

                &.disabled {
                    background-color: $bold-green-12-db;
                    border-color: transparent;
                    color: $deep-blue-60;
                    cursor: not-allowed;

                    .image-dropdown-value-text-container {
                        color: $deep-blue-60
                    }
                }
            }
        }
    }
}
