.sina-save-image {
    display: flex;
    height: 68px;

    path, polygon, rect {
        fill: #000;
    }

    &.big {
        height: 88px;
        width: 100%;
    }

    &.dark {
        path, polygon, rect {
            fill: #fff;
        }
    }

    &.disabled {
        filter: invert(50%);
        cursor: not-allowed;
    }
}