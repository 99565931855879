.simple-accordion {
    display: grid;
    transition: grid-template-rows 0.5s ease-out;

    .simple-accordion-container {
        overflow: hidden;
        grid-row: 1 / span 2;
    }

    &.closed {
        grid-template-rows: 0fr;
    }

    &.open {
        grid-template-rows: 1fr;
    }
}
