﻿.sticky {
    position: sticky;
    z-index: 3;

    &.stuck {
        background-color: white;
    }

    &.dark.stuck {
        background-color: $deep-blue;
    }
}
