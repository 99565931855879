.header-tabs {
    display: flex;
    position: relative;
    gap: 32px;

    .tab {
        cursor: pointer;
        font-size: 18px;
        font-weight: 900;
        line-height: 26px;
        outline: none;
        padding-bottom: 2px;
        transition: color 0.3s ease-in-out;
        user-select: none;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

        &:focus-visible {
            outline: 1px solid $focus-blue;
            outline-offset: -1px;
        }
    }

    .header-tab-underline {
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: -1;
        width: 0;
        border-bottom: 2px solid $siemens-petrol;
        transition: color 0.3s ease-in-out, left 0.5s ease-in-out, width 0.5s ease-in-out;
    }

    &.dark .header-tab-underline {
        border-color: $bold-green;
    }
}
