.snackbars {
    display: flex;
    flex-direction: column;
    gap: 8px;
    pointer-events: none;
    position: fixed;
    right: 100px;
    top: 64px;
    width: 368px;
    z-index: 30;

    @media (max-width: 478px) {
        left: 10px;
        right: 10px;
        width: auto;
    }
}
