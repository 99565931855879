﻿.box {
    border-radius: 4px;
    min-width: 0;
    padding: 24px 24px 32px;

    &:not(.colored-background).box-layer-1 {
        background-color: $light-sand;
    }

    &:not(.colored-background).box-layer-2 {
        background-color: $bright-sand;
    }

    &.background-color-green {
        background-color: $bold-green-60;
    }

    &.background-color-yellow {
        background-color: $highlight-yellow;
    }

    &.box-small-padding {
        padding: 16px 16px 32px;
    }

    &.colored-edge {
        border-left: 4px solid;

        &.edge-color-reference {
            border-color: $reference-system;
        }

        &.edge-color-alternative {
            border-color: $alternative-system;
        }
    }

    &.dark {
        &:not(.colored-background).box-layer-1 {
            background-color: $deep-blue-90;
        }

        &:not(.colored-background).box-layer-2 {
            background-color: $deep-blue-80;
        }

        &.colored-edge {
            &.edge-color-reference {
                border-color: $reference-system-dark;
            }

            &.edge-color-alternative {
                border-color: $alternative-system-dark;
            }
        }
    }
}
