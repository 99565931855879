.systems-view {
    display: grid;
    column-gap: 40px;
    row-gap: 24px;
    margin-top: 32px;
    grid-template-columns: 1fr;
    margin-bottom: 32px;
    align-items: start;

    .systems-comparison-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        row-gap: 16px;
        column-gap: 16px;

        @media (max-width: $systems-small-max) {
            grid-template-columns: 1fr;
            column-gap: initial;
        }

        .default-box {
            grid-column: span 2;

            @media (max-width: $systems-small-max) {
                grid-column: span 1;
            }
        }

        .bottom-box {
            grid-column: span 2;
            
            .info-button-mobile {
                display: none;
            }

            @media (max-width: $systems-small-max) {
                grid-column: span 1;

                .system-circuit:first-child, .bottom-box-select {
                    grid-column: span 2;
                }

                .system-circuit:nth-child(2), .info-button-desktop {
                    display: none;
                }

                .info-button-mobile {
                    display: inline-flex;
                }
            }
        }

        &.commercial-view {
            align-items: stretch;
            grid-auto-flow: column;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(3, auto);

            @media (max-width: $systems-small-max) {
                grid-template-columns: 1fr;
                grid-template-rows: repeat(6, auto);
            }
        }
    }

    .savings-box {
        min-width: 500px;

        @media (max-width: $systems-small-max) {
            min-width: 200px
        }
    }

    &.fullscreen .systems-comparison-grid {
        display: none;
    }

    &:not(.fullscreen) {
        @media (min-width: $systems-full-min) {
            grid-template-columns: 2fr 1fr;
        }
    }

    .select {
        min-width: 125px;
    }
}
