﻿.banner {
    display: grid;

    .banner-container {
        background-color: white;
        box-shadow: 0 2px 5px 0 rgba($deep-blue, 0.25);
        color: $deep-blue;
        display: grid;
        gap: 8px;
        grid-row: 1 / span 2;
        grid-template-columns: 4px 1fr;
        overflow: hidden;

        .banner-highlight {
            align-self: stretch;
        }

        .banner-content {
            align-items: start;
            display: grid;
            gap: 8px;
            grid-template-columns: auto 1fr;
            padding: 8px 8px 8px 0;

            .status-icon svg {
                width: 24px;
            }

            .banner-text-container {
                display: flex;
                flex-direction: column;

                .banner-title {
                    font-size: 16px;
                    font-weight: 900;
                    line-height: 24px;
                }

                .banner-description {
                    font-size: 16px;
                    line-height: 24px;
                }
            }

            .banner-action-container {
                align-items: center;
                align-self: stretch;
                display: flex;
            }

            &.with-actions {
                grid-template-columns: auto 1fr auto;
            }
        }

        &.round {
            border-radius: 4px;
        }
    }

    &.closed {
        grid-template-rows: 0fr;
        opacity: 0;
        transition: grid-template-rows 0.3s ease-out 0.3s, opacity 0.3s ease-in;
    }

    &.open {
        grid-template-rows: 1fr;
        opacity: 1;
        transition: grid-template-rows 0.3s ease-out, opacity 0.3s ease-in 0.3s;
    }

    &.error .banner-container .banner-highlight {
        background-color: $feedback-red-light;
    }

    &.info .banner-container .banner-highlight {
        background-color: $feedback-blue-light;
    }

    &.success .banner-container .banner-highlight {
        background-color: $feedback-green-light;
    }

    &.warning .banner-container .banner-highlight {
        background-color: $feedback-orange-light;
    }

    &.dark {
        .banner-container {
            background-color: $deep-blue-90;
            color: white;
        }

        &.error .banner-container .banner-highlight {
            background-color: $feedback-red-dark;
        }

        &.info .banner-container .banner-highlight {
            background-color: $feedback-blue-dark;
        }

        &.success .banner-container .banner-highlight {
            background-color: $feedback-green-dark;
        }

        &.warning .banner-container .banner-highlight {
            background-color: $feedback-orange-dark;
        }
    }
}
