﻿.colorful-bar {
    border-radius: 2px;
    border: 2px solid;

    &.colorful-bar-alternative {
        border-color: $alternative-system;
    }

    &.colorful-bar-reference {
        border-color: $reference-system;
    }

    &.dark {
        &.colorful-bar-alternative {
            border-color: $alternative-system-dark;
        }

        &.colorful-bar-reference {
            border-color: $reference-system-dark;
        }
    }
}
