.box-tab-switcher {
    border-radius: 4px 4px 0px 0px;
    background-color: $secondary-deep-blue-10;
    display: flex;
    flex-direction: column;
    position: relative;
    font-size: 20px;
    font-weight: 900;
    line-height: 26px;
    margin: -24px -24px 0px -24px;

    .box-tab-switcher-selected-item {
        align-self: stretch;
        border-radius: 4px 4px 0px 0px;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        gap: 6px;
        overflow: hidden;
        padding: 12px;
        position: relative;
        text-overflow: ellipsis;
        user-select: none;
        white-space: nowrap;

        .box-tab-switcher-selected-item-content {
            display: flex;
            flex-direction: row;
            gap: 6px;
            align-items: center;
            min-width: 0;

            .box-tab-switcher-selected-item-label {
                min-width: 0;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            .box-tab-switcher-selected-item-icon {
                flex-shrink: 0;
                width: 32px;
                height: 32px;
            }
        }

        .box-tab-switcher-selected-item-arrow {
            align-items: center;
            display: flex;
            flex-shrink: 0;

            svg {
                fill: $deep-blue-90;
                transition: fill 0.3s ease-in-out, transform 0.3s ease-in-out;
                width: 24px;
                height: 24px;
            }
        }
    }

    .box-tab-switcher-dropdown-container {
        left: 0;
        pointer-events: none;
        position: absolute;
        right: 0;
        z-index: 2;

        .box-tab-switcher-dropdown {
            background-color: $deep-blue-8;
            border-radius: 0 0 4px 4px;
            padding: 10px 8px 12px 8px;
            pointer-events: auto;

            &:first-child {
                border-top: 2px solid $light-grey;
                border-radius: 1px;
            }
        }
    }

    &.closing .box-tab-switcher-dropdown-container .box-tab-switcher-dropdown {
        animation: box-tab-switcher-dropdown-close 0.3s cubic-bezier(.75,-.02,.2,.97) forwards;

        @keyframes box-tab-switcher-dropdown-close {
            0% {
                opacity: 1;
                transform: translate(0, 56px);
            }

            100% {
                opacity: 0;
                transform: translate(0, 40px);
            }
        }
    }

    &.closed .box-tab-switcher-dropdown-container .box-tab-switcher-dropdown {
        opacity: 0;
        transform: translate(0, 56px);
        visibility: hidden;
    }

    &.open, &.opening {
        padding-bottom: 0;
        background-color: $deep-blue-8;

        .box-tab-switcher-selected-item {
            padding-bottom: 12px;
        }

        .box-tab-switcher-dropdown-container .box-tab-switcher-dropdown {
            animation: box-tab-switcher-dropdown-open 0.3s cubic-bezier(.75,-.02,.2,.97) forwards;

            @keyframes box-tab-switcher-dropdown-open {
                0% {
                    opacity: 0;
                    transform: translate(0, 40px);
                }

                100% {
                    opacity: 1;
                    transform: translate(0, 56px);
                }
            }
        }

        .box-tab-switcher-selected-item-arrow svg {
            transform: rotate(-180deg);
        }
    }

    &.dark {
        background-color: $deep-blue-80;


        &.open, &.opening {
            background-color: $deep-blue-60;

            .box-tab-switcher-selected-item {
                background-color: $deep-blue-60;
            }
        }

        .box-tab-switcher-selected-item {
            background: $deep-blue-80;

            .box-tab-switcher-selected-item-content .box-tab-switcher-selected-item-icon svg, .box-tab-switcher-selected-item-arrow svg {
                fill: white;
            }
        }

        .box-tab-switcher-dropdown-container .box-tab-switcher-dropdown {
            background-color: $deep-blue-60;

            &:first-child {
                border-color: $deep-blue-50;
            }
        }
    }
}
