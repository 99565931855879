.content-container {
    width: 1350px;
    margin: 0 auto;
    background-color: inherit;

    @media (max-width: 1450px) {
        width: calc(100% - (2 * (17px + (100% * (30 / 1410)))));
        padding: 0 calc(17px + (100% * (30 / 1410)));
    }
}
