﻿.navigation-button-compact {
    background-color: $light-sand;
    cursor: pointer;
    display: grid;
    gap: 4px;
    grid-template-columns: 4px auto;
    outline: none;
    transition: background-color 0.2s ease-in-out;

    .navigation-button-selection {
        transition: background-color 0.2s ease-in-out;

        &.selected {
            background-color: $interactive-cyan;
        }
    }

    .navigation-button-container {
        display: flex;
        gap: 8px;
        padding: 8px 8px 8px 0;

        .navigation-button-icons {
            align-self: start;
            display: flex;

            svg {
                width: 24px;
            }

            .navigation-button-arrow-icon {
                display: flex;
                transition: transform 0.2s ease-in-out;

                &.rotated {
                    transform: rotate(-180deg);
                }
            }
        }

        .navigation-button-content {
            display: flex;
            flex-direction: column;
            font-size: 16px;
            gap: 4px;
            line-height: 24px;
            user-select: none;

            .navigation-button-label {
                font-weight: 700;
            }
        }
    }

    &:hover {
        background-color: $bold-green-18;
    }

    &:focus-visible {
        outline: 1px $focus-blue solid;
        outline-offset: 1px;
    }

    &.dark {
        background-color: $deep-blue-90;

        .navigation-button-selection.selected {
            background-color: $bold-blue;
        }

        .navigation-button-container .navigation-button-icons {
            & > svg {
                fill: white;
            }

            .navigation-button-arrow-icon svg {
                fill: white;
            }
        }

        &:hover {
            background-color: $bold-green-12-db;
        }
    }
}
