.header-key-visual {
    animation: header-key-visual-appear 0.6s ease-in-out forwards;
    background-position: center;
    background-size: cover;
    position: absolute;
    width: 100%;

    @keyframes header-key-visual-appear {
        0% {
            opacity: 0;
            transform: translateY(40px);
        }

        100% {
            opacity: 1;
            transform: translateY(0);
        }
    }

    &.hidden {
        display: none;
    }
}
