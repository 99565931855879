﻿.comparison-card-tab {
    margin: 0 -10px 0 -10px;

    .comparison-card-tab-block {
        padding-top: 24px;

        .system-content {
            width: 100%;
        }
    }

    .checkboxes {
        display: flex;
        flex-direction: row;
        justify-items: left;
        gap: 6px;
        padding: 32px 0 16px 0;

        @media (max-width: $systems-small-max) {
            flex-direction: column;
        }
    }

    .system-content-item-list {
        display: flex;
        flex-direction: column;
        row-gap: 5px;
    }

    .comparison-card-tab-item {
        display: grid;
        grid-template-columns: 3fr 2fr;

        :first-child {
            justify-self:left;
            text-align: left;
        }

        :last-child {
            text-align: right;
        }
    }
}
