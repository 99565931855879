﻿.button-group {
    display: flex;

    .button.secondary {
        &:not(:last-child) {
            &, &:not(.disabled):hover {
                border-right: none;
                border-top-right-radius: 0px;
                border-bottom-right-radius: 0px;
            }

            &:not(.disabled):hover + .button {
                border-left-color: $teal;

                &.dark {
                    border-left-color: $bold-green;
                }
            }
        }

        &:not(:first-child) {
            border-left-color: $interactive-blue;
            border-top-left-radius: 0px;
            border-bottom-left-radius: 0px;

            &.dark {
                border-left-color: $interactive-coral;
            }

            &:not(.disabled):hover {
                border-left-color: $teal;

                &.dark {
                    border-left-color: $bold-green;
                }
            }
        }
    }
}
