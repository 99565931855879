.menu-label-item {
    color: $deep-blue;
    font-size: 16px;
    font-weight: 700;
    line-height: 16px;
    padding: 6px 8px;
    user-select: none;

    &.dark {
        color: white;
    }
}
