﻿.timeline {
    background-color: $light-sand;
    border-radius: 4px;
    grid-column: span 2;
    padding: 16px 16px 24px;

    .timeline-label {
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        margin-bottom: 8px;
    }

    .timeline-container {
        display: grid;
        gap: 8px;
        grid-template: 1fr / 9px auto;

        .timeline-line {
            border-left: 1px dashed $deep-blue;
            justify-self: center;
        }

        .timeline-entries {
            display: flex;
            flex-direction: column;
            gap: 16px;
            margin-top: 5px;

            .timeline-entry {
                .timeline-entry-date {
                    color: $deep-blue-30;
                    font-size: 12px;
                    line-height: 16px;
                    margin-bottom: 2px;
                    position: relative;

                    .timeline-entry-dot {
                        border-radius: 4px;
                        height: 8px;
                        left: -16px;
                        position: absolute;
                        top: 4px;
                        width: 8px;

                        &.color-blue {
                            background-color: $feedback-blue-light;
                        }

                        &.color-green {
                            background-color: $feedback-green-light;
                        }

                        &.color-red {
                            background-color: $feedback-red-light;
                        }

                        &.color-yellow {
                            background-color: $feedback-yellow-light;
                        }
                    }
                }

                .timeline-entry-container {
                    background-color: $bright-sand;
                    border-radius: 4px;
                    padding: 8px;

                    .timeline-entry-title {
                        color: $deep-blue-50;
                        font-size: 12px;
                        line-height: 16px;
                    }

                    .timeline-entry-text {
                        color: $deep-blue;
                        font-size: 14px;
                        line-height: 20px;
                    }
                }
            }
        }
    }

    &.dark {
        background-color: $deep-blue-90;

        .timeline-container {
            .timeline-line {
                border-color: $deep-blue-55;
            }

            .timeline-entries .timeline-entry {
                .timeline-entry-date {
                    color: $deep-blue-55;

                    .timeline-entry-dot {
                        &.color-blue {
                            background-color: $feedback-blue-dark;
                        }

                        &.color-green {
                            background-color: $feedback-green-dark;
                        }

                        &.color-red {
                            background-color: $feedback-red-dark;
                        }

                        &.color-yellow {
                            background-color: $feedback-yellow-dark;
                        }
                    }
                }

                .timeline-entry-container {
                    background-color: $deep-blue-80;

                    .timeline-entry-title {
                        color: $deep-blue-30;
                    }

                    .timeline-entry-text {
                        color: white;
                    }
                }
            }
        }
    }
}
