header {
    color: $deep-blue;
    pointer-events: none;
    position: absolute;
    width: 100%;
    z-index: 11;

    .main-row-container {
        pointer-events: auto;
        position: relative;
        z-index: 1;

        .main-row {
            align-items: center;
            display: flex;
            gap: 16px;
            padding: 32px 0 56px 0;

            .skip-button {
                background: linear-gradient(to right, $bold-green, $bold-blue);
                color: $deep-blue;
                cursor: default;
                font-size: 16px;
                font-weight: 700;
                left: 50%;
                outline: 1px solid $focus-blue;
                outline-offset: 1px;
                padding: 10px 25px;
                position: absolute;
                top: 10px;
                transform: translate(-50%, -100px);
                user-select: none;
                white-space: nowrap;
                z-index: 1;

                &:focus {
                    transform: translate(-50%, 0);
                }
            }

            .siemens-logo {
                fill: $deep-blue;
                height: 20px;
                transition: fill 0.3s ease-in-out;
                user-select: none;

                @media (max-width: $hide-siemens-logo-max) {
                    display: none;
                }
            }

            .header-title {
                font-size: 16px;
                line-height: 24px;
                transition: color 0.3s ease-in-out;
                user-select: none;

                @media (max-width: $hide-title-max) {
                    display: none;
                }
            }

            .header-buttons {
                align-items: center;
                display: flex;
                flex-grow: 1;
                gap: 16px;
                justify-content: flex-end;

                .mega-menu-button {
                    display: flex;

                    @media (min-width: calc($hide-tabs-max + 1px)) {
                        display: none;
                    }
                }
            }
        }
    }

    .addtitional-rows-container {
        pointer-events: auto;
        transition: transform 0.5s ease-in-out;
        will-change: transform;

        @media (max-width: $hide-tabs-max) {
            transform: translateY(-28px);

            .secondary-row-container .content-container .secondary-row {
                opacity: 0 !important;
            }
        }

        .secondary-row-container .content-container .secondary-row {
            display: flex;
            opacity: 1;
            transition: opacity 0.5s ease-in-out;
            will-change: opacity;
        }
    }

    &.dark {
        color: white;

        .main-row-container .main-row .siemens-logo {
            fill: white;
        }
    }
}
