.header-mega-menu {
    $max-radius: calc(max(300vh, 300vw));

    height: 100vh;
    left: 0;
    position: fixed;
    right: 0;
    z-index: 12;

    @media (min-width: calc($hide-tabs-max + 1px)) {
        display: none;
    }

    .header-mega-menu-background {
        background-color: $light-sand;
        border-bottom-left-radius: 100%;
        position: absolute;
        right: 0;
        top: 0;
    }

    .header-mega-menu-content {
        margin: 56px auto 0;
        max-width: 400px;
        position: relative;

        .header-mega-menu-header {
            align-items: center;
            display: flex;
            justify-content: space-between;
            margin: 0 16px 32px;

            .header-mega-menu-header-title {
                font-size: 40px;
                line-height: 56px;
            }
        }
    }

    &.opening {
        .header-mega-menu-background {
            animation: header-mega-menu-background-open 0.5s ease-in-out forwards;

            @keyframes header-mega-menu-background-open {
                0% {
                    height: 0px;
                    width: 0px;
                }

                100% {
                    height: $max-radius;
                    width: $max-radius;
                }
            }
        }

        .header-mega-menu-content {
            animation: header-mega-menu-content-open 0.5s ease-in-out forwards;

            @keyframes header-mega-menu-content-open {
                0% {
                    opacity: 0;
                }

                25% {
                    opacity: 0;
                }

                100% {
                    opacity: 1;
                }
            }
        }
    }

    &.closing {
        .header-mega-menu-background {
            animation: header-mega-menu-background-close 0.4s ease-in-out forwards;

            @keyframes header-mega-menu-background-close {
                0% {
                    height: $max-radius;
                    width: $max-radius;
                }

                100% {
                    height: 0px;
                    width: 0px;
                }
            }
        }

        .header-mega-menu-content {
            animation: header-mega-menu-content-close 0.4s ease-in-out forwards;

            @keyframes header-mega-menu-content-close {
                0% {
                    opacity: 1;
                }

                100% {
                    opacity: 0;
                }
            }
        }
    }

    &.closed {
        display: none;
    }

    &.open .header-mega-menu-background {
        height: $max-radius;
        width: $max-radius;
    }

    &.dark .header-mega-menu-background {
        background-color: $deep-blue-90;
    }
}
