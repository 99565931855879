.comment {
    display: grid;
    gap: 16px;
    grid-template-columns: 24px auto;

    .comment-line-container {
        display: grid;
        grid-template-rows: 24px auto;

        .comment-monogram {
            align-items: center;
            background-color: $deep-blue-50;
            border-radius: 50%;
            color: white;
            display: flex;
            font-size: 14px;
            justify-content: center;
            line-height: 20px;
            user-select: none;
        }

        .comment-vertical-line {
            background-color: $deep-blue-30;
            justify-self: center;
            width: 2px;
        }
    }

    .comment-main-container {
        .comment-content-container {
            align-items: start;
            display: flex;
            gap: 16px;

            .comment-sender {
                font-size: 16px;
                font-weight: 900;
                line-height: 24px;
            }

            .comment-info, .comment-message {
                font-size: 14px;
                line-height: 20px;
                margin-bottom: 16px;
            }

            .comment-tags {
                display: flex;
                flex-wrap: wrap;
                gap: 16px;

                .comment-tag {
                    border: 1px solid $interactive-blue;
                    border-radius: 18px;
                    color: $interactive-blue;
                    font-size: 14px;
                    line-height: 14px;
                    letter-spacing: 0.2px;
                    padding: 11px 24px;
                    white-space: nowrap;

                }
            }
        }

        .comment-horizontal-line {
            background-color: $deep-blue-30;
            height: 1px;
            margin-bottom: 16px;
            margin-top: 24px;
            width: 100%;
        }
    }

    &:last-child .comment-main-container .comment-horizontal-line {
        display: none;
    }

    &.dark {
        .comment-line-container {
            .comment-monogram {
                background-color: white;
                color: $deep-blue;
            }

            .comment-vertical-line {
                background-color: $deep-blue-70;
            }
        }

        .comment-main-container {
            .comment-content-container .comment-tags .comment-tag {
                border-color: $interactive-coral;
                color: $interactive-coral;
            }

            .comment-horizontal-line {
                background-color: $deep-blue-70;
            }
        }
    }
}
