﻿.headline {
    max-width: 510px;
    display: flex;

    .headline-text {

        @mixin headline-text-appear-animation($translation: 0, $name: '') {
            @keyframes headline-#{$name}-appear {
                0% {
                    opacity: 0;
                    transform: translateY($translation);
                }

                100% {
                    opacity: 1;
                    transform: translateY(0);
                }
            }
        }

        .headline-label {
            animation: headline-label-appear 0.6s ease-in-out forwards;
            padding-bottom: 15px;
            font-size: 18px;
            font-weight: 700;
            text-transform: uppercase;

            @include headline-text-appear-animation(40px, label);
        }

        .headline-title {
            animation: headline-title-appear 0.6s ease-in-out forwards;
            font-size: 60px;
            font-style: normal;
            font-weight: 900;
            line-height: 56px;


            @include headline-text-appear-animation(40px, title);
        }

        .headline-description {
            animation: headline-description-appear 0.6s ease-in-out forwards;
            font-size: 18px;
            font-weight: 700;
            line-height: 28px;
            padding-top: 16px;

            @include headline-text-appear-animation(60px, description);
        }
    }
}
