.tile-with-actions {
    background-color: $light-sand;
    border-radius: 4px;
    grid-column: span 2;
    padding: 16px 16px 24px;

    .tile-label {
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        margin-bottom: 8px;
    }

    .tile-actions {
        display: flex;
        flex-direction: column;
        gap: 8px;

        .tile-action {
            align-items: center;
            background-color: $bright-sand;
            border-radius: 4px;
            cursor: pointer;
            display: grid;
            gap: 8px;
            grid-template-columns: auto 1fr auto;
            outline: none;
            padding: 8px;
            transition: background-color 0.2s ease-in-out;

            .tile-action-icon {
                justify-self: center;

                svg {
                    width: 24px;
                }
            }

            .tile-action-text-container {
                user-select: none;

                .tile-action-title {
                    color: $deep-blue-50;
                    font-size: 12px;
                    line-height: 16px;
                }

                .tile-action-text {
                    color: $deep-blue;
                    font-size: 14px;
                    line-height: 20px;
                }
            }

            .tile-action-arrow {
                display: flex;

                svg {
                    transition: fill 0.2s ease-in-out;
                    width: 24px;
                }
            }

            &:hover {
                background-color: $light-green-50;

                .tile-action-arrow svg {
                    fill: $teal;
                }
            }

            &:focus-visible {
                outline: 1px solid $focus-blue;
                outline-offset: 1px;
            }
        }
    }

    &.dark {
        background-color: $deep-blue-90;

        .tile-actions .tile-action {
            background-color: $deep-blue-80;

            .tile-action-icon > svg {
                fill: white;
            }

            .tile-action-text-container {
                .tile-action-title {
                    color: $deep-blue-30;
                }

                .tile-action-text {
                    color: white;
                }
            }

            .tile-action-arrow svg {
                fill: white;
            }

            &:hover {
                background-color: $interactive-coral-20-db;

                .tile-action-arrow svg {
                    fill: $bold-green;
                }
            }
        }
    }
}
