.status-icon {
    display: flex;

    svg {
        width: var(--icon-width);
    }

    &.status-icon-error svg {
        fill: $feedback-red-light;
    }

    &.status-icon-info svg {
        fill: $feedback-blue-light;
    }

    &.status-icon-success svg {
        fill: $feedback-green-light;
    }

    &.status-icon-warning svg {
        fill: $feedback-orange-light;
    }

    &.dark {
        &.status-icon-error svg {
            fill: $feedback-red-dark;
        }

        &.status-icon-info svg {
            fill: $feedback-blue-dark;
        }

        &.status-icon-success svg {
            fill: $feedback-green-dark;
        }

        &.status-icon-warning svg {
            fill: $feedback-orange-dark;
        }
    }
}
