﻿.system-circuit {
    position: relative;
    display: grid;
    grid-template-columns: 51px 11px 11px auto;
    grid-template-rows: 8px 7px 7px 30px;
    grid-template-areas: "top-1 top-2 top-2 top-3" "line-1-1 line-1-2 line-1-2 line-1-3" "line-2-1 line-2-2 line-2-2 line-2-3" "bottom-1 bottom-2 bottom-3 bottom-4";

    &.system-circuit-offset-8 {
        grid-template-columns: 59px 11px 11px auto;

        .system-circuit-vertical-line {
            width: 69px;
        }
    }

    &.system-circuit-offset-16 {
        grid-template-columns: 67px 11px 11px auto;

        .system-circuit-vertical-line {
            width: 77px;
        }
    }

    .system-circuit-top-2, .system-circuit-line-1-2, .system-circuit-line-2-2 {
        border-bottom: 2px solid $deep-blue;
        transform: skewY(-40deg);
        transform-origin: right bottom;
    }

    .system-circuit-bottom-1, .system-circuit-bottom-2, .system-circuit-bottom-3, .system-circuit-bottom-4 {
        border-bottom: 2px solid $deep-blue;
        box-sizing: border-box;
    }

    .system-circuit-top-1 {
        grid-area: top-1;
    }

    .system-circuit-top-2 {
        grid-area: top-2;
        margin-top: -1px;
    }

    .system-circuit-top-3 {
        grid-area: top-3;
    }

    .system-circuit-line-1-1 {
        grid-area: line-1-1;
    }

    .system-circuit-line-1-2 {
        grid-area: line-1-2;
    }

    .system-circuit-line-1-3 {
        grid-area: line-1-3;
    }

    .system-circuit-line-2-1 {
        grid-area: line-2-1;
    }

    .system-circuit-line-2-2 {
        grid-area: line-2-2;
    }

    .system-circuit-line-2-3 {
        grid-area: line-2-3;
    }

    .system-circuit-bottom-1 {
        grid-area: bottom-1;
    }

    .system-circuit-bottom-2 {
        grid-area: bottom-2;
        position: relative;

        .dot {
            height: 6px;
            width: 6px;
            background-color: $deep-blue;
            border-radius: 50%;
            display: inline-block;
            position: absolute;
            right: -3px;
            bottom: -4px;
        }
    }

    .system-circuit-bottom-3 {
        grid-area: bottom-3;
    }

    .system-circuit-bottom-4 {
        grid-area: bottom-4;
    }

    .system-circuit-vertical-line {
        position: absolute;
        border-right: 2px solid $deep-blue;
        left: 0;
        width: 61px;
        height: 100%;
    }

    .system-circuit-information {
        padding: 16px;
        position: absolute;
        right: 0;
        top: 0;
    }

    &.dark {
        .system-circuit-top-2 {
            border-color: white;
        }

        .system-circuit-line-1-2 {
            border-color: white;
        }

        .system-circuit-line-2-2 {
            border-color: white;
        }

        .system-circuit-bottom-1 {
            border-color: white;
        }

        .system-circuit-bottom-2 {
            border-color: white;

            .dot {
                background-color: white;
            }
        }

        .system-circuit-bottom-3 {
            border-color: white;
        }

        .system-circuit-bottom-4 {
            border-color: white;
        }

        .system-circuit-vertical-line {
            border-color: white;
        }
    }
}
