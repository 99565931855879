﻿.input-highlight {
    position: relative;

    .input-highlight-bar {
        background-color: $interactive-blue;
        bottom: 0;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 0;
        transition: opacity 0.2s ease-out;
        width: 8px;
    }

    .input-highlight-container {
        padding-left: 0px;
        transition: padding 0.2s ease-out 0.1s;
    }

    &.highlighted {
        .input-highlight-bar {
            opacity: 1;
            transition: opacity 0.2s ease-out 0.1s;
        }

        .input-highlight-container {
            padding-left: 16px;
            transition: padding 0.2s ease-out;
        }
    }

    &.dark {
        .input-highlight-bar {
            background-color: $interactive-coral;
        }
    }
}
