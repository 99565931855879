.modal-background {
    align-items: center;
    background-color: rgba($light-sand, 0.7);
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    transition: padding 0.5s ease-in-out;
    z-index: 20;

    &.clickable {
        cursor: pointer;
    }

    .modal {
        --offset-x: 0px;
        --offset-y: 0px;
        background-color: white;
        border-radius: 4px;
        box-shadow: 0px 0px 28px 8px $bright-sand;
        cursor: default;
        margin: 0 10px;
        max-height: calc(100% - 100px);
        min-width: 0;
        outline: none;
        padding: 28px 24px 32px 24px;
        position: relative;
        transform: translate(var(--offset-x), var(--offset-y));

        &:focus-visible {
            outline: 1px solid $focus-blue;
            outline-offset: 2px;
        }

        .move-handle {
            cursor: move;
            height: 20px;
            left: 0;
            position: absolute;
            right: 0;
            top: 0;
        }
    }

    &.opening {
        animation: modal-background-appear 0.2s ease-in-out forwards;

        @keyframes modal-background-appear {
            0% {
                opacity: 0;
            }

            100% {
                opacity: 1;
            }
        }

        .modal {
            animation: modal-appear 0.2s ease-in-out forwards;

            @keyframes modal-appear {
                0% {
                    transform: scale(1.1) translate(var(--offset-x), var(--offset-y));
                }

                100% {
                    transform: scale(1) translate(var(--offset-x), var(--offset-y));
                }
            }
        }
    }

    &.closing {
        animation: modal-background-disappear 0.2s ease-in-out forwards;

        @keyframes modal-background-disappear {
            0% {
                opacity: 1;
            }

            100% {
                opacity: 0;
            }
        }

        .modal {
            animation: modal-disappear 0.2s ease-in-out forwards;

            @keyframes modal-disappear {
                0% {
                    transform: scale(1) translate(var(--offset-x), var(--offset-y));
                }

                100% {
                    transform: scale(1.1) translate(var(--offset-x), var(--offset-y));
                }
            }
        }
    }

    &.closed {
        display: none;
    }

    &.dark {
        background-color: rgba($deep-blue, 0.7);

        .modal {
            background-color: $deep-blue-90;
            box-shadow: 0px 0px 28px 8px $deep-blue;
            color: white;
        }
    }

    .icon-button {
        position: fixed;
        top: 8px;
        right: 8px;
    }
}
