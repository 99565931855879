﻿.breadcrumbs {
    align-items: center;
    display: flex;

    svg {
        fill: $interactive-blue;
        flex-shrink: 0;
        width: 24px;
    }

    &.dark svg {
        fill: $interactive-coral;
    }
}
