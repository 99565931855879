.menu {
    border-radius: 4px;
    color: $interactive-blue;
    cursor: pointer;
    display: flex;
    font-size: 16px;
    line-height: 24px;
    margin: -4px 0 -4px -6px;
    outline: none;
    padding: 4px 0 4px 6px;
    position: relative;
    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    & > svg {
        fill: $interactive-blue;
        transition: fill 0.2s ease-in-out;
        width: 24px;
    }

    .menu-dropdown {
        background-color: $light-sand;
        border-radius: 4px;
        box-shadow: 0 2px 5px 0 rgba($deep-blue, 0.25);
        cursor: default;
        min-width: 200px;
        padding: 10px 8px;
        position: absolute;
        top: calc(100% + 8px);
        white-space: nowrap;
        z-index: 2;
    }

    &.opening .menu-dropdown {
        animation: menu-dropdown-open 0.4s cubic-bezier(.75,-.02,.2,.97) forwards;

        @keyframes menu-dropdown-open {
            0% {
                opacity: 0;
                transform: translate(0, 20px);
            }

            100% {
                opacity: 1;
                transform: translate(0);
            }
        }
    }

    &.closing .menu-dropdown {
        animation: menu-dropdown-close 0.4s cubic-bezier(.75,-.02,.2,.97) forwards;

        @keyframes menu-dropdown-close {
            0% {
                opacity: 1;
                transform: translate(0);
            }

            100% {
                opacity: 0;
                transform: translate(0, 20px);
            }
        }
    }

    &.closed .menu-dropdown {
        visibility: hidden;
    }

    &:hover, &.opening, &.open {
        background-color: $bold-green-20;
        color: $teal;

        & > svg {
            fill: $teal;
        }
    }

    &:focus-visible {
        outline: 1px solid $focus-blue;
        outline-offset: 1px;
    }

    &.dark {
        color: $interactive-coral;

        & > svg {
            fill: $interactive-coral;
        }

        .menu-dropdown {
            background-color: $deep-blue-60;
        }

        &:hover, &.opening, &.open {
            background-color: $bold-green-12-db;
            color: $bold-green;

            & > svg {
                fill: $bold-green;
            }
        }
    }
}
