.comment-overlay {
    display: flex;

    .comment-overlay-dropdown-container {
        margin: -41px 0 0 -8px;
        pointer-events: none;
        position: absolute;

        .comment-overlay-dropdown {
            background-color: $light-sand;
            border-radius: 4px;
            box-shadow: 0 2px 5px 0 rgba($deep-blue, 0.25);
            margin-bottom: 8px;
            padding: 8px;
            pointer-events: auto;
            position: relative;
            width: 264px;
            white-space: normal;
            z-index: 2;

            .comment-overlay-dropdown-header {
                border-bottom: 1px $light-grey solid;
                display: flex;
                gap: 8px;
                justify-content: end;
                padding-bottom: 8px;
            }

            .comment-overlay-dropdown-content {
                display: grid;
                gap: 8px;
                grid-template-columns: 24px 1fr;
                padding-top: 8px;

                svg {
                    fill: $deep-blue;
                    width: 24px;
                }

                .comment-overlay-dropdown-message-container {
                    .comment-overlay-dropdown-messages {
                        display: flex;
                        flex-direction: column-reverse;
                        gap: 16px;
                        margin-bottom: 8px;
                        max-height: 200px;
                        overflow-y: auto;

                        &::-webkit-scrollbar {
                            width: 20px;
                        }

                        &::-webkit-scrollbar-track {
                            background-clip: content-box;
                            background-color: $light-grey;
                            border: 9px transparent solid;
                        }

                        &::-webkit-scrollbar-thumb {
                            background-clip: content-box;
                            background-color: $interactive-blue;
                            border: 8px transparent solid;
                        }

                        &::-webkit-scrollbar-thumb:hover {
                            background-color: $teal-90;
                        }

                        .comment-overlay-dropdown-message {
                            .comment-overlay-dropdown-message-header {
                                display: flex;
                                justify-content: space-between;
                                padding-bottom: 8px;

                                .comment-overlay-dropdown-message-sender {
                                    line-height: 20px;
                                }

                                .comment-overlay-dropdown-message-elapsed-time {
                                    color: $deep-blue-50;
                                    line-height: 20px;
                                }
                            }
                        }
                    }
                }
            }

            &.opening {
                animation: comment-overlay-dropdown-open 0.4s cubic-bezier(.75,-.02,.2,.97) forwards;

                @keyframes comment-overlay-dropdown-open {
                    0% {
                        opacity: 0;
                    }

                    100% {
                        opacity: 1;
                    }
                }
            }

            &.closing {
                animation: comment-overlay-dropdown-close 0.4s cubic-bezier(.75,-.02,.2,.97) forwards;

                @keyframes comment-overlay-dropdown-close {
                    0% {
                        opacity: 1;
                    }

                    100% {
                        opacity: 0;
                    }
                }
            }

            &.closed {
                visibility: hidden;
            }
        }
    }

    &.dark .comment-overlay-dropdown-container .comment-overlay-dropdown {
        background-color: $deep-blue-60;

        .comment-overlay-dropdown-header {
            border-color: $deep-blue-30;
        }

        .comment-overlay-dropdown-content {
            svg {
                fill: white;
            }

            .comment-overlay-dropdown-message-container {
                color: white;

                .comment-overlay-dropdown-messages {
                    &::-webkit-scrollbar-track {
                        background-color: white;
                    }

                    &::-webkit-scrollbar-thumb {
                        background-color: $interactive-coral;
                    }

                    &::-webkit-scrollbar-thumb:hover {
                        background-color: $bold-green;
                    }

                    .comment-overlay-dropdown-message {
                        color: white;

                        .comment-overlay-dropdown-message-header .comment-overlay-dropdown-message-elapsed-time {
                            color: $deep-blue-20;
                        }
                    }
                }
            }
        }
    }
}
