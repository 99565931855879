﻿.text-area {
    .text-area-field {
        overflow: hidden;
        position: relative;

        textarea {
            background-color: $interactive-cyan-8;
            border: none;
            border-bottom: 1px solid $deep-blue-70;
            border-radius: 0;
            color: $deep-blue-70;
            font: inherit;
            margin: 0;
            outline: none;
            padding: 16px 14px 6px;
            resize: none;
            scroll-padding-top: 16px;
            transition: color 0.3s ease-in-out, background 0.3s ease-in-out, border 0.3s ease-in-out;
            width: calc(100% - 28px);
            -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

            &::placeholder {
                color: $deep-blue-70;
                transition: color 0.3s ease-in-out;
                user-select: none;
            }

            &::-webkit-scrollbar {
                width: 6px;
            }

            &::-webkit-scrollbar-track {
                background-color: inherit;
            }

            &::-webkit-scrollbar-thumb {
                background-color: $interactive-cyan-10;
                cursor: pointer;
            }

            &.error:not(:disabled):not(:focus) {
                background-color: $light-red;
                border-color: $feedback-red-dark;
                color: $deep-blue;

                &::-webkit-scrollbar-thumb {
                    background-color: $feedback-red-dark-40;
                }

                &::-webkit-scrollbar-thumb:hover {
                    background-color: $feedback-red-dark-32;
                }
            }

            &:focus, &:hover:not(:disabled) {
                background-color: $bold-green-18;
                border-color: $green;
                color: $deep-blue;

                &::placeholder {
                    color: $deep-blue;
                }

                &::-webkit-scrollbar-thumb {
                    background-color: $light-green-70;
                }

                &::-webkit-scrollbar-thumb:hover {
                    background-color: $light-green-50;
                }
            }

            &:disabled {
                background-color: $interactive-cyan-8 !important;
                border-color: transparent;
                color: $deep-blue-50 !important;
                cursor: not-allowed;
                -webkit-text-fill-color: $deep-blue-50;
            }

            &:read-only {
                background-color: transparent;
                border-color: transparent;
                color: $deep-blue-70;
                cursor: not-allowed;
            }

            &.error:not(:disabled):not(:focus) + .text-area-label {
                background-color: $light-red;
                box-shadow: -2px -4px 3px 6px $light-red;
                color: $feedback-red-dark !important;
            }

            &:focus + .text-area-label {
                background-color: $bold-green-18;
                box-shadow: -2px -4px 3px 6px $bold-green-18;
                color: $deep-blue;
                font-size: 10px;
                top: 2px;
            }

            &:hover + .text-area-label {
                background-color: $bold-green-18;
                box-shadow: -2px -4px 3px 6px $bold-green-18;
                color: $deep-blue;
            }

            &:disabled + .text-area-label {
                background-color: $interactive-cyan-8 !important;
                box-shadow: -2px -4px 3px 6px $interactive-cyan-8 !important;
                color: $deep-blue-50 !important;
            }

            &:read-only + .text-area-label {
                background-color: white;
                box-shadow: -2px -4px 3px 6px white;
                color: $deep-blue-70;
            }

            &:required + .text-area-label::before {
                content: "*";
                position: absolute;
                right: calc(100% + 2px);
            }
        }

        .text-area-label {
            align-items: center;
            background-color: $interactive-cyan-8;
            box-shadow: -2px -4px 3px 6px $interactive-cyan-8;
            color: $deep-blue-70;
            display: flex;
            font-size: 16px;
            margin-left: 14px;
            padding-right: 2px;
            pointer-events: none;
            position: absolute;
            top: 16px;
            transition: color 0.3s ease-in-out, font-size 0.15s ease-in-out, top 0.15s ease-in-out, background 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
            user-select: none;

            &.filled {
                font-size: 10px;
                top: 2px;
            }
        }
    }

    .text-area-message {
        color: $deep-blue;
        font-size: 10px;
        margin: 3px 14px 0;
        user-select: none;

        &.error {
            color: $feedback-red-dark;
        }
    }

    &.dark {
        .text-area-field {
            textarea {
                background-color: $interactive-coral-12-db;
                border-color: $deep-blue-50;
                color: $deep-blue-8;

                &::placeholder {
                    color: $deep-blue-8;
                }

                &::-webkit-scrollbar-thumb {
                    background-color: $deep-blue-80 !important;
                }

                &::-webkit-scrollbar-thumb:hover {
                    background-color: $deep-blue-60 !important;
                }

                &.error:not(:disabled):not(:focus) {
                    background-color: $dark-red;
                    border-color: $medium-red;
                    color: $deep-blue-8;

                    &::-webkit-scrollbar-thumb {
                        background-color: $dark-red-92 !important;
                    }

                    &::-webkit-scrollbar-thumb:hover {
                        background-color: $dark-red-88 !important;
                    }
                }

                &:focus, &:hover {
                    background-color: $bold-green-12-db;
                    border-color: $bold-green;
                    color: $deep-blue-8;
                }

                &:disabled {
                    background-color: $interactive-coral-12-db !important;
                    border-color: transparent;
                    color: $deep-blue-60 !important;
                    -webkit-text-fill-color: $deep-blue-60;
                }

                &:read-only {
                    background-color: transparent;
                    border-color: transparent;
                    color: $deep-blue-50;
                }

                &.error:not(:disabled):not(:focus) + .text-area-label {
                    background-color: $dark-red !important;
                    box-shadow: -2px -4px 3px 6px $dark-red !important;
                    color: $medium-red !important;
                }

                &:hover + .text-area-label {
                    background-color: $bold-green-12-db;
                    box-shadow: -2px -4px 3px 6px $bold-green-12-db;
                    color: $deep-blue-8;
                }

                &:hover + .text-area-label.filled, &:focus + .text-area-label, &:focus:hover + .text-area-label {
                    background-color: $bold-green-12-db;
                    box-shadow: -2px -4px 3px 6px $bold-green-12-db;
                    color: $bold-green;
                }

                &:disabled + .text-area-label, &:disabled:hover + .text-area-label {
                    background-color: $interactive-coral-12-db !important;
                    box-shadow: -2px -4px 3px 6px $interactive-coral-12-db !important;
                    color: $deep-blue-60 !important;
                }

                &:read-only + .text-area-label, &:read-only:hover + .text-area-label, &:read-only:focus + .text-area-label {
                    background-color: $deep-blue;
                    box-shadow: -2px -4px 3px 6px $deep-blue;
                    color: $deep-blue-50;
                }
            }

            .text-area-label {
                background-color: $interactive-coral-12-db;
                box-shadow: -2px -4px 3px 6px $interactive-coral-12-db;
                color: $deep-blue-50;
            }
        }

        .text-area-message {
            color: $deep-blue-8;

            &.error {
                color: $deep-blue-50;
            }
        }
    }
}
