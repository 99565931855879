﻿.multiselect {
    position: relative;
    min-width: 205px;
    color: $deep-blue-70;
    background-color: $interactive-cyan-8;
    border-bottom: 1px solid $deep-blue-70;
    outline: none;
    user-select: none;
    cursor: pointer;
    transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out, border 0.3s ease-in-out;

    .select-opener {
        display: grid;
        grid-template-columns: 1fr auto;
        gap: 5px;
        padding: 0 5px 0 14px;

        &.with-reset {
            grid-template-columns: 1fr auto auto;
        }

        &.filled {
            .select-label {
                bottom: 24px;
                font-size: 10px;
            }

            .select-value {
                opacity: 1;
            }
        }

        .select-label {
            position: absolute;
            top: 2px;
            bottom: 2px;
            display: flex;
            align-items: center;
            font-size: 16px;
            color: $deep-blue-70;
            user-select: none;
            pointer-events: none;
            transition: color 0.3s ease-in-out, font-size 0.15s ease-in-out, bottom 0.15s ease-in-out;

            &.required::before {
                position: absolute;
                content: "*";
                right: calc(100% + 2px);
            }
        }

        .select-value {
            padding: 16px 0 6px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            opacity: 0;
            transition: opacity 0.3s ease-in-out;
        }

        .select-reset {
            display: flex;
            align-items: center;
            margin: 16px 0 6px;
            border-radius: 100%;
            cursor: pointer;
            transition: background-color 0.2s ease-in-out;

            &:hover {
                background-color: $light-green;

                svg {
                    color: $deep-blue;
                }
            }

            svg {
                width: 15px;
                padding: 0 2px;
                fill: $deep-blue-70;
                transition: fill 0.3s ease-in-out;
            }
        }

        .select-arrow {
            display: flex;
            align-items: center;

            svg {
                width: 20px;
                fill: $deep-blue-70;
                transition: fill 0.3s ease-in-out, transform 0.5s ease-in-out;
            }
        }
    }

    .select-values {
        position: absolute;
        top: calc(100% + 1px);
        z-index: 2;
        max-height: 0;
        width: 100%;
        overflow-y: auto;
        background-color: $interactive-cyan-8;
        transition: max-height 0.5s ease-in-out, background-color 0.5s ease-in-out;

        &::-webkit-scrollbar {
            width: 6px;
        }

        &::-webkit-scrollbar-track {
            background-color: inherit;
        }

        &::-webkit-scrollbar-thumb {
            background-color: $interactive-cyan-10;
        }

        .select-value {
            position: relative;
            z-index: 1;
            display: grid;
            grid-template-columns: 16px 1fr;
            gap: 5px;
            align-items: baseline;
            padding: 5px 14px;
            user-select: none;
            cursor: pointer;

            .checkmark {
                height: 13px;
                width: 8px;
                transform: translateX(5px) rotate(40deg);

                &::before, &::after {
                    position: absolute;
                    content: "";
                    background-color: $deep-blue;
                }

                &::before {
                    width: 0;
                    height: 2px;
                    top: calc(100% - 2px);
                    left: 0;
                    transition: width 0.15s ease-in-out 0.15s, background-color 0.3s ease-in-out 0s;
                }

                &::after {
                    width: 2px;
                    height: 0;
                    bottom: 0;
                    left: calc(100% - 2px);
                    transition: height 0.15s ease-in-out 0s, background-color 0.3s ease-in-out 0s;
                }

                &.checked {
                    &::before {
                        width: 100%;
                        transition: width 0.15s ease-in-out 0s, background-color 0.3s ease-in-out 0s;
                    }

                    &::after {
                        height: 100%;
                        transition: height 0.15s ease-in-out 0.15s, background-color 0.3s ease-in-out 0s;
                    }
                }
            }

            .select-value-text {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }

    &.open {
        .select-values {
            &.select-values-1 {
                max-height: 29px;
            }

            &.select-values-2 {
                max-height: 58px;
            }

            &.select-values-3 {
                max-height: 87px;
            }

            &.select-values-4 {
                max-height: 116px;
            }

            &.select-values-5 {
                max-height: 145px;
            }
        }

        .select-opener .select-arrow svg {
            transform: rotate(-180deg);
        }
    }

    &.open, &:hover {
        color: $deep-blue;
        background-color: $bold-green-18;
        border-color: $green;

        .select-opener {
            .select-label {
                color: $deep-blue;
            }

            .select-reset svg {
                fill: $deep-blue;
            }

            .select-arrow svg {
                fill: $deep-blue;
            }
        }

        .select-values {
            background-color: $bold-green-18;

            &::-webkit-scrollbar-thumb {
                background-color: $light-green-70;
            }

            &::-webkit-scrollbar-thumb:hover {
                background-color: $light-green-50;
            }

            .select-value.selected {
                background-color: $light-green;
            }
        }
    }

    &.disabled {
        color: $deep-blue-50;
        background-color: $interactive-cyan-8;
        border-color: transparent;
        cursor: not-allowed;

        .select-opener {
            .select-label {
                color: $deep-blue-50;
            }

            .select-arrow svg {
                fill: $deep-blue-50;
            }
        }
    }

    &.error:not(.disabled):not(:focus-within) {
        color: $deep-blue;
        background-color: $light-red;
        border-color: $feedback-red-dark;

        .select-opener {
            .select-label {
                color: $feedback-red-dark;
            }

            .select-reset {
                &:hover {
                    background-color: $feedback-red-dark-40;

                    svg {
                        color: $deep-blue;
                    }
                }

                svg {
                    fill: $deep-blue;
                }
            }

            .select-arrow svg {
                fill: $deep-blue
            }
        }

        .select-values {
            background-color: $light-red;

            &::-webkit-scrollbar-thumb {
                background-color: $feedback-red-dark-40;
            }

            .select-value.selected {
                background-color: inherit !important;
            }
        }
    }

    &.dark {
        color: $deep-blue-8;
        background-color: $interactive-coral-12-db;
        border-color: $deep-blue-50;

        .select-opener {
            .select-label {
                color: $deep-blue-50;
            }

            .select-reset {
                &:hover {
                    background-color: $deep-blue-60;

                    svg {
                        color: $deep-blue-8;
                    }
                }

                svg {
                    fill: $deep-blue-8;
                }
            }

            .select-arrow svg {
                fill: $deep-blue-50;
            }
        }

        .select-values {
            background-color: $interactive-coral-12-db;

            &::-webkit-scrollbar-thumb {
                background-color: $deep-blue-80;
            }

            &::-webkit-scrollbar-thumb:hover {
                background-color: $deep-blue-60;
            }

            .checkmark {
                &::before, &::after {
                    background-color: $deep-blue-8;
                }
            }
        }

        &.open, &:hover {
            color: $deep-blue-8;
            background-color: $bold-green-12-db;
            border-color: $bold-green;

            .select-opener {
                .select-label {
                    color: $deep-blue-8;
                }

                .select-reset svg {
                    fill: $deep-blue-8;
                }

                .select-arrow svg {
                    fill: $bold-green;
                }

                &.filled .select-label {
                    color: $bold-green;
                }
            }

            .select-values {
                background-color: $bold-green-12-db;

                .select-value.selected {
                    background-color: $teal;
                }
            }
        }

        &.disabled, &.disabled:hover {
            color: $deep-blue-60;
            background-color: $interactive-coral-12-db;
            border-color: transparent;

            .select-opener {
                .select-label {
                    color: $deep-blue-60;
                }

                .select-arrow svg {
                    fill: $deep-blue-60;
                }
            }
        }

        &.error:not(.disabled):not(:focus-within) {
            color: $deep-blue-8;
            background-color: $dark-red;
            border-color: $medium-red;

            .select-opener {
                .select-label {
                    color: $medium-red;
                }

                .select-reset {
                    &:hover {
                        background-color: $dark-red-92;

                        svg {
                            color: $deep-blue-8;
                        }
                    }

                    svg {
                        fill: $deep-blue-8;
                    }
                }

                .select-arrow svg {
                    fill: $medium-red
                }
            }

            .select-values {
                background-color: $dark-red;

                &::-webkit-scrollbar-thumb {
                    background-color: $dark-red-92;
                }
            }
        }
    }
}
