.hover-menu {
    display: flex;

    .hover-menu-dropdown-container {
        margin-top: 38px;
        pointer-events: none;
        position: absolute;

        .hover-menu-dropdown {
            background-color: $light-sand;
            border-radius: 4px;
            box-shadow: 0 2px 5px 0 rgba($deep-blue, 0.25);
            margin-bottom: 8px;
            min-width: 104px;
            padding: 10px 8px;
            pointer-events: auto;
            position: relative;
            white-space: nowrap;
            z-index: 2;

            &.opening {
                animation: hover-menu-dropdown-open 0.4s cubic-bezier(.75,-.02,.2,.97) forwards;

                @keyframes hover-menu-dropdown-open {
                    0% {
                        opacity: 0;
                        transform: translate(0, 20px);
                    }

                    100% {
                        opacity: 1;
                        transform: translate(0);
                    }
                }
            }

            &.closing {
                animation: hover-menu-dropdown-close 0.4s cubic-bezier(.75,-.02,.2,.97) forwards;

                @keyframes hover-menu-dropdown-close {
                    0% {
                        opacity: 1;
                        transform: translate(0);
                    }

                    100% {
                        opacity: 0;
                        transform: translate(0, 20px);
                    }
                }
            }

            &.closed {
                visibility: hidden;
            }
        }
    }

    &.position-left {
        position: relative;

        .hover-menu-dropdown-container .hover-menu-dropdown {
            right: calc(100% - 30px);
        }
    }

    &.position-right {
        position: relative;

        .hover-menu-dropdown-container {
            margin-left: -6px;
        }
    }

    &.position-scroll .hover-menu-dropdown-container {
        margin-left: -6px;
    }

    &.position-scroll-end .hover-menu-dropdown-container {
        right: 0;
    }

    &.dark .hover-menu-dropdown-container .hover-menu-dropdown {
        background-color: $deep-blue-60;
    }
}
