.button {
    position: relative;
    z-index: 1;
    display: inline-flex;
    align-items: center;
    gap: 5px;
    padding: 11px 25px;
    font-weight: 700;
    border: none;
    outline: none;
    user-select: none;
    cursor: pointer;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    transition: color 0.3s ease-in-out, background 0.3s ease-in-out, border 0.3s ease-in-out;

    svg, path {
        width: 20px;
        transition: fill 0.3s ease-in-out;
    }

    &.rounded-border{
        border-radius: 56px !important;
    }

    &.big-size {
        font-size: 18px;
        font-weight: 900;
        padding: 17px 45px;
    }

    &.call-to-action {
        color: $deep-blue;
        background: linear-gradient(to right, $bold-green, $bold-blue);

        svg {
            fill: $deep-blue;
        }

        &::before {
            position: absolute;
            content: "";
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: -1;
            background: $light-green;
            opacity: 0;
            transition: opacity 0.3s ease-in-out;
        }
    }

    &.primary {
        color: white;
        background: $interactive-blue;

        svg {
            fill: white;
        }
    }

    &.secondary {
        padding: 10px 24px;
        color: $interactive-blue;
        background-color: transparent;
        border: 1px solid $interactive-blue;
        border-radius: 2px;

        svg, path {
            fill: $interactive-blue;
        }
    }

    &.tertiary {
        color: $interactive-blue;
        background-color: transparent;

        svg {
            fill: $interactive-blue;
        }
    }

    &.disabled {
        cursor: not-allowed;

        &.call-to-action {
            color: $deep-blue-8;
            background: $deep-blue-30;

            svg {
                fill: $deep-blue-8;
            }
        }

        &.primary {
            color: $deep-blue-8;
            background: $deep-blue-30;

            svg, path {
                fill: $deep-blue-8;
            }
        }

        &.secondary {
            color: $deep-blue-30;
            border-color: $deep-blue-30;

            svg, path {
                fill: $deep-blue-30;
            }
        }

        &.tertiary {
            color: $deep-blue-30;

            svg {
                fill: $deep-blue-30;
            }
        }
    }

    &:not(.disabled):hover,
    &:not(.disabled):active {
        &.call-to-action::before {
            opacity: 1;
        }

        &.primary {
            background: $teal-90;
        }

        &.secondary {
            color: $teal;
            background-color: $bold-green-20;
            border: 1px solid $teal;
            border-radius: 2px;

            svg, path {
                fill: $teal;
            }
        }

        &.tertiary {
            color: $teal;
            background-color: $bold-green-20;

            svg {
                fill: $teal;
            }
        }
    }

    &:not(.disabled):focus-visible {
        &::after {
            position: absolute;
            content: "";
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: -1;
            outline: 1px solid $focus-blue;
            outline-offset: 1px;
        }

        &.secondary::after {
            outline-offset: 2px;
        }
    }

    &.dark {
        &.call-to-action::before {
            background: $light-green-40;
        }

        &.primary {
            color: $deep-blue;
            background: $interactive-coral;

            svg {
                fill: $deep-blue;
            }
        }

        &.secondary {
            color: $interactive-coral;
            background-color: transparent;
            border: 1px solid $interactive-coral;

            svg, path {
                fill: $interactive-coral;
            }
        }

        &.tertiary {
            color: $interactive-coral;
            background-color: transparent;

            svg {
                fill: $interactive-coral;
            }
        }

        &.disabled {
            &.call-to-action {
                color: $deep-blue-30;
                background: $deep-blue-55;

                svg {
                    fill: $deep-blue-30;
                }
            }

            &.primary {
                color: $deep-blue-30;
                background: $deep-blue-55;

                svg {
                    fill: $deep-blue-30;
                }
            }

            &.secondary {
                color: $deep-blue-55;
                border-color: $deep-blue-55;

                svg, path {
                    fill: $deep-blue-55;
                }
            }

            &.tertiary {
                color: $deep-blue-55;

                svg {
                    fill: $deep-blue-55;
                }
            }
        }

        &:not(.disabled):hover,
        &:not(.disabled):active {
            &.primary {
                background: $bold-green;
            }

            &.secondary {
                color: $bold-green;
                background-color: $bold-green-12-db;
                border: 1px solid $bold-green;

                svg, path {
                    fill: $bold-green;
                }
            }

            &.tertiary {
                color: $bold-green;
                background-color: $bold-green-12-db;

                svg {
                    fill: $bold-green;
                }
            }
        }
    }
}