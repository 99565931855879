.data-table {
    background-color: inherit;
    position: relative;

    &::before, &::after {
        bottom: 0;
        content: "";
        pointer-events: none;
        position: absolute;
        top: 0;
        width: 10px;
    }

    &::before {
        left: -11px;
    }

    &::after {
        right: -10px;
    }

    .data-table-container {
        display: grid;
        height: 100%;
        margin: 0 -10px;
        overflow-x: auto;
        overflow-y: hidden;
        padding: 0 10px;

        &::-webkit-scrollbar {
            height: 24px;
        }

        &::-webkit-scrollbar-track {
            background-clip: content-box;
            background-color: $light-grey;
            border: 11px transparent solid;
        }

        &::-webkit-scrollbar-thumb {
            background-clip: content-box;
            background-color: $interactive-blue;
            border: 10px transparent solid;
        }

        &::-webkit-scrollbar-thumb:hover {
            background-color: $teal-90;
        }

        .data-table-grid {
            display: grid;
            height: 100%;

            .data-table-row {
                display: contents;

                .data-table-cell {
                    background-clip: padding-box;
                    color: $deep-blue;
                    font-size: 16px;
                    line-height: 20px;
                    padding: 10px 24px 9px;

                    &.data-table-header-cell {
                        background-color: $soft-sand;
                        border: 1px transparent solid;
                        border-top: none;
                        font-weight: 900;

                        &.data-table-normal-cell {
                            border-left: none;

                            &.before-divider, &:last-child {
                                border-right: 1px $soft-sand solid;
                            }
                        }
                    }

                    &.data-table-data-cell {
                        &.data-table-initial-cell {
                            background-color: $bright-sand;
                            border: 1px transparent solid;
                            font-weight: 900;
                        }

                        &.data-table-normal-cell {
                            border: 1px $bright-sand solid;
                            border-left: none;

                            &.data-table-highlighted-cell {
                                background-color: $feedback-red-light;
                                color: white;
                            }
                        }
                    }
                }

                &:not(:last-child) .data-table-cell {
                    &.data-table-header-cell, &.data-table-data-cell {
                        border-bottom: none;
                    }
                }
            }
        }

        &.no-overscroll {
            overscroll-behavior-x: none;
        }

        &.no-scrollbar {
            scrollbar-width: none;

            &::-webkit-scrollbar {
                display: none;
            }
        }
    }

    &.data-table-layer-1 {
        &::before {
            background: linear-gradient(to right, white, rgba(white, 0.0));
        }

        &::after {
            background: linear-gradient(to left, white, rgba(white, 0.0));
        }
    }

    &.data-table-layer-2 {
        &::before {
            background: linear-gradient(to right, $light-sand, rgba($light-sand, 0.0));
        }

        &::after {
            background: linear-gradient(to left, $light-sand, rgba($light-sand, 0.0));
        }
    }

    &.data-table-layer-3 {
        &::before {
            background: linear-gradient(to right, $bright-sand, rgba($bright-sand, 0.0));
        }

        &::after {
            background: linear-gradient(to left, $bright-sand, rgba($bright-sand, 0.0));
        }

        .data-table-container .data-table-grid .data-table-row .data-table-cell {
            &.data-table-header-cell {
                background-color: $dark-sand;

                &.data-table-normal-cell {
                    &.before-divider, &:last-child {
                        border-color: $dark-sand;
                    }
                }
            }

            &.data-table-data-cell {
                &.data-table-initial-cell {
                    background-color: $soft-sand;
                }

                &.data-table-normal-cell {
                    border-color: $soft-sand;
                }
            }
        }
    }

    &.dark {
        .data-table-container {
            &::-webkit-scrollbar-track {
                background-color: white;
            }

            &::-webkit-scrollbar-thumb {
                background-color: $interactive-coral;
            }

            &::-webkit-scrollbar-thumb:hover {
                background-color: $bold-green;
            }

            .data-table-grid .data-table-row {
                .data-table-cell {
                    color: white;

                    &.data-table-header-cell {
                        background-color: $deep-blue-55;

                        &.data-table-normal-cell {
                            &.before-divider, &:last-child {
                                border-right: 1px $deep-blue-55 solid;
                            }
                        }
                    }

                    &.data-table-data-cell {
                        &.data-table-initial-cell {
                            background-color: $deep-blue-75;
                        }

                        &.data-table-normal-cell {
                            border-color: $deep-blue-75;

                            &.data-table-highlighted-cell {
                                background-color: $feedback-red-dark;
                            }
                        }
                    }
                }
            }
        }

        &.data-table-layer-1 {
            &::before {
                background: linear-gradient(to right, $deep-blue, rgba($deep-blue, 0.0));
            }

            &::after {
                background: linear-gradient(to left, $deep-blue, rgba($deep-blue, 0.0));
            }
        }

        &.data-table-layer-2 {
            &::before {
                background: linear-gradient(to right, $deep-blue-90, rgba($deep-blue-90, 0.0));
            }

            &::after {
                background: linear-gradient(to left, $deep-blue-90, rgba($deep-blue-90, 0.0));
            }
        }

        &.data-table-layer-3 {
            &::before {
                background: linear-gradient(to right, $deep-blue-80, rgba($deep-blue-80, 0.0));
            }

            &::after {
                background: linear-gradient(to left, $deep-blue-80, rgba($deep-blue-80, 0.0));
            }

            .data-table-container .data-table-grid .data-table-row .data-table-cell {
                &.data-table-header-cell {
                    background-color: $deep-blue-50;

                    &.data-table-normal-cell {
                        &.before-divider, &:last-child {
                            border-color: $deep-blue-50;
                        }
                    }
                }

                &.data-table-data-cell {
                    &.data-table-initial-cell {
                        background-color: $deep-blue-60;
                    }

                    &.data-table-normal-cell {
                        border-color: $deep-blue-60;
                    }
                }
            }
        }
    }
}
