.input-button {
    background-color: $interactive-cyan-8;
    border-bottom: 1px solid $deep-blue-70;
    color: $deep-blue-70;
    cursor: pointer;
    display: grid;
    gap: 5px;
    grid-template-columns: 1fr auto;
    min-width: 205px;
    outline: none;
    padding: 0 5px 0 14px;
    position: relative;
    transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out, border 0.3s ease-in-out;
    user-select: none;

    .input-button-label {
        align-items: center;
        bottom: 2px;
        color: $deep-blue-70;
        display: flex;
        font-size: 16px;
        left: 14px;
        pointer-events: none;
        position: absolute;
        top: 2px;
        transition: color 0.3s ease-in-out, font-size 0.15s ease-in-out, bottom 0.15s ease-in-out;
        user-select: none;

        &.required::before {
            content: "*";
            position: absolute;
            right: calc(100% + 2px);
        }
    }

    .input-button-value {
        opacity: 0;
        overflow: hidden;
        padding: 16px 0 6px;
        text-overflow: ellipsis;
        transition: opacity 0.3s ease-in-out;
        white-space: nowrap;
    }

    .input-button-reset {
        align-items: center;
        border-radius: 100%;
        cursor: pointer;
        display: flex;
        margin: 16px 0 6px;
        transition: background-color 0.2s ease-in-out;

        svg {
            fill: $deep-blue-70;
            padding: 0 2px;
            transition: fill 0.3s ease-in-out;
            width: 15px;
        }

        &:hover {
            background-color: $light-green;

            svg {
                color: $deep-blue;
            }
        }
    }

    .input-button-icon {
        align-items: center;
        display: flex;

        svg {
            fill: $deep-blue-70;
            transition: fill 0.3s ease-in-out;
            width: 20px;
        }
    }

    &.disabled {
        background-color: $interactive-cyan-8;
        border-color: transparent;
        color: $deep-blue-50;
        cursor: not-allowed;

        .input-button-label {
            color: $deep-blue-50;
        }

        .input-button-icon svg {
            fill: $deep-blue-50;
        }
    }

    &.filled {
        .input-button-label {
            bottom: 24px;
            font-size: 10px;
        }

        .input-button-value {
            opacity: 1;
        }
    }

    &.highlighted:not(.disabled):not(:focus-within):not(:hover) {
        border-color: $interactive-blue;

        .input-button-label {
            color: $interactive-blue;
        }
    }

    &.with-reset {
        grid-template-columns: 1fr auto auto;
    }

    &:hover, &:focus-within {
        background-color: $bold-green-18;
        border-color: $green;
        color: $deep-blue;

        .input-button-label {
            color: $deep-blue;
        }

        .input-button-reset svg {
            fill: $deep-blue;
        }

        .input-button-icon svg {
            fill: $deep-blue;
        }
    }

    &.dark {
        background-color: $interactive-coral-12-db;
        border-color: $deep-blue-50;
        color: $deep-blue-8;

        .input-button-label {
            color: $deep-blue-50;
        }

        .input-button-reset {
            svg {
                fill: $deep-blue-8;
            }

            &:hover {
                background-color: $deep-blue-60;

                svg {
                    color: $deep-blue-8;
                }
            }
        }

        .input-button-icon svg {
            fill: $deep-blue-50;
        }

        &.disabled, &.disabled:hover {
            background-color: $interactive-coral-12-db;
            border-color: transparent;
            color: $deep-blue-60;

            .input-button-label {
                color: $deep-blue-60;
            }

            .input-button-icon svg {
                fill: $deep-blue-60;
            }
        }

        &.highlighted:not(.disabled):not(:focus-within):not(:hover) {
            border-color: $interactive-coral;

            .input-button-label {
                color: $interactive-coral;
            }
        }

        &:hover, &:focus-within {
            background-color: $bold-green-12-db;
            border-color: $bold-green;
            color: $deep-blue-8;

            .input-button-label {
                color: $deep-blue-8;
            }

            .input-button-reset svg {
                fill: $deep-blue-8;
            }

            .input-button-icon svg {
                fill: $bold-green;
            }

            &.filled .input-button-label {
                color: $bold-green;
            }
        }
    }
}
