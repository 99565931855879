.radio-button-container {
    align-items: center;
    cursor: pointer;
    display: flex;
    flex-wrap: nowrap;
    gap: 5px;
    outline: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    .radio-button {
        align-items: center;
        background-color: $interactive-blue-12;
        border: 2px solid $deep-blue-70;
        border-radius: 100%;
        display: flex;
        flex-shrink: 0;
        height: 16px;
        justify-content: center;
        outline: none;
        transition: background 0.3s ease-in-out, border 0.3s ease-in-out;
        width: 16px;

        &::before {
            background-color: $deep-blue-70;
            border-radius: 100%;
            content: "";
            height: 8px;
            transform: scale(0);
            transition: background 0.3s ease-in-out, transform 0.3s ease-in-out;
            width: 8px;
        }

        &.selected::before {
            transform: scale(1);
        }
    }

    .radio-button-label {
        color: $deep-blue-70;
        font-size: 16px;
        transition: color 0.3s ease-in-out;
        user-select: none;
    }

    &:not(.disabled):hover, &:not(.disabled):active {
        .radio-button {
            background-color: $bold-green-20;
            border-color: $teal;

            &::before {
                background-color: $teal;
            }
        }

        .radio-button-label {
            color: $deep-blue;
        }
    }

    &:not(:disabled):focus-visible .radio-button {
        outline: 1px solid $focus-blue;
        outline-offset: 2px;
    }

    &:not(.disabled) .radio-button.selected + .radio-button-label {
        color: $deep-blue;
    }

    &:not(.disabled) .radio-button.select-highlight.selected {
        border-color: $teal;

        &::before {
            background-color: $teal;
        }
    }

    &.disabled {
        cursor: not-allowed;

        .radio-button {
            background-color: transparent;
            border-color: $deep-blue-50;

            &::before {
                background-color: $deep-blue-50;
            }
        }

        .radio-button-label {
            color: $deep-blue-50;
        }
    }

    &.dark {
        .radio-button {
            background-color: $deep-blue-85;
            border-color: $deep-blue-30;

            &::before {
                background-color: $deep-blue-30;
            }
        }

        .radio-button-label {
            color: $deep-blue-30;
        }

        &:not(.disabled):hover, &:not(.disabled):active {
            .radio-button {
                background-color: $bold-green-12-db;
                border-color: $bold-green;

                &::before {
                    background-color: $bold-green;
                }
            }

            .radio-button-label {
                color: $deep-blue-8;
            }
        }

        &:not(.disabled) .radio-button.selected + .radio-button-label {
            color: $deep-blue-8;
        }

        &:not(.disabled) .radio-button.select-highlight.selected {
            border-color: $bold-green;

            &::before {
                background-color: $bold-green;
            }
        }

        &.disabled {
            .radio-button {
                background-color: transparent;
                border-color: $deep-blue-60;

                &::before {
                    background-color: $deep-blue-60;
                }
            }

            .radio-button-label {
                color: $deep-blue-60;
            }
        }
    }
}
