.box-tab-switcher-dropdown-item {
    color: $deep-blue;
    cursor: pointer;
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-items: start;
    font-size: 20px;
    gap: 6px;
    font-weight: 900;
    line-height: 34px;
    outline: none;
    padding: 0 4px;
    position: relative;
    user-select: none;
    z-index: 1;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    .box-tab-switcher-dropdown-item-icon {
        flex-shrink: 0;
        width: 32px;
        height: 32px;
    }

    .box-tab-switcher-dropdown-item-label {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    &::before {
        bottom: -1px;
        border-radius: 8px;
        content: "";
        left: 0;
        pointer-events: none;
        position: absolute;
        right: 0;
        top: -1px;
        z-index: -1;
    }

    &:hover {
        color: white;

        svg {
            fill: white;
        }

        &::before {
            background-color: $teal-90;
        }
    }

    &:focus-visible::before {
        outline: 1px solid $focus-blue;
        outline-offset: -1px;
    }

    &.dark {
        color: white;

        svg {
            fill: white;
        }

        &::before {
            border-color: $deep-blue-50;
        }

        &:hover::before {
            background-color: $interactive-coral-20-db;
        }
    }
}
