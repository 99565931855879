﻿.text {
    &.text-box-title {
        color: $deep-blue;
        font-size: 20px;
        line-height: 26px;

        &.dark {
            color: white;
        }
    }

    &.text-label {
        color: $deep-blue;
        font-size: 18px;
        line-height: 28px;

        &.dark {
            color: white;
        }
    }

    &.text-normal {
        color: $deep-blue;
        font-size: 16px;
        line-height: 24px;

        &.dark {
            color: white;
        }
    }

    &.text-small {
        color: $deep-blue;
        font-size: 14px;
        line-height: 20px;

        &.dark {
            color: white;
        }
    }

    &.text-subordinate {
        color: $deep-blue-50;
        font-size: 14px;
        line-height: 20px;

        &.dark {
            color: $deep-blue-30;
        }
    }

    &.text-subtitle {
        color: $deep-blue;
        font-size: 26px;
        font-weight: 900;
        line-height: 34px;

        &.dark {
            color: white;
        }
    }

    &.text-subtitle-small {
        color: $deep-blue;
        font-size: 20px;
        font-weight: 900;
        line-height: 26px;

        &.dark {
            color: white;
        }
    }

    &.text-subtitle-very-small {
        color: $deep-blue;
        font-size: 16px;
        font-weight: 900;
        line-height: 24px;

        &.dark {
            color: white;
        }
    }

    &.text-subtitle-very-small-light {
        color: $deep-blue;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;

        &.dark {
            color: white;
        }
    }

    &.text-title {
        color: $deep-blue;
        font-size: 40px;
        line-height: 56px;

        &.dark {
            color: white;
        }
    }

    &.text-title-label {
        color: $deep-blue-50;
        font-size: 16px;
        line-height: 24px;

        &.dark {
            color: $deep-blue-30;
        }
    }

    &.text-color-reference {
        color: $reference-system;

        &.dark {
            color: $reference-system-dark;
        }
    }

    &.text-color-alternative {
        color: $alternative-system;

        &.dark {
            color: $alternative-system-dark;
        }
    }
}
