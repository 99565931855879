.menu-dropdown-item {
    position: relative;
    outline: none;

    .menu-dropdown-item-button {
        align-items: center;
        color: $deep-blue;
        cursor: pointer;
        display: flex;
        font-size: 16px;
        justify-content: space-between;
        line-height: 16px;
        padding: 2px 0 2px 8px;
        user-select: none;
        z-index: 1;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

        &::before {
            border-radius: 8px;
            bottom: -1px;
            content: "";
            left: 0;
            pointer-events: none;
            position: absolute;
            right: 0;
            top: -1px;
            z-index: -1;
        }

        svg {
            fill: $deep-blue;
            width: 24px;
        }

        &:hover {
            color: white;

            &::before {
                background-color: $teal-90;
            }

            svg {
                fill: white;
            }
        }
    }

    .menu-dropdown-item-dropdown {
        background-color: $light-sand;
        border-radius: 4px;
        box-shadow: 0 2px 5px 0 rgba($deep-blue, 0.25);
        min-width: 200px;
        padding: 10px 8px;
        position: absolute;
        top: -10px;
        visibility: hidden;
        white-space: nowrap;
        z-index: 2;
    }

    &:hover > .menu-dropdown-item-button:not(:hover)::before {
        background-color: $bright-sand;
    }

    &:focus-visible > .menu-dropdown-item-button::before {
        outline: 1px solid $focus-blue;
        outline-offset: -1px;
    }

    &.dark {
        .menu-dropdown-item-button {
            color: white;

            svg {
                fill: white;
            }

            &:hover::before {
                background-color: $interactive-coral-20-db;
            }
        }

        .menu-dropdown-item-dropdown {
            background-color: $deep-blue-60;
        }

        &:hover > .menu-dropdown-item-button:not(:hover)::before {
            background-color: $deep-blue-50;
        }
    }
}

.menu:not(.closed) .menu-dropdown-item.open > .menu-dropdown-item-dropdown {
    visibility: visible;
}
