﻿.dot {
    border-radius: 4px;
    height: 8px;
    width: 8px;

    &.dot-big {
        border-radius: 8px;
        height: 16px;
        width: 16px;
    }

    &.dot-blue {
        background-color: $feedback-blue-light;
    }

    &.dot-green {
        background-color: $feedback-green-light;
    }

    &.dot-red {
        background-color: $feedback-red-light;
    }

    &.dot-yellow {
        background-color: $feedback-yellow-light;
    }

    &.dark {
        &.dot-blue {
            background-color: $feedback-blue-dark;
        }

        &.dot-green {
            background-color: $feedback-green-dark;
        }

        &.dot-red {
            background-color: $feedback-red-dark;
        }

        &.dot-yellow {
            background-color: $feedback-yellow-dark;
        }
    }
}
