﻿.chart-wrapper {
    display: flex;
    flex-direction: column;

    .intersection-point {
        fill: white;
        stroke: $deep-blue;
    }

    .recharts-active-dot circle {
        fill: $deep-blue;
        stroke: $deep-blue;
    }

    .recharts-bar {
        stroke-width: 1px;
        stroke: $light-sand;

        .recharts-label-list {
            stroke: black;
            stroke-width: 0.1;
        }

        &.iesReference .recharts-bar-rectangle {
            fill: grey;
        }

        &.reference .recharts-bar-rectangle {
            fill: $reference-system;
        }

        &.alternative .recharts-bar-rectangle {
            fill: $alternative-system;
        }
    }

    .recharts-label-list {
        fill: $deep-blue;
        font-size: 12px;
    }

    .recharts-line {
        .recharts-curve.recharts-line-curve {
            stroke: $deep-blue;
            stroke-width: 2px;
        }

        &.reference-line {
            .recharts-curve.recharts-line-curve {
                stroke: $reference-system;
            }

            .recharts-line-dots svg {
                stroke: $reference-system;
                fill: $reference-system;
            }
        }

        &.alternative-line {
            .recharts-curve.recharts-line-curve {
                stroke: $alternative-system;
            }

            .recharts-line-dots svg {
                stroke: $alternative-system;
                fill: $alternative-system;
            }
        }

        .recharts-line-dots svg.intersection-point {
            stroke-width: 1px;
            stroke: $deep-blue;
            fill: white;
        }
    }

    .recharts-scatter .recharts-scatter-symbol {
        fill: $deep-blue-30;
    }

    .recharts-reference-dot {
        .recharts-dot.recharts-reference-dot-dot {
            fill: $deep-blue;
            stroke: none;
        }

        &.dot-yellow .recharts-dot.recharts-reference-dot-dot {
            fill: $feedback-yellow-light;
        }
    }

    .recharts-cartesian-axis {
        .recharts-cartesian-axis-line {
            stroke: $deep-blue;
        }

        .recharts-cartesian-axis-ticks .recharts-cartesian-axis-tick {
            .recharts-cartesian-axis-tick-line {
                stroke: $deep-blue;
            }

            .recharts-text.recharts-cartesian-axis-tick-value {
                fill: $deep-blue;
                font-size: 12px;
            }
        }

        .recharts-text.recharts-label {
            fill: $deep-blue;
            font-size: 12px;
        }
    }

    .recharts-curve.recharts-tooltip-cursor {
        stroke: $deep-blue-30;
    }

    .recharts-tooltip-wrapper {
        .recharts-default-tooltip, .custom-tooltip {
            backdrop-filter: blur(3px);
            background-color: rgba(white, 0.85) !important;
            border: 1px solid $light-grey;
            border-radius: 4px;
            padding: 10px;

            .recharts-tooltip-label {
                color: $deep-blue !important;
                font-weight: 700;
            }

            .recharts-tooltip-item-list .recharts-tooltip-item {
                color: $deep-blue !important;
            }
        }

        .custom-tooltip {
            .label {
                color: $deep-blue !important;
                font-weight: 600;
                padding-bottom: 10px;
            }

            &.intersection-point {
                .value-list-item-name {
                    color: $deep-blue;
                }

                .value-list-item-value {
                    color: $deep-blue;
                }
            }
        }
    }

    &.dark {
        .intersection-point {
            fill: $deep-blue;
            stroke: white;
        }

        .recharts-active-dot circle {
            fill: white;
            stroke: white;
        }

        .recharts-bar {
            stroke-width: 1px;
            stroke: $deep-blue;

            &.reference .recharts-bar-rectangle {
                fill: $reference-system-dark;
            }

            &.alternative .recharts-bar-rectangle {
                fill: $alternative-system-dark;
            }
        }

        .recharts-line {
            .recharts-curve.recharts-line-curve {
                stroke: white;
            }

            &.reference-line {
                .recharts-curve.recharts-line-curve {
                    stroke: $reference-system-dark;
                }

                .recharts-line-dots svg {
                    stroke: $reference-system-dark;
                    fill: $reference-system-dark;
                }
            }

            &.alternative-line {
                .recharts-curve.recharts-line-curve {
                    stroke: $alternative-system-dark;
                }

                .recharts-line-dots svg {
                    stroke: $alternative-system-dark;
                    fill: $alternative-system-dark;
                }
            }

            .recharts-line-dots svg.intersection-point {
                stroke-width: 1px;
                stroke: white;
                fill: $deep-blue;
            }
        }

        .recharts-reference-dot {
            .recharts-dot.recharts-reference-dot-dot {
                fill: white;
            }

            &.dot-yellow .recharts-dot.recharts-reference-dot-dot {
                fill: $feedback-yellow-dark;
            }
        }

        .recharts-cartesian-axis {
            .recharts-cartesian-axis-line {
                stroke: white;
            }

            .recharts-cartesian-axis-ticks .recharts-cartesian-axis-tick {
                .recharts-cartesian-axis-tick-line {
                    stroke: white;
                }

                .recharts-text.recharts-cartesian-axis-tick-value {
                    fill: white;
                }
            }

            .recharts-text.recharts-label {
                fill: white;
            }
        }

        .recharts-curve.recharts-tooltip-cursor {
            stroke: $deep-blue-50;
        }

        .recharts-tooltip-wrapper {
            .recharts-default-tooltip, .custom-tooltip {
                background-color: rgba($deep-blue-80, 0.85) !important;
                border-color: $deep-blue-55 !important;

                .recharts-tooltip-label {
                    color: white !important;
                }

                .recharts-tooltip-item-list .recharts-tooltip-item {
                    color: white !important;
                }
            }

            .custom-tooltip {
                .label {
                    color: white !important;
                }

                &.intersection-point {
                    .value-list-item-name {
                        color: white;
                    }

                    .value-list-item-value {
                        color: white;
                    }
                }
            }
        }
    }
}
