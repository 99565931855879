﻿.menu-item {
    color: $deep-blue;
    cursor: pointer;
    font-size: 16px;
    line-height: 16px;
    outline: none;
    padding: 6px 8px;
    position: relative;
    user-select: none;
    z-index: 1;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    &::before {
        border-radius: 8px;
        bottom: -1px;
        content: "";
        left: 0;
        pointer-events: none;
        position: absolute;
        right: 0;
        top: -1px;
        z-index: -1;
    }

    &.disabled {
        color: $deep-blue-50;
        cursor: default;
    }

    &:not(.disabled):hover {
        color: white;

        &::before {
            background-color: $teal-90;
        }
    }

    &:focus-visible::before {
        outline: 1px solid $focus-blue;
        outline-offset: -1px;
    }

    &.dark {
        color: white;

        &.disabled {
            color: $deep-blue-30;
        }

        &:not(.disabled):hover::before {
            background-color: $interactive-coral-20-db;
        }
    }
}
